import { Box, Button, Drawer, Grid, Typography } from '@mui/material';
import { EmployeeItemHorizontal } from 'elements/components/EmployeeItem';
import Breadcrumbs from 'elements/UI/Breadcrums';
import Container from 'elements/UI/Container';
import PageContainer from 'elements/UI/PageContainer';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddTeamModal } from './AddTeamModal';
import { useSelector } from 'react-redux';
import Loader from 'elements/UI/Loader';

const TeamPage = () => {
  const { isAuth } = useAuth();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const navigate = useNavigate();

  const { team, isLoadingTeam } = useSelector((state) => state.team);

  const handleClick = (id) => {
    navigate(`/team/${id}`);
  };
  return (
    <PageContainer>
      <Container>
        {isLoadingTeam ? (
          <Loader />
        ) : (
          <>
            <Breadcrumbs
              links={[{ name: 'Главная', to: 'main' }, { name: 'Команда' }]}
              mb={{ xs: '18px', sm: '64px' }}
            />
            <Typography variant='h2' mb={{ xs: '24px', sm: '48px' }}>
              Команда
            </Typography>
            {isAuth ? (
              <>
                <Box mb='16px'>
                  <Button
                    variant='outlined'
                    sx={{ mr: '8px' }}
                    onClick={() => setIsAddOpen(true)}
                  >
                    Добавить
                  </Button>
                </Box>

                <Drawer
                  anchor='bottom'
                  open={isAddOpen}
                  onClose={() => setIsAddOpen(false)}
                >
                  <AddTeamModal onClose={() => setIsAddOpen(false)} />
                </Drawer>
              </>
            ) : null}
            <Grid container spacing={{ xs: '12px', md: '40px' }}>
              {team.map((el, idx) => (
                <Grid item xs={12} md={6} key={idx}>
                  <EmployeeItemHorizontal
                    employee={el}
                    isHorizontal={true}
                    onClick={handleClick}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default TeamPage;
