import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: '',
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginFetching: (state) => {
      state.isLoading = true;
    },
    loginFetchingSuccess: (state, action) => {
      state.isLoading = false;
      state.error = '';
    },
    loginFetchingError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { loginFetching, loginFetchingSuccess, loginFetchingError } =
  loginSlice.actions;

export default loginSlice.reducer;
