import { Grid, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const Avatar = styled('div')(({ theme, imgUrl }) => ({
  borderRadius: '8px',
  height: '90px',
  width: '90px',
  backgroundColor: 'white',
  backgroundImage: `url('${imgUrl}')`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
}));

export const PersonBlock = ({ person }) => {
  const navigate = useNavigate();
  return (
    <>
      {person && (
        <Grid
          container
          direction='row'
          spacing={2}
          mb={3}
          onClick={() => person && navigate(`/team/${person.id}`)}
          sx={{ cursor: 'pointer' }}
        >
          <Grid item>
            <Avatar imgUrl={person.photoPath} />
          </Grid>
          <Grid item>
            <Box>
              <Typography fontWeight='bold'>
                {person.firstName + ' ' + person.lastName}
              </Typography>
              <Typography mt={1}>{person.position}</Typography>
              <Typography mt={1} color='#104173'>
                {person.email}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};
