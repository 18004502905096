import { Box, Input, Typography, styled } from '@mui/material';
import ArrowButton from 'elements/UI/ArrowButton';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from 'store/actions/loginActions';
import * as Yup from 'yup';

const Wrapper = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.whiteBg.main,
}));

const LoginContainer = styled(Box)(({ theme }) => ({
  width: '450px',
  background: theme.palette.blueBg.main,
  borderRadius: '36px',
  padding: '30px 48px',
}));

const schema = Yup.string().required('Это поле обязательно');

const LoginPage = () => {
  const [key, setKey] = useState('');
  const [validationErrors, setValidationErrors] = useState(null);

  const { isLoading, error } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await schema.validate(key);
      setValidationErrors('');
      const loginData = { password: key };
      const ok = await dispatch(login(loginData));
      if (ok) {
        navigate('/');
      }
      //   const auth = getAuth()
      //   signInWithEmailAndPassword(auth, email, password)
      //     .then(({user}) => {
      //       console.log(user)
      //       dispatch(
      //         setUser({
      //           email: user.email,
      //           id: user.uid,
      //           token: user.accessToken,
      //         })
      //       )
      //       push('/')
      //     })
      //     .catch(() => alert('Invalid user!'))
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrors(error.errors[0]);
      }
      throw error;
    }
  };

  return (
    <Wrapper>
      <LoginContainer>
        <Typography
          variant='h6'
          mb={1}
          sx={{ opacity: 0.7, cursor: 'pointer' }}
          onClick={() => navigate('/main')}
        >
          ← Назад
        </Typography>
        <Typography variant='h5' mb={2}>
          Введите ключ:
        </Typography>
        <Input fullWidth value={key} onChange={(e) => setKey(e.target.value)} />
        <Typography
          sx={{ color: '#ca0000', mb: '28px', mt: '8px' }}
          variant='subtitle1'
        >
          {validationErrors || error}
        </Typography>
        <ArrowButton
          fullWidth={true}
          withoutArrow={true}
          onClick={() => handleLogin()}
          disabled={isLoading}
        >
          <Typography>Продолжить</Typography>
        </ArrowButton>
      </LoginContainer>
    </Wrapper>
  );
};

export default LoginPage;
