const responseStatus = {
  success: 200,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  validationError: 422,
  serverError: 500,
};

export default function getResponse(result) {
  const data = result?.data;

  if (
    data.httpStatusCode !== responseStatus.notFound &&
    (result.status === responseStatus.success ||
      data.httpStatusCode === responseStatus.success)
  ) {
    return {
      ok: true,
      data: data.entity || data,
      errors: data.errors,
      httpStatusCode: data.httpStatusCode,
      isSuccess: data.isSuccess,
    };
  } else {
    return {
      ok: false,
      errors: data.errors,
      httpStatusCode: data.httpStatusCode,
    };
  }
}
