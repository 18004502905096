import { Typography } from '@mui/material';
import Breadcrumbs from 'elements/UI/Breadcrums';
import Container from 'elements/UI/Container';
import PageContainer from 'elements/UI/PageContainer';
import Practices from 'elements/components/Practices';

const PracticesPage = () => {
  return (
    <PageContainer>
      <Container>
        <Breadcrumbs
          links={[{ name: 'Главная', to: 'main' }, { name: 'Практики' }]}
          mb={{ xs: '18px', sm: '64px' }}
        />
        <Typography variant='h2' mb={{ xs: '18px', sm: '64px' }}>
          Практики
        </Typography>
        <Practices />
      </Container>
    </PageContainer>
  );
};

export default PracticesPage;
