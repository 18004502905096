import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mainArticleList: [],
  isLoadingMainArticleList: false,
  errorMainArticleList: '',

  articleList: [],
  articlePageMetaData: {},
  isLoadingArticleList: false,
  errorArticleList: '',

  article: {},
  isLoadingArticle: false,
  errorArticle: '',

  commentList: [],
  commentPageMetaData: {},
  isLoadingCommentList: false,
  errorCommentList: '',

  isLoadingCommentEdit: false,
  commentEditError: '',

  articleEditInfo: {},
  isLoadingArticleEditInfo: false,
  errorArticleEditInfo: '',

  isLoadingEdit: false,
  editError: '',

  articleSearchList: [],
  articleSearchPageMetaData: {},
  isLoadingArticleSearchList: false,
  errorArticleSearchList: '',

  primInfoList: [],
  isLoadingPrimInfoList: false,
  errorPrimInfoList: '',

  lastNews: [],
  isLoadingLastNews: false,
  errorLastNews: '',

  eventList: [],
  eventListPageMetaData: {},
  isLoadingEventList: false,
  errorEventList: '',

  selectedTagForSearch: null,
};

export const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    getMainArticleListFetching: (state) => {
      state.isLoadingMainArticleList = true;
    },
    getMainArticleListFetchingSuccess: (state, action) => {
      state.isLoadingMainArticleList = false;
      state.errorMainArticleList = '';
      state.mainArticleList = action.payload;
    },
    getMainArticleListFetchingError: (state, action) => {
      state.isLoadingMainArticleList = false;
      state.errorMainArticleList = action.payload;
      state.mainArticleList = [];
    },

    clearArticleList: (state) => {
      state.isLoadingArticleList = false;
      state.errorArticle = '';
      state.articleList = [];
      state.articlePageMetaData = {};
    },
    getArticleListFetching: (state) => {
      state.isLoadingArticleList = true;
    },
    getArticleListFetchingSuccess: (state, action) => {
      state.isLoadingArticleList = false;
      state.errorArticleList = '';
      state.articleList = [
        ...state.articleList,
        ...action.payload?.pageItems?.filter((el) => !el.isMainPage),
      ];
      state.articlePageMetaData = action.payload?.pagedMetaData;
    },
    getArticleListFetchingError: (state, action) => {
      state.isLoadingArticleList = false;
      state.errorArticleList = action.payload;
      state.articleList = [];
    },

    clearArticle: (state) => {
      state.isLoadingArticle = false;
      state.errorArticle = '';
      state.article = {};
    },
    getArticleFetching: (state) => {
      state.isLoadingArticle = true;
    },
    getArticleFetchingSuccess: (state, action) => {
      state.isLoadingArticle = false;
      state.errorArticle = '';
      state.article = action.payload;
    },
    getArticleFetchingError: (state, action) => {
      state.isLoadingArticle = false;
      state.errorArticle = action.payload;
      state.article = {};
    },

    clearCommentList: (state) => {
      state.isLoadingCommentList = false;
      state.errorCommentList = '';
      state.commentList = [];
      state.commentPageMetaData = {};
    },
    getCommentListFetching: (state) => {
      state.isLoadingCommentList = true;
    },
    getCommentListFetchingSuccess: (state, action) => {
      state.isLoadingCommentList = false;
      state.errorCommentList = '';
      state.commentList = [...state.commentList, ...action.payload?.pageItems];
      state.commentPageMetaData = action.payload?.pagedMetaData;
    },
    getCommentListFetchingError: (state, action) => {
      state.isLoadingCommentList = false;
      state.errorCommentList = action.payload;
      state.commentList = [];
    },

    getArticleEditInfoFetching: (state) => {
      state.isLoadingArticleEditInfo = true;
    },
    getArticleEditInfoFetchingSuccess: (state, action) => {
      state.isLoadingArticleEditInfo = false;
      state.errorArticleEditInfo = '';
      state.articleEditInfo = action.payload;
    },
    getArticleEditInfoFetchingError: (state, action) => {
      state.isLoadingArticleEditInfo = false;
      state.errorArticleEditInfo = action.payload;
      state.articleEditInfo = {};
    },

    createOrUpdateArticleFetching: (state) => {
      state.isLoadingEdit = true;
    },
    createOrUpdateArticleFetchingSuccess: (state, action) => {
      state.isLoadingEdit = false;
      state.editError = '';
    },
    createOrUpdateArticleFetchingError: (state, action) => {
      state.isLoadingEdit = false;
      state.editError = action.payload;
    },

    createCommentFetching: (state) => {
      state.isLoadingCommentEdit = true;
    },
    createCommentFetchingSuccess: (state, action) => {
      state.isLoadingCommentEdit = false;
      state.commentEditError = '';
    },
    createCommentFetchingError: (state, action) => {
      state.isLoadingCommentEdit = false;
      state.commentEditError = action.payload;
    },

    setSelectedTagForSearch: (state, action) => {
      state.selectedTagForSearch = action.payload;
    },

    clearArticleSearchList: (state) => {
      state.isLoadingArticleSearchList = false;
      state.errorArticleSearch = '';
      state.articleSearchList = [];
      state.articleSearchPageMetaData = {};
      // state.selectedTagForSearch = null;
    },

    getArticleSearchListFetching: (state) => {
      state.isLoadingArticleSearchList = true;
    },
    getArticleSearchListFetchingSuccess: (state, action) => {
      state.isLoadingArticleSearchList = false;
      state.errorArticleSearchList = '';
      state.articleSearchList = [
        ...state.articleSearchList,
        ...action.payload?.pageItems,
      ];
      state.articleSearchPageMetaData = action.payload?.pagedMetaData;
    },
    getArticleSearchListFetchingError: (state, action) => {
      state.isLoadingArticleSearchList = false;
      state.errorArticleSearchList = action.payload;
      state.articleSearchList = [];
    },

    getPrimInfoListListFetching: (state) => {
      state.isLoadingPrimInfoListList = true;
    },
    getPrimInfoListListFetchingSuccess: (state, action) => {
      state.isLoadingPrimInfoListList = false;
      state.errorPrimInfoListList = '';
      state.primInfoList = action.payload?.pageItems;
    },
    getPrimInfoListListFetchingError: (state, action) => {
      state.isLoadingPrimInfoListList = false;
      state.errorPrimInfoListList = action.payload;
      state.primInfoList = [];
    },

    getLastNewsFetching: (state) => {
      state.isLoadingLastNews = true;
    },
    getLastNewsFetchingSuccess: (state, action) => {
      state.isLoadingLastNews = false;
      state.errorLastNews = '';
      state.lastNews = action.payload?.pageItems?.sort((a, b) => {
        const dateA = new Date(a.createdDateTimeUtc);
        const dateB = new Date(b.createdDateTimeUtc);
        return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
      });
    },
    getLastNewsFetchingError: (state, action) => {
      state.isLoadingLastNews = false;
      state.errorLastNews = action.payload;
      state.lastNews = [];
    },

    clearEventList: (state) => {
      state.isLoadingEventList = false;
      state.errorEventList = '';
      state.eventList = [];
      state.eventListPageMetaData = {};
    },
    getEventListFetching: (state) => {
      state.isLoadingEventList = true;
    },
    getEventListFetchingSuccess: (state, action) => {
      state.isLoadingEventList = false;
      state.errorEventList = '';
      state.eventList = [...state.eventList, ...action.payload?.pageItems];
      state.eventListPageMetaData = action.payload?.pagedMetaData;
    },
    getEventListFetchingError: (state, action) => {
      state.isLoadingEventList = false;
      state.errorEventList = action.payload;
      state.eventList = [];
      state.eventListPageMetaData = {};
    },
  },
});

export const {
  getMainArticleListFetching,
  getMainArticleListFetchingSuccess,
  getMainArticleListFetchingError,
  clearArticleList,
  getArticleListFetching,
  getArticleListFetchingSuccess,
  getArticleListFetchingError,
  clearCommentList,
  getCommentListFetching,
  getCommentListFetchingSuccess,
  getCommentListFetchingError,
  createOrUpdateArticleFetching,
  createOrUpdateArticleFetchingSuccess,
  createOrUpdateArticleFetchingError,
  clearArticle,
  getArticleFetching,
  getArticleFetchingSuccess,
  getArticleFetchingError,
  getArticleEditInfoFetching,
  getArticleEditInfoFetchingSuccess,
  getArticleEditInfoFetchingError,
  createCommentFetching,
  createCommentFetchingSuccess,
  createCommentFetchingError,
  clearArticleSearchList,
  getArticleSearchListFetching,
  getArticleSearchListFetchingSuccess,
  getArticleSearchListFetchingError,
  getPrimInfoListListFetching,
  getPrimInfoListListFetchingSuccess,
  getPrimInfoListListFetchingError,
  getLastNewsFetching,
  getLastNewsFetchingSuccess,
  getLastNewsFetchingError,
  clearEventList,
  getEventListFetching,
  getEventListFetchingSuccess,
  getEventListFetchingError,
  setSelectedTagForSearch,
} = articleSlice.actions;

export default articleSlice.reducer;
