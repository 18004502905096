import { Box, styled } from '@mui/system';
import CarouselBlock from './CarouselBlock';

const AnimatedBlock = () => {
  const ContentBlock = styled(Box)(({ theme }) => ({
    height: '100vh',
    maxHeight: '1080px',
    // backgroundImage: `url(${animatedBg})`,
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
    marginTop: '-4px',
    paddingTop: '30px',

    position: 'relative',
    '& .video-container': {
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      '& video': {
        minWidth: '100vw',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      '&::after': {
        content: "''",
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
  }));

  return (
    <ContentBlock>
      {/* <Container> */}

      <div className='video-container'>
        <video
          loop='loop'
          autoPlay='autoplay'
          muted='muted'
          id='myVideo'
          playsInline='playsInline'
        >
          <source
            src={
              'https://storage.yandexcloud.net/prim-group-files/animation_site_corected.mp4'
            }
            type='video/mp4'
          />
        </video>
      </div>

      <Box
        sx={{
          pt: { xs: '50%', sm: 'min(230px, 25vh)', md: 'min(300px, 25vh)' },
        }}
      >
        {/* <Grid container>
          <Grid xs={8}> */}
        <CarouselBlock />
        {/* </Grid>
        </Grid> */}
      </Box>
      {/* </Container> */}
    </ContentBlock>
  );
};

export default AnimatedBlock;
