import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tags: [],
  isLoading: false,
  error: '',

  isLoadingEdit: false,
  editError: '',
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    getTagsFetching: (state) => {
      state.isLoading = true;
    },
    getTagsFetchingSuccess: (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.tags = action.payload;
    },
    getTagsFetchingError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.tags = [];
    },

    createOrEditFetching: (state) => {
      state.isLoadingEdit = true;
    },
    createOrEditFetchingSuccess: (state, action) => {
      state.isLoadingEdit = false;
      state.editError = '';
    },
    createOrEditFetchingError: (state, action) => {
      state.isLoadingEdit = false;
      state.editError = action.payload;
    },
  },
});

export const {
  getTagsFetching,
  getTagsFetchingSuccess,
  getTagsFetchingError,
  createOrEditFetching,
  createOrEditFetchingSuccess,
  createOrEditFetchingError,
} = tagSlice.actions;

export default tagSlice.reducer;
