import { ReactComponent as VideoIcon } from 'assets/icons/video-material.svg';
import { ReactComponent as CommentIcon } from 'assets/icons/comment-icon.svg';
import { ReactComponent as DonwloadIcon } from 'assets/icons/donwload-doc-icon.svg';
import { ReactComponent as ArticleIcon } from 'assets/icons/article-icon.svg';

export const materialTypeIconModel = {
  0: <VideoIcon />,
  1: <DonwloadIcon />,
  2: <CommentIcon />,
  3: <ArticleIcon />,
};

export const typesModel = {
  externalVideo: {
    key: 0,
    name: 'Внешнее видео',
  },
  file: {
    key: 1,
    name: 'Файл',
  },
  externalInterview: {
    key: 2,
    name: 'Интервью/комментарий на стороннем ресурсе',
  },
  externalArticle: {
    key: 3,
    name: 'Статья на стороннем ресурсе',
  },
};
