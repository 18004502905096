import { Grid, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import Tag from 'elements/UI/Tag';

import employeeImg from 'assets/img/empty-avatar.jpg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const EmployeeInfoContainer = styled(Box)(({ theme }) => ({
  border: '2px solid #FFFFFF',
  borderRadius: '36px',
  paddingBottom: '40px',
  paddingRight: '12px',
  paddingLeft: '12px',
  position: 'relative',
  margin: '0 -6px 64px -3px',
  //   overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    // paddingLeft: '16px',
    // paddingRight: '16px',
    margin: '0 -16px 64px -8px',
    paddingRight: '24px',
    paddingLeft: '24px',
  },
  [theme.breakpoints.up('md')]: {
    // paddingLeft: '40px',
    // paddingRight: '40px',
    margin: '0 -40px 64px -20px',
    paddingRight: '20px',
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  // borderRadius: '36px',
  position: 'relative',
  // margin: '-12px 0 0 -15px',
  // overflow: 'hidden',

  [theme.breakpoints.up('ssm')]: {
    // paddingLeft: '16px',
    // paddingRight: '16px',
    margin: '-42px 0 0 -15px',
    // overflow: 'hidden',
  },
  [theme.breakpoints.up('sm')]: {
    // paddingLeft: '16px',
    // paddingRight: '16px',
    margin: '-42px 0 0 -32px',
  },
  [theme.breakpoints.up('md')]: {
    // paddingLeft: '40px',
    // paddingRight: '40px',
    margin: '-44px 0 0 -44px',
  },
}));

const EmployeePhoto = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('ssm')]: {
    marginLeft: '-20px',
    marginTop: '-8px',
    // marginRight: '-12px',
    width: 'calc(100% + 24px)',
  },
  width: '100%',
  borderRadius: '36px',
  backgroundColor: theme.palette.whiteBg.main,
  boxShadow:
    '0px 31px 13px rgba(16, 65, 115, 0.02), 0px 18px 11px rgba(16, 65, 115, 0.06), 0px 8px 8px rgba(16, 65, 115, 0.1), 0px 2px 4px rgba(16, 65, 115, 0.11), 0px 0px 0px rgba(16, 65, 115, 0.11)',
}));

const StyledPracticsContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('mmd')]: {
    paddingLeft: '24px',
    paddingTop: '14px',
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: '36px',
    paddingTop: '14px',
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '0',
    paddingTop: '0',
  },
  [theme.breakpoints.up('lg')]: {
    flexWrap: 'nowrap',
  },
}));

const Practics = ({ employee }) => {
  const { tags } = useSelector((state) => state.tag);
  const navigate = useNavigate();
  return employee?.practices?.length ? (
    <StyledPracticsContainer container spacing='8px'>
      <Grid item xs={12} lg='auto'>
        <Typography component='span' variant='h6' mr='16px'>
          Практики
        </Typography>
      </Grid>
      <Grid item>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            flexWrap: 'wrap',
            cursor: 'pointer',
          }}
        >
          {employee?.practices?.map((el, idx) => (
            <Tag
              onClick={() => navigate('/practices/' + encodeURI(el.name))}
              color={tags.find((tag) => tag.id === el.tagId)?.color}
              key={idx}
            >
              {el.name}
            </Tag>
          ))}
        </Box>
      </Grid>
    </StyledPracticsContainer>
  ) : null;
};

const Text = ({ employee }) => {
  return (
    <Box mt='44px'>
      <Typography sx={{ whiteSpace: 'pre-line' }}>
        {employee.description}
      </Typography>
    </Box>
  );
};

const EmployeeInfo = ({ employee }) => {
  return (
    <EmployeeInfoContainer>
      <InnerContainer>
        <Grid container spacing='30px'>
          <Grid item xs={12} ssm={5} sm={4} lg={3}>
            <EmployeePhoto
              src={employee?.photoPath ?? employeeImg}
              alt='Фото сотрудника'
            />
            <Box display={{ xs: 'none', mmd: 'block', lg: 'none' }}>
              <Practics employee={employee} />
            </Box>
          </Grid>

          <Grid item xs={12} ssm={7} sm={8} lg={9}>
            <Box display='inline' backgroundColor='#DAE1ED'>
              <Typography
                variant='h3'
                component='span'
                style={{ lineHeight: '0.9em' }}
              >
                {[
                  employee.firstName,
                  employee.middleName,
                  employee.lastName,
                ].join(' ')}
              </Typography>
            </Box>
            <Grid container mt='16px' spacing='30px'>
              <Grid item xs={12} lg={7}>
                <Grid
                  container
                  spacing='15px'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Grid item>
                    <Typography component='span' variant='h6'>
                      {employee.position}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography component='span'>{employee.degree}</Typography>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  {employee.email ? (
                    <Grid item>
                      <MailIcon
                        style={{ verticalAlign: 'middle', marginRight: '14px' }}
                      />
                      <Typography component='span'>{employee.email}</Typography>
                    </Grid>
                  ) : null}
                  {employee.phone ? (
                    <Grid item>
                      <PhoneIcon
                        style={{ verticalAlign: 'middle', marginRight: '14px' }}
                      />
                      <Typography component='span'>{employee.phone}</Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={5} display={{ xs: 'none', lg: 'block' }}>
                <Practics employee={employee} />
              </Grid>
            </Grid>
            <Box display={{ xs: 'none', mmd: 'block' }}>
              <Text employee={employee} />
            </Box>
          </Grid>
        </Grid>
      </InnerContainer>

      <Box display={{ mmd: 'none', xs: 'block' }} mt='14px'>
        <Practics employee={employee} />
        <Text employee={employee} />
      </Box>
    </EmployeeInfoContainer>
  );
};

export default EmployeeInfo;
