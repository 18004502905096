import { Box, Typography } from '@mui/material';
import Container from 'elements/UI/Container';
import PageContainer from 'elements/UI/PageContainer';
import Masonry from '@mui/lab/Masonry';
import CardNews from 'elements/UI/CardNews';
import ArrowButton from 'elements/UI/ArrowButton';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventList } from 'store/actions/articleActions';
import { clearEventList } from 'store/slices/articleSlices';
import Breadcrumbs from 'elements/UI/Breadcrums';
import Loader from 'elements/UI/Loader';

const EventsPage = ({}) => {
  const { isLoadingEventList, eventList, eventListPageMetaData } = useSelector(
    (state) => state.article
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventList());

    return () => {
      dispatch(clearEventList());
    };
  }, [dispatch]);

  const loadMore = () => {
    dispatch(getEventList({ Page: eventListPageMetaData?.pageNumber + 1 }));
  };

  return (
    <PageContainer>
      <Container>
        {isLoadingEventList ? (
          <Loader />
        ) : (
          <>
            <Breadcrumbs
              links={[
                { name: 'Главная', to: 'blog' },
                { name: 'Наши мероприятия' },
              ]}
              mb='64px'
            />
            <Typography variant='h2' mb='64px'>
              Наши мероприятия
            </Typography>
            <Box>
              <Masonry
                columns={{ xs: 1, sm: 2, md: 3 }}
                spacing={'24px'}
                sx={{
                  mt: '24px',
                  '& > div': {
                    width: {
                      xs: '100%',
                      sm: 'calc(50% - 24px * 1 / 2)',
                      md: 'calc(33.33% - 24px * 2 / 3)',
                    },
                  },
                }}
              >
                {eventList?.map((el, idx) => (
                  <CardNews news={el} key={idx} showDescription={false} />
                ))}
              </Masonry>
            </Box>
            {eventListPageMetaData?.hasNextPage ? (
              <Box sx={{ mt: '12px' }}>
                <ArrowButton
                  fullWidth={true}
                  withoutArrow={true}
                  onClick={loadMore}
                  disabled={isLoadingEventList}
                  bgColor={'#DAE1ED'}
                >
                  <Typography variant='h6Normal'>
                    Ещё <span>↓</span>
                  </Typography>
                </ArrowButton>
              </Box>
            ) : null}
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default EventsPage;
