import { memo } from 'react';
import { ReactComponent as ArrowAngle } from 'assets/elements/arrow-angle.svg';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';

const ButtonContainer = styled('div')(({ theme, invertColor }) => ({
  border: '2px solid #FFFFFF',
  borderRadius: '8px',
  //   width: 'fit-content',
  transform: 'translate(8px, 0)',
  marginRight: '8px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  filter: invertColor ? `invert(100%)` : '',
  width: '394px',
  maxWidth: '100%',
}));
const Button = styled('button')(({ theme }) => ({
  background: 'white',
  width: '-webkit-fill-available',
  color: 'black',
  borderRadius: '8px',
  padding: '16px 32px',
  [theme.breakpoints.up('sm')]: {
    paddingRight: '100px',
  },
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '29px',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  transform: 'translate(-8px, -8px)',
  transition: 'all .3s',
  '-webkit-tap-highlight-color': 'transparent',
  position: 'relative',
  '&:hover': {
    transform: 'translate(-4px, -4px)',
  },
  '&:active': {
    transform: 'translate(0, 0)',
  },
  '& svg': {
    position: 'absolute',
    right: '-30px',
    top: '50%',
    transform: `translateY(-50%) scale(1, 1)`,
    transition: 'all 0.3s',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  '&.active svg': {
    transform: `translateY(-50%) scale(1, -1)`,
  },
}));

const OpenMoreButton = memo(
  ({ onClick, isOpen, closenText, invertColor = false }) => {
    return (
      <ButtonContainer invertColor={invertColor}>
        <Button onClick={onClick} className={`${isOpen ? 'active' : ''}`}>
          <Typography
            style={{
              display: 'block',
            }}
            variant='h6Normal'
          >
            {isOpen ? 'Свернуть' : closenText}
          </Typography>
          <ArrowAngle />
        </Button>
      </ButtonContainer>
    );
  }
);

export default OpenMoreButton;
