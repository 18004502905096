import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import Anchor from 'elements/UI/Anchor';
import { useEffect } from 'react';

const LandArtcle = ({ toPathAnchor = null }) => {
  useEffect(() => {
    if (toPathAnchor) {
      console.log(toPathAnchor);
      document.getElementById(toPathAnchor)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [toPathAnchor]);
  return (
    <Box
      sx={{
        '& ul > li:not(:last-of-type)': {
          marginBottom: '0.8em',
        },
      }}
    >
      <Anchor name='Земля, недвижимость и строительство' linkId={'first'} />
      <Typography
        variant='h5'
        component='p'
        style={{ textDecoration: 'underline' }}
      >
        Земля, недвижимость и строительство
      </Typography>
      <br />
      <Typography textAlign='justify'>
        Количество сделок с землёй и недвижимостью в России неуклонно растёт.
        Государство остро заинтересовано в обеспечении населения страны
        доступным, качественным и продуманным с точки зрения урбанистки жильём.
        Бизнес также не остаётся без внимания: строящиеся для ведения
        предпринимательской деятельности сооружения должны соответствовать
        высоким стандартам, удовлетворять запросы предпринимателей и
        потребителей. Всё это позволяет повышать эффективность уже существующего
        бизнеса, обогащать бизнес-среду новыми предприятиями, и в целом
        оказывать позитивное влияние на всю экосистему бизнеса и
        предпринимательскую инициативу в стране.
        <br />
        <br />
        То же касается и земли: граждане обустраивают свою жизнь на земле так,
        как того захотят, а бизнес владеет и использует землю для создания
        рабочих мест, производства товаров и предоставления услуг. Всё это, без
        сомнения, способствует экономическому росту и развитию государства,
        увеличению налоговых поступлений и улучшению общего благосостояния в
        стране.
        <br />
        <br />
        Строительный бизнес находится на передовой линии по достижению
        поставленных государством задач в сфере обеспечения населения
        необходимым объёмом недвижимости. Но достигаемые посредством различных
        инвестиционных и строительных проектов задачи крайне сложны с точки
        зрения правового регулирования. Действующее гражданское, земельное и
        градостроительное законодательство стремительно развивается, формируется
        широкая правоприменительная практика по реализации различных
        девелоперских проектов по комплексному развитию территорий — словом, без
        квалифицированной юридической помощи справиться со столь сложной
        системой может быть стать просто невозможным. Игнорирование юридического
        сопровождения любого инвестиционного, строительного проекта, сделки по
        приобретению земли или недвижимости может обернуться множеством
        различных проблем как для граждан, так и для бизнеса.
      </Typography>
      <br />
      <Typography variant='h7' component='p' fontWeight='bold'>
        Мы оказываем следующие юридические услуги:
      </Typography>
      <Typography>
        <ul>
          <li>Комплексное юридическое сопровождение строительных проектов;</li>
          <li>
            Правовое сопровождение при реализации инвестиционных проектов в
            недвижимость;
          </li>
          <li>
            Сопровождение различных сделок в сфере недвижимости и строительства;
          </li>
          <li>
            Защита интересов собственников жилой и коммерческой недвижимости в
            государственных органах и судах.
          </li>
        </ul>
      </Typography>
      <br />
      <Anchor name='Жилищно-коммунальное хозяйство' linkId={'second'} />
      <Typography
        variant='h5'
        component='p'
        style={{ textDecoration: 'underline' }}
      >
        Жилищно-коммунальное хозяйство
      </Typography>
      <br />
      <Typography textAlign='justify'>
        Сфера жилищно-коммунального хозяйства имеет множество нюансов. Она
        состоит из различных составляющих, которые сопровождают людей каждый
        день: водо- и электроснабжение, вывоз ТКО, ТБО и другое. Обслуживанием
        этих потребностей занимается множество различных организаций, которые
        являются профессиональными участниками соответствующего рынка, и
        ежедневно оказывают эти услуги.
        <br />
        <br />
        Конечно, бизнесу в сфере ЖКХ с точки зрения закона приходится нелегко.
        Для каждого вида оказываемых услуг существует множество разнообразных
        нормативных актов, которые жёстко регламентируют порядок осуществления
        предпринимательской деятельности и оказания услуг населению. И поскольку
        последствия недостаточного внимания к правовому сопровождению этой
        деятельности могут быть крайне серьёзными, квалифицированная юридическая
        помощь здесь просто необходима.
      </Typography>
      <br />
      <Typography variant='h7' component='p' fontWeight='bold'>
        Мы оказываем следующие юридические услуги:
      </Typography>
      <Typography>
        <ul>
          <li>Консультирование по вопросам предоставления услуг ЖКХ;</li>
          <li>
            Документальное сопровождение бизнеса в жилищно-коммунальной сфере;
          </li>
          <li>
            Представление и защита интересов бизнеса в государственных органах и
            судах.
          </li>
        </ul>
      </Typography>
    </Box>
  );
};

export default LandArtcle;
