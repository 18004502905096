import { StyledTab } from 'elements/UI/Tabs';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { Box, Button, Drawer } from '@mui/material';
import MaterialsTab from './MaterialsTab';
import { useAuth } from 'hooks/useAuth';
import { AddContentModal } from './AddContentModal';
import { useSelector } from 'react-redux';
import ArticlesTab from './ArticlesTab';
import { Autoplay } from 'swiper';

const tabs = [
  { name: 'Материалы', value: 0 },
  { name: 'Публикации', value: 1 },
  { name: 'Интересные кейсы', value: 2 },
  { name: 'Обзоры', value: 3 },
  { name: 'Комментарии', value: 4 },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      style={value !== index ? { display: 'none' } : {}}
      {...other}
    >
      {children}
    </div>
  );
}

const EmployeeContent = ({ memberId }) => {
  const [isAddContentModalOpen, setIsAddContentModalOpen] = useState(false);
  const [value, setValue] = useState(0);

  const { isAuth } = useAuth();

  const {
    memberContent,
    memberPublications,
    memberInterestingCases,
    memberReview,
    memberComments,
  } = useSelector((state) => state.team);

  return (
    <>
      {isAuth ? (
        <>
          <Button
            variant='outlined'
            onClick={() => setIsAddContentModalOpen(true)}
            sx={{ mb: '16px' }}
          >
            Добавить контент
          </Button>
          <Drawer
            anchor='bottom'
            open={isAddContentModalOpen}
            onClose={() => setIsAddContentModalOpen(false)}
          >
            <AddContentModal
              onClose={() => setIsAddContentModalOpen(false)}
              memberId={memberId}
            />
          </Drawer>
        </>
      ) : null}
      <Box mb='64px' sx={{ cursor: 'grab' }}>
        <Swiper
          className='mySwiper swiperOverflow swiperFullWidth'
          slidesPerView='auto'
          modules={[Autoplay]}
          speed={3000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: true,
          }}
        >
          {tabs.map((el, idx) => (
            <SwiperSlide key={idx} style={{ width: 'auto' }}>
              <StyledTab
                label={el.name}
                value={el.value}
                onClick={() => setValue(el.value)}
                className={value === el.value ? 'Mui-selected' : ''}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <TabPanel value={value} index={0}>
        <MaterialsTab
          content={memberContent}
          isAuth={isAuth}
          memberId={memberId}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MaterialsTab
          content={memberPublications}
          isAuth={isAuth}
          memberId={memberId}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ArticlesTab content={memberInterestingCases} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ArticlesTab content={memberReview} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ArticlesTab content={memberComments} />
      </TabPanel>
    </>
  );
};

export default EmployeeContent;
