import { styled } from '@mui/system';
import { ReactComponent as SearchIcom } from 'assets/icons/search-icon.svg';
import { ReactComponent as MenuIcom } from 'assets/icons/menu-icon.svg';
import { ReactComponent as CloseIcom } from 'assets/icons/close-icon.svg';
import Container from 'elements/UI/Container';
import LogoPrimGroup from '../Logo';
import { useDispatch } from 'react-redux';
import { toggleMenu, toggleSearch } from 'store/slices/uiSlices';
import { Grid, ButtonBase, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const HeaderInner = styled(Box)(({ theme, iswhitebg }) => ({
  // paddingTop: '24px',
  // paddingBottom: '24px',

  // paddingTop: '6px',
  // paddingBottom: '6px',

  marginTop: '4px',
  padding: '5px 20px',
  [theme.breakpoints.up('mmd')]: {
    marginTop: '8px',
    padding: '8px 28px',
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: '8px',
    padding: '16px 28px',
  },
  borderRadius: '36px',
  border: '2px solid white',

  marginLeft: '-6px',
  marginRight: '-6px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: '-16px',
    marginRight: '-16px',
  },
  [theme.breakpoints.up('md')]: {
    // paddingLeft: '40px',
    // paddingRight: '40px',
    marginLeft: '-40px',
    marginRight: '-40px',
  },

  backgroundColor: iswhitebg
    ? theme.palette.whiteBg.main
    : theme.palette.blueBg.main,
  transition: 'all .25s',
  '&.transparent': {
    background: 'transparent',
    border: '2px solid transparent',
  },
  // '&:not(.onTop)': {
  //   paddingTop: '6px',
  //   paddingBottom: '6px',
  // },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.headerBtnBg.main,
  width: '32px',
  height: '32px',
  borderRadius: '8px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  transition: 'all 1.2s',
  [theme.breakpoints.up('md')]: {
    width: '48px',
    height: '48px',
  },
  '& svg': {
    transition: 'all 1.2s',
    filter: `invert(0%)`,
    width: '50%',
    height: '50%',
  },

  '&.white': {
    backgroundColor: '#FFFFFF',
    '& svg': {
      filter: `invert(100%)`,
    },
  },
}));

const Header = ({
  whiteLogo = false,
  invertBtnColors = false,
  menuIconClose = false,
  searchIconClose = false,
  iswhitebg,
  transparentStyle,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <Container>
        <HeaderInner
          iswhitebg={iswhitebg}
          className={transparentStyle ? 'transparent' : ''}
        >
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Box
                sx={{
                  width: { xs: '200px', mmd: 'auto' },
                  '& > svg': { maxWidth: '100%' },
                  cursor: 'pointer',
                }}
                onClick={() => {
                  location.pathname === '/main'
                    ? window.scrollTo({ top: 0, behavior: 'smooth' })
                    : navigate('/main');
                }}
              >
                <LogoPrimGroup isWhiteText={whiteLogo} />
              </Box>
            </Grid>
            <Grid item>
              <Button
                sx={{ mr: '24px' }}
                onClick={() => dispatch(toggleSearch())}
                className={invertBtnColors ? 'white' : ''}
              >
                {searchIconClose ? <CloseIcom /> : <SearchIcom />}
              </Button>
              <Button
                onClick={() => dispatch(toggleMenu())}
                className={invertBtnColors ? 'white' : ''}
              >
                {menuIconClose ? <CloseIcom /> : <MenuIcom />}
              </Button>
            </Grid>
          </Grid>
        </HeaderInner>
      </Container>
    </>
  );
};

export default Header;
