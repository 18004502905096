import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
// import videoUrl from 'assets/video/main-video.mp4';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as SoundOffIcon } from 'assets/icons/soundOff.svg';
import { ReactComponent as SoundOnIcon } from 'assets/icons/soundOn.svg';
import _ from 'lodash';

const videoUrl = process.env.REACT_APP_MAINVIDEO_URL;

const VideoContainer = styled('div')(({ theme }) => ({
  zIndex: '100',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  '& > div:not(.curtains)': {
    width: '100%',
    position: 'relative',
  },
  '.curtains': {
    position: 'absolute',
    background: theme.palette.blueBg.main,
    top: '-10px',
    // left: 0,
    bottom: '-10px',
    // right: 0,
    transition: 'all .1s',
  },
  'div:nth-of-type(2)': {
    left: '-10px',
    right: '50%',
  },
  'div:nth-of-type(3)': {
    left: '50%',
    right: '-10px',
  },
  '& #soundBtn': {
    opacity: '0',
  },
  '&:hover #soundBtn': {
    opacity: '1',
  },
}));

const MuteBtn = ({ isSoundOn, toggle, id }) => {
  return (
    <Box
      id={id}
      onClick={toggle}
      sx={{
        width: '230px',
        height: '50px',
        border: '2px solid #000000',
        borderRadius: '8px',
        position: 'absolute',
        right: '50%',
        transform: 'translateX(50%)',
        top: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        cursor: 'pointer',
        zIndex: '1',
        transition: '.3s',
        '&:hover': {
          backgroundColor: '#DAE1ED',
          border: '2px solid #DAE1ED',
        },
      }}
    >
      <Box sx={{ height: '24px', display: 'flex', alignItems: 'center' }}>
        {isSoundOn ? <SoundOnIcon /> : <SoundOffIcon />}
      </Box>

      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: '16px',
          lineHeight: '29px',
          color: '#000000',
          width: '145px',
        }}
      >
        {isSoundOn ? 'Выключить' : 'Включить'} звук
      </Typography>
    </Box>
  );
};

const Video = () => {
  const [soundOn, setSoundOn] = useState(false);

  const videoRef = useRef();
  const leftCurtain = useRef();
  const rightCurtain = useRef();

  const changePageHeightEvent = useSelector(
    (state) => state.ui.changePageHeightEvent
  );

  const setCurtains = useCallback((persent) => {
    leftCurtain.current.style.right = 50 + (50 / 100) * persent + '%';
    rightCurtain.current.style.left = 50 + (50 / 100) * persent + '%';
  }, []);

  const handleScroll = useCallback(() => {
    let bottomPosition = window.scrollY + document.documentElement.clientHeight;
    let videoHight = videoRef.current?.clientHeight;
    let videoPosition =
      videoRef.current?.getBoundingClientRect().top + window.pageYOffset ||
      document.documentElement.scrollTop;

    let persent = Math.floor(
      ((bottomPosition - videoPosition) / videoHight) * 100
    );
    let persentFinal = Math.max(25, Math.min(persent, 100) + 25);
    if (persentFinal > 70) {
      // videoRef.current.play();
    }
    setCurtains(persentFinal);
  }, [setCurtains]);

  useEffect(() => {
    handleScroll();
    const throttledScroll = _.throttle(handleScroll, 100);
    window.addEventListener('scroll', throttledScroll);

    return () => window.removeEventListener('scroll', throttledScroll);
  }, [handleScroll]);

  useEffect(() => {
    handleScroll();
  }, [handleScroll, changePageHeightEvent]);

  return (
    <VideoContainer>
      <div>
        <MuteBtn
          isSoundOn={soundOn}
          toggle={() => setSoundOn((prevState) => !prevState)}
          id='soundBtn'
        />
        {/* <img
          ref={videoRef}
          width='100%'
          src='https://vogazeta.ru/uploads/full_size_1557816965-290e4b7bc202668514ed6cac44b21ed7.jpg'
        /> */}
        <video
          loop
          muted={!soundOn ? 'muted' : false}
          ref={videoRef}
          width='100%'
          autoPlay
          playsInline='playsInline'
          style={{ pointerEvents: 'none' }}
        >
          <source src={videoUrl} type='video/mp4'></source>
        </video>
      </div>
      <div
        className='curtains'
        ref={leftCurtain}
        style={{ transition: 'all 0.25s' }}
      />
      <div
        className='curtains'
        ref={rightCurtain}
        style={{ transition: 'all 0.25s' }}
      />
    </VideoContainer>
  );
};

export default Video;
