import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import Container from 'elements/UI/Container';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tag from 'elements/UI/Tag';
import { object, string } from 'yup';
import { createOrEditTags, tagRemove } from 'store/actions/tagActions';
import { ConfirmModal } from 'elements/components/ConfirmModal';

const modes = {
  creating: 1,
  editing: 2,
};

const schema = object().shape({
  name: string()
    .required('Название обязательно')
    .max(300, 'Максимальная длинна названия - 300'),
  color: string().required('Цвет - обязательное поле'),
});

export const TagModal = ({ onClose }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(null);

  const [name, setName] = useState('');
  const [color, setColor] = useState('#000000');
  const [id, setId] = useState(null);
  const [canEdit, setCanEdit] = useState(false);

  const [tagToDelete, setTagToDelete] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [validationErrors, setValidationErrors] = useState(null);

  const dispatch = useDispatch();
  const { isLoading, tags, editError, isLoadingEdit } = useSelector(
    (state) => state.tag
  );

  const handleNewCreate = () => {
    setMode(modes.creating);
    setOpen(true);
  };

  const handleEdit = (tag) => {
    setMode(modes.editing);
    setOpen(true);
    setId(tag.id);
    setName(tag.name);
    setColor(tag.color);
    setCanEdit(tag.canNotBeDeleted);
  };
  const handleClose = () => {
    setOpen(false);
    setId(null);
    setName('');
    setColor('#000000');
    setValidationErrors('');
    setCanEdit(false);
  };

  const onCreate = async () => {
    let form = { name: name, color: color };
    if (id) {
      form = { ...form, id: id };
    }
    try {
      await schema.validate(form);
      setValidationErrors('');
      const { ok } = await dispatch(createOrEditTags(form));

      if (ok) {
        handleClose();
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrors(error.errors[0]);
      }
      throw error;
    }
  };

  const onRemove = async (tag) => {
    setTagToDelete(tag);
    setIsDeleteModalOpen(true);
  };

  const handleRemove = async (tag) => {
    let form = { id: tag?.id };
    try {
      const { ok } = await dispatch(tagRemove(form));
      if (ok) {
        setIsDeleteModalOpen(false);
        setTagToDelete(null);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Container>
        <Grid
          mt={2}
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography variant='h3'>Управление тегами</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon stroke='black' />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container spacing='8px' mb='24px' mt='12px'>
          {tags.map((el, idx) => (
            <Grid item>
              <Box
                sx={{
                  border: '1px solid #00000080',
                  borderRadius: '40px',
                  p: '1px 2px',
                }}
              >
                <Tag
                  onClick={() => handleEdit(el)}
                  sx={{ cursor: 'pointer' }}
                  color={el.color}
                >
                  {el.name}
                </Tag>
                {!el.canNotBeDeleted ? (
                  <IconButton
                    aria-label='delete'
                    size='small'
                    onClick={() => onRemove(el)}
                  >
                    <DeleteIcon fontSize='inherit' />
                  </IconButton>
                ) : null}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Button variant='outlined' onClick={handleNewCreate}>
          Новый тег
        </Button>
      </Container>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            width: '400px',
            padding: '24px',
            backgroundColor: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '24px',
          }}
        >
          <Typography variant='h4' sx={{ mb: '24px' }}>
            {mode === modes.creating ? 'Создание' : 'Редактирование'}
          </Typography>
          <TextField
            size='small'
            label='Название'
            variant='outlined'
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={canEdit}
            sx={{ mb: '24px' }}
          />
          <input
            type='color'
            id='head'
            name='head'
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
          <Typography
            variant='body1'
            sx={{ ml: '20px' }}
            component={'label'}
            for='head'
          >
            Цвет
          </Typography>
          <Typography
            sx={{ color: '#ca0000', mb: '28px', mt: '8px' }}
            variant='subtitle1'
          >
            {validationErrors || editError}
          </Typography>
          <Grid container justifyContent='space-around' mt='24px'>
            <Grid item>
              <Button onClick={onCreate} disabled={isLoading}>
                {mode === modes.creating ? 'Создать' : 'Сохранить'}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose}>Отмена</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onSubmit={() => handleRemove(tagToDelete)}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setTagToDelete(null);
        }}
        isLoading={isLoadingEdit}
      />
    </Box>
  );
};
