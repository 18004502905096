import { Box, styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../Footer';
import SubscribeBlock from '../SubscribeBlock';
import TopBar from '../TopBar';
import Navbar from '../Navbar';
import { CookieConsentModal } from '../CookieConsentModal';

const ContentContainer = styled(Box)(({ theme, iswhitebg }) => ({
  minHeight: '100vh',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  // overflowX: 'hidden',
  backgroundColor: iswhitebg
    ? theme.palette.whiteBg.main
    : theme.palette.blueBg.main,
}));
const Content = styled(Box)(({ theme }) => ({
  flexGrow: '1',
  paddingBottom: '150px',
  position: 'relative',
}));

const OutletContainer = styled(Box)(({ theme, enableoverflow }) => ({
  overflow: enableoverflow ? '' : 'hidden',
  paddingTop: '4px',
  paddingBottom: '32px',
}));

const NavContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '150px',
  bottom: 0,
  right:
    'calc((100vw - (min(100%, 1800px) - 400px))/2 + (min(100%, 1800px) - 400px) + 40px + 12px)',
  left: '12px',
  zIndex: 0,
  '& .navbar': {
    position: 'sticky',
    top: '150px',
    // background: '#1212ff',
  },
  [theme.breakpoints.down('xl')]: {
    display: 'none',
  },
}));

const BottomContaier = styled(Box)(({ theme, iswhitebg }) => ({
  background: iswhitebg
    ? 'linear-gradient(180deg, rgba(233, 236, 241, 0) 0%, #E9ECF1 18.75%)'
    : theme.palette.whiteBg.main,
  position: 'relative',
  '&:after': {
    content: "''",
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-130px',
    height: '130px',
    background: iswhitebg
      ? ''
      : 'linear-gradient(180deg, #DAE1ED00 0%, #F0F0F0 100%)',
  },
}));

const Template = ({ children }) => {
  const { pathname } = useLocation();
  const [iswhitebg, setIsWhiteBg] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    let page = pathname.split('/')?.[1];

    setIsWhiteBg(page === 'article' || page === 'blog' || page === 'events');
  }, [pathname]);

  return (
    <ContentContainer iswhitebg={iswhitebg}>
      <CookieConsentModal />
      <TopBar iswhitebg={iswhitebg} />
      <Content>
        {pathname.split('/')?.[1] === 'article' ? null : (
          <NavContainer>
            <Navbar pathname={pathname} />
          </NavContainer>
        )}

        <OutletContainer
          enableoverflow={pathname.split('/')?.[1] === 'article'}
        >
          <Outlet />
        </OutletContainer>
      </Content>
      <BottomContaier iswhitebg={iswhitebg}>
        <SubscribeBlock />
        <Footer />
      </BottomContaier>
    </ContentContainer>
  );
};

export default Template;
