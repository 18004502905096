import articleAPIRoutes from 'apiRoutes/article';
import {
  createOrUpdateArticleFetching,
  createOrUpdateArticleFetchingError,
  createOrUpdateArticleFetchingSuccess,
  getArticleFetching,
  getArticleFetchingError,
  getArticleFetchingSuccess,
  getArticleEditInfoFetching,
  getArticleEditInfoFetchingError,
  getArticleEditInfoFetchingSuccess,
  getArticleListFetching,
  getArticleListFetchingError,
  getArticleListFetchingSuccess,
  getMainArticleListFetchingSuccess,
  getMainArticleListFetchingError,
  clearArticleList,
  getCommentListFetching,
  getCommentListFetchingSuccess,
  getCommentListFetchingError,
  createCommentFetching,
  createCommentFetchingSuccess,
  createCommentFetchingError,
  clearCommentList,
  getArticleSearchListFetching,
  getArticleSearchListFetchingSuccess,
  getArticleSearchListFetchingError,
  clearArticleSearchList,
  getMainArticleListFetching,
  getPrimInfoListListFetching,
  getPrimInfoListListFetchingSuccess,
  getPrimInfoListListFetchingError,
  getLastNewsFetching,
  getLastNewsFetchingSuccess,
  getLastNewsFetchingError,
  getEventListFetching,
  getEventListFetchingSuccess,
  getEventListFetchingError,
  clearEventList,
} from 'store/slices/articleSlices';
import getResponse from 'utils/getResponse';

export const getMainArticleList = () => async (dispatch) => {
  try {
    dispatch(getMainArticleListFetching());

    const response = await articleAPIRoutes.getMainArticleList();
    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(getMainArticleListFetchingSuccess(data));
    } else {
      dispatch(getMainArticleListFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getMainArticleListFetchingError(e.message));
  }
};

export const getPrimInfoList = () => async (dispatch) => {
  try {
    dispatch(getPrimInfoListListFetching());

    const response = await articleAPIRoutes.getArticleList({
      Page: 1,
      Type: 2,
    });
    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(getPrimInfoListListFetchingSuccess(data));
    } else {
      dispatch(getPrimInfoListListFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getPrimInfoListListFetchingError(e.message));
  }
};

export const getLastNews = () => async (dispatch) => {
  try {
    dispatch(getLastNewsFetching());

    const response = await articleAPIRoutes.getArticleList({
      Page: 1,
      Type: 0,
    });
    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(getLastNewsFetchingSuccess(data));
    } else {
      dispatch(getLastNewsFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getLastNewsFetchingError(e.message));
  }
};

export const getArticleList =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch(getArticleListFetching());

      const response = await articleAPIRoutes.getArticleList({
        page: page,
        Type: 0,
      });
      const { ok, data, errors } = getResponse(response);
      if (ok) {
        dispatch(getArticleListFetchingSuccess(data));
      } else {
        dispatch(getArticleListFetchingError(errors[0].value));
      }
    } catch (e) {
      dispatch(getArticleListFetchingError(e.message));
    }
  };

export const getArticle = (form) => async (dispatch) => {
  try {
    dispatch(getArticleFetching());

    const response = await articleAPIRoutes.getArticle(form);
    const { ok, data, errors, httpStatusCode } = getResponse(response);

    if (ok) {
      dispatch(getArticleFetchingSuccess(data));
    } else {
      dispatch(
        getArticleFetchingError(
          httpStatusCode === 404 ? 'not_found' : errors[0].value
        )
      );
    }
  } catch (e) {
    dispatch(getArticleFetchingError(e.message));
  }
};
export const getArticleEditInfo = (form) => async (dispatch) => {
  try {
    dispatch(getArticleEditInfoFetching());

    const response = await articleAPIRoutes.getArticleEditInfo(form);
    const { ok, data, errors } = getResponse(response);
    if (ok) {
      dispatch(getArticleEditInfoFetchingSuccess(data));
    } else {
      dispatch(getArticleEditInfoFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getArticleEditInfoFetchingError(e.message));
  }
};

export const createOrUpdateArticle = (form) => async (dispatch) => {
  try {
    dispatch(createOrUpdateArticleFetching());

    const response = await articleAPIRoutes.articleCreateOrEdit(form);

    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(createOrUpdateArticleFetchingSuccess(data));
      if (form.id) {
        dispatch(getArticle({ id: form.id }));
        dispatch(getArticleEditInfo({ id: form.id }));
      } else {
        if (form.type === 1) {
          dispatch(clearEventList());
          dispatch(getEventList());
        } else {
          dispatch(clearArticleList());
          dispatch(getMainArticleList());
          dispatch(getArticleList());
        }
      }
    } else {
      dispatch(createOrUpdateArticleFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(createOrUpdateArticleFetchingError(e.message));
  }
};

export const articleRemove = (form) => async (dispatch) => {
  try {
    dispatch(createOrUpdateArticleFetchingSuccess());

    const response = await articleAPIRoutes.articleRemove(form);
    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(createOrUpdateArticleFetchingSuccess(data));
    } else {
      dispatch(createOrUpdateArticleFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(createOrUpdateArticleFetchingError(e.message));
  }
};

export const getCommentList = (form) => async (dispatch) => {
  try {
    dispatch(getCommentListFetching());

    const response = await articleAPIRoutes.getArticleComments(form);
    const { ok, data, errors } = getResponse(response);
    if (ok) {
      dispatch(getCommentListFetchingSuccess(data));
    } else {
      dispatch(getCommentListFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getCommentListFetchingError(e.message));
  }
};

export const createComment = (form) => async (dispatch) => {
  try {
    dispatch(createCommentFetching());

    const response = await articleAPIRoutes.articleCommentCreate(form);

    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(createCommentFetchingSuccess(data));
      dispatch(clearCommentList());
      dispatch(getCommentList({ articleId: form.articleId, page: 1 }));
    } else {
      dispatch(createCommentFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(createCommentFetchingError(e.message));
  }
};

export const removeComment = (form) => async (dispatch) => {
  try {
    dispatch(createCommentFetching());

    const response = await articleAPIRoutes.articleCommentRemove(form);
    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(createCommentFetchingSuccess(data));
    } else {
      dispatch(createCommentFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(createCommentFetchingError(e.message));
  }
};

export const getSearchArticleList =
  (form, needClear = true) =>
  async (dispatch) => {
    try {
      dispatch(getArticleSearchListFetching());

      const response = await articleAPIRoutes.getArticleList({
        ...form,
        Type: 3,
      });
      const { ok, data, errors } = getResponse(response);
      if (ok) {
        if (needClear) {
          dispatch(clearArticleSearchList());
        }
        dispatch(getArticleSearchListFetchingSuccess(data));
      } else {
        dispatch(getArticleSearchListFetchingError(errors[0].value));
      }
    } catch (e) {
      dispatch(getArticleSearchListFetchingError(e.message));
    }
  };

export const getEventList = (form) => async (dispatch) => {
  try {
    dispatch(getEventListFetching());

    const response = await articleAPIRoutes.getArticleList({
      ...form,
      Type: 1,
    });
    const { ok, data, errors } = getResponse(response);
    if (ok) {
      dispatch(getEventListFetchingSuccess(data));
    } else {
      dispatch(getEventListFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getEventListFetchingError(e.message));
  }
};
