import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowButton from 'elements/UI/ArrowButton';
import CardNews from 'elements/UI/CardNews';
import { useAuth } from 'hooks/useAuth';
import { mockPrimInfo } from 'mock';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { articleRemove, getPrimInfoList } from 'store/actions/articleActions';
import { ConfirmModal } from '../ConfirmModal';

const PrimInfoBlock = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDelItem, setCurrentDelItem] = useState(null);

  const [showCount, setShowCount] = useState(2);
  const [currentPage, setCurrentPage] = useState(0);

  const { primInfoList } = useSelector((state) => state.article);

  const theme = useTheme();
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch();

  const { isAuth } = useAuth();

  useEffect(() => {
    dispatch(getPrimInfoList());
  }, [dispatch]);

  useEffect(() => {
    if (greaterThanMd) {
      setShowCount(3);
    } else {
      setShowCount(2);
    }
  }, [greaterThanMd]);

  const handleDelete = async () => {
    let form = { id: currentDelItem.id };
    try {
      const { ok } = await dispatch(articleRemove(form));
      if (ok) {
        setIsDeleteModalOpen(false);
        dispatch(getPrimInfoList());
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      {primInfoList.length > 0 ? (
        <>
          <Typography variant='h2' mb='64px'>
            PRIMинфо(рм)
          </Typography>
          <Box sx={{ transition: 'height 0.3s' }}>
            <Grid container spacing='40px'>
              {primInfoList
                .slice(
                  Math.max(0, currentPage * showCount),
                  (currentPage + 1) * showCount
                )
                .map((el, idx) => (
                  <Grid item key={idx} xs={6} md={4}>
                    <CardNews
                      news={el}
                      dltBtn={isAuth ? true : false}
                      onDelete={() => {
                        setCurrentDelItem(el);
                        setIsDeleteModalOpen(true);
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '32px',
            }}
            mt='48px'
          >
            <ArrowButton
              reverseArrow={true}
              disabled={currentPage === 0}
              onClick={() => setCurrentPage((prev) => prev - 1)}
            >
              Назад
            </ArrowButton>
            <Typography variant='h5' width='100px' textAlign='center'>
              {currentPage + 1} / {Math.ceil(primInfoList.length / showCount)}
            </Typography>
            <ArrowButton
              disabled={(currentPage + 1) * showCount >= primInfoList.length}
              onClick={() => setCurrentPage((prev) => prev + 1)}
            >
              Далее
            </ArrowButton>
          </Box>
        </>
      ) : null}
      {isAuth ? (
        <ConfirmModal
          isOpen={isDeleteModalOpen}
          onSubmit={handleDelete}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      ) : null}
    </>
  );
};

export default PrimInfoBlock;
