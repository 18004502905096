import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Container from 'elements/UI/Container';
import { useCallback, useState } from 'react';
import ContackNsk from './ContactNsk';
import ContackTsk from './ContactTsk';

const ContactContainer = styled('div')(({ theme }) => ({
  border: '2px solid #FFFFFF',
  borderRadius: '36px',
  paddingBottom: 0,
  position: 'relative',
  margin: '0 -6px',
  [theme.breakpoints.up('sm')]: {
    margin: '0 -16px',
  },
  [theme.breakpoints.up('md')]: {
    margin: '0 -40px',
  },
  '& .practicesLabel': {
    color: 'black',
    background: theme.palette.blueBg.main,
    textShadow: 'none',
    position: 'absolute',
    top: '-24px',
    left: '16px',
    [theme.breakpoints.up('sm')]: {
      left: '32px',
      top: '-28px',
    },
    [theme.breakpoints.up('md')]: {
      left: '80px',
      top: '-36px',
    },
  },
}));

const StyledTabs = styled((props) => <Tabs {...props} />)({
  '& .MuiTabs-flexContainer': {
    gap: '48px',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    padding: '0',
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
      lineHeight: '39px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '36px',
      lineHeight: '44px',
    },
    color: 'black',
    opacity: '0.5',
    transition: 'all .3s',
    paddingBottom: '5px',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: '""',
      bottom: 0,
      left: 0,
      right: 0,
      height: '2px',
      background: 'black',
    },
    '&.Mui-selected': {
      color: 'black',
      opacity: '1',
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {children}
    </div>
  );
}

const ContactBlock = () => {
  const [value, setValue] = useState('tsk');

  return (
    <>
      <ContactContainer>
        <Typography variant='h2' className='practicesLabel'>
          Контакты
        </Typography>
        <Grid container spacing='40px'>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                padding: {
                  xs: '42px 16px',
                  sm: '42px 32px',
                  md: '80px 10px 20px 80px',
                  lg: '80px 10px 130px 80px',
                },
                minHeight: {
                  xs: '510px',
                  sm: '440px',
                  md: '495px',
                  lg: '525px',
                },
              }}
            >
              <StyledTabs
                value={value}
                onChange={(e, newValue) => {
                  setValue(newValue);
                }}
                sx={{ mb: '64px' }}
              >
                <StyledTab label='Томск' value='tsk' />
                <StyledTab label='Новосибирск' value='nsk' />
              </StyledTabs>
              <TabPanel value={value} index={'tsk'}>
                <ContackTsk />
              </TabPanel>
              <TabPanel value={value} index={'nsk'}>
                <ContackNsk />
              </TabPanel>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                borderRadius: '36px',
                width: '100%',
                height: { xs: '400px', md: '500px', lg: '100%' },
                overflow: 'hidden',
                backgroundColor: 'white',
                '& .hidden': {
                  display: 'none',
                },
              }}
            >
              <iframe
                title='tsk-map'
                src='https://yandex.ru/map-widget/v1/?um=constructor%3A8f2b9c0e77f4e1f9ed984964a5b69f3f20d39efc2d0d70587a51f743665e2c89&amp;source=constructor'
                width='100%'
                height='100%'
                frameBorder='0'
                className={value === 'tsk' ? 'open' : 'hidden'}
              ></iframe>
              <iframe
                title='nsk-map'
                src='https://yandex.ru/map-widget/v1/?um=constructor%3Ad9ac2b17fbb878bc853c1447dafa03308602ad8ce2bbc79799bddc67b159aff5&amp;source=constructor'
                width='100%'
                height='100%'
                frameBorder='0'
                className={value === 'nsk' ? 'open' : 'hidden'}
              ></iframe>
            </Box>
          </Grid>
        </Grid>
      </ContactContainer>
    </>
  );
};

export default ContactBlock;
