import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import PaperBlank from 'elements/UI/PaperBlank';
import InfoIcon from '@mui/icons-material/Info';
import { useCookies } from 'react-cookie';

const fileName = process.env.REACT_APP_COOKIE_CONSENT_FILE;
const host = process.env.REACT_APP_YANDEXHOST;

export const CookieConsentModal = ({}) => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const giveCookieConsent = () => {
    setCookie('cookieConsent', true, { path: '/' });
  };

  if (cookies.cookieConsent) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '24px',
        zIndex: '200',
        left: '50%',
        maxWidth: '800px',
        width: '90%',
        transform: 'translate(-50%)',
      }}
    >
      <PaperBlank sx={{ padding: '28px 24px' }}>
        <Grid container wrap='nowrap' spacing={3} alignItems='center'>
          <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
            <InfoIcon fontSize='large' htmlColor='#104173' />
          </Grid>
          <Grid item>
            <Typography
              variant='body2'
              sx={{
                mb: '14px',
                fontSize: { xs: '12px', sm: '16px' },
                textAlign: 'justify',
              }}
            >
              Продолжая просмотр настоящего сайта, Вы соглашаетесь с
              использованием файлов Cookie и иных методов, средств и
              инструментов интернет-статистики и настройки, применяемых на сайте
              для повышения удобства использования сайта, а также, в
              определенных случаях, для продвижения работ и услуг АБ «PRIM
              Group», предоставления информации о предстоящих мероприятиях.
            </Typography>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Typography
                  sx={{
                    color: '#104173',
                    borderBottom: '1px dashed #104173',
                    opacity: '75%',
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  component={'a'}
                  href={host + fileName}
                  target='_blank'
                >
                  Подробнее
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: '#104173',
                    borderBottom: '1px dashed #104173',
                    cursor: 'pointer',
                  }}
                  onClick={giveCookieConsent}
                >
                  Продолжить
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaperBlank>
    </Box>
  );
};
