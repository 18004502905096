import { styled } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import Menu from '../Menu';
import Search from '../Search';
import { useDispatch, useSelector } from 'react-redux';
import { closeAll } from 'store/slices/uiSlices';
import { Box, Drawer } from '@mui/material';
import Header from '../Header';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

const HeaderContainer = styled(Box)(({ theme, iswhitebg }) => ({
  zIndex: 100,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,

  // backgroundColor: iswhitebg
  //   ? theme.palette.whiteBg.main
  //   : theme.palette.blueBg.main,
  transition: 'all 0.2s',
  lineHeight: '1em',

  '&.onTop': {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
}));

const TopBar = ({ iswhitebg }) => {
  const menuIsOpen = useSelector((state) => state.ui.menuIsOpen);
  const searchIsOpen = useSelector((state) => state.ui.searchIsOpen);
  const [onTop, setOnTop] = useState(true);
  const [isMainPage, setIsMainPage] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleScroll = useCallback(() => {
    if (window.scrollY > 0) setOnTop(false);
    else setOnTop(true);
  }, []);

  useEffect(() => {
    if (location?.pathname === '/main') setIsMainPage(true);
    else setIsMainPage(false);

    dispatch(closeAll());
  }, [location, dispatch]);

  useEffect(() => {
    const throttledScroll = _.throttle(handleScroll, 100);
    if (isMainPage) {
      window.addEventListener('scroll', throttledScroll);
    } else {
      window.removeEventListener('scroll', throttledScroll);
    }

    return () => window.removeEventListener('scroll', throttledScroll);
  }, [isMainPage, handleScroll]);

  return (
    <>
      <HeaderContainer
        className={`${isMainPage && onTop ? 'onTop' : ''}`}
        iswhitebg={iswhitebg}
      >
        <Header
          whiteLogo={isMainPage && onTop}
          iswhitebg={iswhitebg}
          transparentStyle={isMainPage && onTop}
        />
      </HeaderContainer>
      <Drawer
        anchor={'top'}
        open={menuIsOpen}
        onClose={() => dispatch(closeAll())}
      >
        <Menu onClose={() => dispatch(closeAll())} />
      </Drawer>
      <Drawer
        anchor={'bottom'}
        open={searchIsOpen}
        onClose={() => dispatch(closeAll())}
      >
        <Search onClose={() => dispatch(closeAll())} />
      </Drawer>
    </>
  );
};

export default TopBar;
