import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Container from 'elements/UI/Container';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import TextEditor from 'pages/Article/components/TextEditor';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filesAPIRoutes from 'apiRoutes/files';
import { createOrUpdateArticle } from 'store/actions/articleActions';
import { object, string } from 'yup';
import { articleTypesModel } from '../models';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, theme) {
  return {
    fontWeight: theme.typography.fontWeightRegular,
  };
}

const schema = object().shape({
  title: string().required('Название обязательно'),
});

export const AddArticleModal = ({
  onClose,
  article = null,
  articleId = null,
}) => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [isMainPage, setIsMainPage] = useState(false);
  const [link, setLink] = useState('');
  const [sourceName, setSourceName] = useState('');
  const [text, setText] = useState('');
  const [coverImg, setCoverImg] = useState(null);
  const [author, setAuthor] = useState(null);
  const [pickedTags, setPickedTags] = useState([]);
  const [shortDescription, setShortDescription] = useState('');
  const [type, setType] = useState(articleTypesModel.post.key);

  const [showHtmlText, setShowHtmlText] = useState(false);

  const [imgLoading, setImgLoading] = useState(false);

  const [validationErrors, setValidationErrors] = useState(null);

  const dispatch = useDispatch();

  const { tags } = useSelector((state) => state.tag);
  const { team } = useSelector((state) => state.team);

  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPickedTags(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleUploadCover = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('uploadFile', file);

    try {
      setImgLoading(true);
      const response = await filesAPIRoutes.upload(formData);
      setImgLoading(false);
      let imageUrl = response?.data?.entity;
      if (imageUrl) {
        setCoverImg(imageUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveCover = async (img) => {
    if (article) {
      setCoverImg(null);
    } else {
      try {
        let form = {
          id: img.id,
          secretKey: img.secretKey,
        };
        await filesAPIRoutes.remove(form);
        setCoverImg(null);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    setId(articleId);
    if (article) {
      setName(article?.title);
      setType(article?.type);
      setIsMainPage(article?.isMainPage);
      setLink(article?.link);
      setSourceName(article?.sourceName);
      setText(article?.text?.replaceAll('<p><br></p>', ''));
      setCoverImg(article?.cover);
      setAuthor(article?.memberCardId);
      setPickedTags(
        tags.filter((tag) => article?.tags?.find((el) => el.name === tag.name))
      );
      setShortDescription(article?.shortComment);
    }
  }, [article, articleId, tags]);

  const handleClose = () => {
    setId(null);
    setName('');
    setType(articleTypesModel.post.key);
    // setIsMainPage(false);
    setLink('');
    setSourceName('');
    setText('');
    setCoverImg(null);
    setAuthor(null);
    setPickedTags([]);
    setShortDescription('');

    setValidationErrors('');

    onClose();
  };

  const handleSubmit = async () => {
    let form = {
      title: name,
    };
    if (type) {
      form = { ...form, type: type };
    }
    if (type !== articleTypesModel.external.key) {
      form = {
        ...form,
        text: text.replace('<p><br></p>', ''),
        isMainPage: isMainPage,
        tags: pickedTags,
        tagIds: pickedTags.map((el) => el.id),
        memberCardId: author,
        cover: coverImg,
        shortComment: shortDescription,
      };
    } else {
      form = {
        ...form,
        link: link,
        tags: [],
        tagIds: [],
      };
    }
    if (type === articleTypesModel.external.key && sourceName) {
      form = {
        ...form,
        sourceName: sourceName,
      };
    }

    if (id) {
      form = { ...form, id: id };
    }
    try {
      setValidationErrors('');
      await schema.validate(form);
      const { ok } = await dispatch(createOrUpdateArticle(form));
      if (ok) {
        handleClose();
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrors(error.errors[0]);
      }
      throw error;
    }
  };
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Container>
        <Grid
          mt={2}
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography variant='h3'>
              {article ? 'Редактирование' : 'Добавление'} статьи
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon stroke='black' />
            </IconButton>
          </Grid>
        </Grid>

        <TextField
          sx={{ mt: '32px', mb: '32px' }}
          label='Название'
          variant='outlined'
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {!article ? (
          <FormControl sx={{ mb: '16px' }}>
            <FormLabel id='demo-radio-buttons-group-label'>Тип</FormLabel>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              defaultValue='female'
              name='radio-buttons-group'
              value={type}
              onChange={(e) => setType(+e.target.value)}
            >
              {Object.values(articleTypesModel).map((typeElement) => (
                <FormControlLabel
                  key={typeElement.key}
                  value={typeElement.key}
                  control={<Radio />}
                  label={typeElement.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        ) : null}

        {type === articleTypesModel.external.key ? (
          <>
            <TextField
              label='Ссылка'
              variant='outlined'
              fullWidth
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <TextField
              label='Название источника'
              variant='outlined'
              fullWidth
              value={sourceName}
              onChange={(e) => setSourceName(e.target.value)}
              sx={{ mt: '32px' }}
            />
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                mb: '24px',
              }}
            >
              <input
                alt='Cover'
                id='contained-button-file'
                style={{ display: 'none' }}
                type='file'
                accept='image/*'
                // value={coverImg}
                onChange={handleUploadCover}
              />
              <Typography variant='h6'>Обложка статьи</Typography>
              {coverImg ? (
                <>
                  <img
                    alt='cover img'
                    src={coverImg?.path}
                    style={{ maxHeight: '100px', maxWidth: '100%' }}
                  />
                  <Button
                    variant='outlined'
                    component='span'
                    onClick={() => handleRemoveCover(coverImg)}
                  >
                    Удалить
                  </Button>
                </>
              ) : (
                <label htmlFor='contained-button-file'>
                  <Button
                    variant='outlined'
                    component='span'
                    disabled={imgLoading}
                  >
                    Загрузить
                  </Button>
                </label>
              )}
            </Box>

            {!showHtmlText ? (
              <TextEditor value={text} setValue={setText} />
            ) : (
              <TextField
                label='HTML'
                variant='outlined'
                fullWidth
                multiline={true}
                value={text}
                onChange={(e) => setText(e.target.value)}
                sx={{ mt: '32px' }}
              />
            )}
            <FormControlLabel
              control={<Checkbox />}
              label='Показ в формате HTML'
              sx={{ mb: '16px' }}
              value={showHtmlText}
              onChange={() => setShowHtmlText(!showHtmlText)}
            />

            <Grid container spacing={'16px'} mt='16px'>
              <Grid item xs={6}>
                <FormControl fullWidth size='small'>
                  <InputLabel id='author'>Автор</InputLabel>
                  <Select
                    labelId='author'
                    id='author-id'
                    value={author || ''}
                    label='Автор'
                    onChange={(event) => {
                      setAuthor(event.target.value);
                    }}
                  >
                    <MenuItem value={null}>Не выбрано</MenuItem>
                    {team.map((empoye) => (
                      <MenuItem value={empoye.id}>
                        {empoye.firstName +
                          ' ' +
                          empoye.middleName +
                          ' ' +
                          empoye.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} size='small' fullWidth>
                  <InputLabel id='demo-simple-select-label'>Теги</InputLabel>
                  <Select
                    labelId='demo-multiple-chip-label'
                    id='demo-multiple-chip'
                    multiple
                    value={pickedTags}
                    onChange={handleChange}
                    input={
                      <OutlinedInput id='select-multiple-chip' label='Chip' />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value.name}
                            sx={{
                              backgroundColor: value.color,
                              color: 'white',
                            }}
                            label={value.name}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {tags.map((tag) => (
                      <MenuItem
                        key={tag.name}
                        value={tag}
                        style={getStyles(tag, theme)}
                      >
                        {tag.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <FormControlLabel
              control={<Checkbox value={isMainPage} />}
              label='На главную'
              sx={{ mt: '16px' }}
              value={isMainPage}
              checked={isMainPage}
              onChange={() => setIsMainPage(!isMainPage)}
            />
            {isMainPage ? (
              <TextField
                label='Короткое описание'
                variant='outlined'
                fullWidth
                multiline={true}
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
                sx={{ mt: '32px' }}
              />
            ) : null}
          </>
        )}
        <Typography
          sx={{ color: '#ca0000', mb: '28px', mt: '8px' }}
          variant='subtitle1'
        >
          {validationErrors}
        </Typography>
        <Box
          mt='32px'
          pb='32px'
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button fullWidth variant='outlined' onClick={handleSubmit}>
            Сохранить
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
