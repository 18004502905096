import Breadcrumbs from 'elements/UI/Breadcrums';
import Container from 'elements/UI/Container';
import { mockEmployee } from 'mock';
import EmployeeContent from './components/EmployeeContent';
import EmployeeInfo from './components/EmployeeInfo';
import PageContainer from 'elements/UI/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getMember,
  getMemberEditInfo,
  memberRemove,
} from 'store/actions/teamActions';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { Box, Button, Drawer } from '@mui/material';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { clearAllMemberMaterials, clearMember } from 'store/slices/teamSlices';
import { AddTeamModal } from 'pages/Team/AddTeamModal';
import Loader from 'elements/UI/Loader';

const EmployeePage = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const {
    member,
    isLoadingMember,
    errorMember,
    isLoadingEdit,
    memberEditInfo,
  } = useSelector((state) => state.team);
  const navigate = useNavigate();
  let { id } = useParams();
  const { isAuth } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMember) {
      navigate('/404', { replace: true });
    }
  }, [errorMember]);

  useEffect(() => {
    dispatch(getMember({ id: +id }));
    return () => {
      dispatch(clearMember());
      dispatch(clearAllMemberMaterials());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getMemberEditInfo({ id: +id }));
    }
  }, [dispatch, id, isAuth]);

  const handleDelete = async () => {
    let form = { id: id };
    try {
      const { ok } = await dispatch(memberRemove(form));
      if (ok) {
        setIsDeleteModalOpen(false);
        navigate('/team');
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <PageContainer>
      <Container>
        {isLoadingMember ? (
          <Loader />
        ) : (
          <>
            <Breadcrumbs
              links={[
                { name: 'Главная', to: 'main' },
                { name: 'Команда', to: 'team' },
                {
                  name: [
                    member.firstName,
                    member.middleName,
                    member.lastName,
                  ].join(' '),
                },
              ]}
              mb={{ xs: '30px', sm: '94px' }}
            />
            {isAuth ? (
              <Box sx={{ mb: '30px' }}>
                <Button
                  variant='outlined'
                  onClick={() => setIsEditModalOpen(true)}
                  sx={{ mr: '12px' }}
                >
                  Редактировать
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  Удалить
                </Button>
                <Drawer
                  anchor='bottom'
                  open={isEditModalOpen}
                  onClose={() => setIsEditModalOpen(false)}
                >
                  <AddTeamModal
                    onClose={() => setIsEditModalOpen(false)}
                    member={memberEditInfo}
                    memberId={id}
                  />
                </Drawer>
                <ConfirmModal
                  isOpen={isDeleteModalOpen}
                  onSubmit={handleDelete}
                  onClose={() => setIsDeleteModalOpen(false)}
                  isLoading={isLoadingEdit}
                />
              </Box>
            ) : null}
            <EmployeeInfo employee={member} />
            <EmployeeContent memberId={+id} />
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default EmployeePage;
