import { styled } from '@mui/system';
import { ReactComponent as Arrow } from 'assets/elements/arrow.svg';

const ButtonContainer = styled('div')(
  ({ theme, disabled, invert, fullWidth, bgColor }) => ({
    border: `2px solid rgb(${!bgColor ? '255 255 255' : '218 225 237'} / ${
      disabled ? '30' : '100'
    }%)`,
    transform: 'translate(8px, 0)',
    marginRight: '8px',
    borderRadius: '8px',
    width: '100%',
    filter: `invert(${invert ? 100 : 0}%)`,
    [theme.breakpoints.up('sm')]: {
      width: fullWidth ? '100%' : 'fit-content',
    },
  })
);
const Button = styled('button')(
  ({
    theme,
    reverseArrow,
    trackState,
    isActive,
    withoutArrow = false,
    bgColor = null,
  }) => ({
    width: '100%',
    background: bgColor ?? 'white',
    color: 'black',
    borderRadius: '8px',
    padding: '16px 32px',
    [theme.breakpoints.up('sm')]: withoutArrow
      ? {}
      : {
          [!reverseArrow ? 'paddingRight' : 'paddingLeft']: '100px',
        },
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '29px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transform: 'translate(-8px, -8px)',
    transition: 'all .3s',
    '-webkit-tap-highlight-color': 'transparent',
    position: 'relative',
    '&:hover:not(:disabled)': {
      transform: 'translate(-4px, -4px)',
    },
    '&:active:not(:disabled)': {
      transform: 'translate(0, 0)',
    },
    '&:disabled': {
      opacity: '0.6',
    },
    '& svg': {
      transition: 'all 0.3s',
      position: 'absolute',
      [!reverseArrow ? 'right' : 'left']: '-30px',
      top: '50%',
      transform: `translateY(-50%) scale(${!reverseArrow ? 1 : -1}, ${
        trackState && isActive ? '-1' : '1'
      })`,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

const ArrowButton = ({
  text,
  bgColor = null,
  invert = false,
  trackState = false,
  reverseArrow = false,
  Element = Arrow,
  isActive,
  children,
  disabled = false,
  fullWidth = false,
  withoutArrow = false,
  ...other
}) => {
  return (
    <ButtonContainer
      disabled={disabled}
      invert={invert}
      fullWidth={fullWidth}
      bgColor={bgColor}
    >
      <Button
        {...other}
        disabled={disabled}
        reverseArrow={reverseArrow}
        trackState={trackState}
        isActive={isActive}
        withoutArrow={withoutArrow}
        bgColor={bgColor}
      >
        {children}
        {!withoutArrow ? <Element /> : null}
      </Button>
    </ButtonContainer>
  );
};

export default ArrowButton;
