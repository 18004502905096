import api from '../http/index';

const practiceAPIRoutes = {
  getPracticeInfo: (data) =>
    api.get('/Practice/GetPracticeInfo', { params: data }),
  getPracticeMembers: (data) =>
    api.get('/Practice/GetAllMembers', { params: data }),
  addMember: (data) =>
    api.post('/Practice/AddMemberCard', JSON.stringify(data)),
  removeMember: (data) =>
    api.post('/Practice/DeleteMemberCard', JSON.stringify(data)),
};

export default practiceAPIRoutes;
