import { Typography, Grid } from '@mui/material';
import CardNews from 'elements/UI/CardNews';
import { useSelector } from 'react-redux';

const LastNews = () => {
  const { lastNews } = useSelector((state) => state.article);

  return (
    <>
      {lastNews?.length > 0 ? (
        <>
          <Typography variant='h3' mt='120px' mb='64px'>
            Последние новости в блоге
          </Typography>
          <Grid container spacing='40px'>
            {lastNews.slice(0, 3).map((el, idx) => (
              <Grid item xs={4} key={idx}>
                <CardNews news={el} showDescription={false} />
              </Grid>
            ))}
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default LastNews;
