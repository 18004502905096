import { configureStore } from '@reduxjs/toolkit';
import uiReducer from './slices/uiSlices';
import loginReducer from './slices/loginSlices';
import tagReducer from './slices/tagSlices';
import teamReducer from './slices/teamSlices';
import emailReducer from './slices/emailSlices';
import articleReducer from './slices/articleSlices';
import practiceReducer from './slices/practiceSlices';

const roorReducer = {
  ui: uiReducer,
  login: loginReducer,
  tag: tagReducer,
  team: teamReducer,
  email: emailReducer,
  article: articleReducer,
  practice: practiceReducer,
};

export const store = configureStore({
  reducer: roorReducer,
});
