import api from '../http/index';

const teamAPIRoutes = {
  getTeamList: () => api.get('/MemberCard/Index'),
  getMember: (data) => api.get('/MemberCard/Info', { params: data }),
  getMemberEditInfo: (data) => api.get('/MemberCard/Edit', { params: data }),
  memberCreateOrEdit: (data) =>
    api.post('/MemberCard/CreateOrUpdate', JSON.stringify(data)),
  memberRemove: (data) =>
    api.post('/MemberCard/Delete', null, { params: data }),
  //
  getContent: (data) => api.get('/MemberCard/GetContents', { params: data }),
  getPublications: (data) =>
    api.get('/MemberCard/GetPublications', { params: data }),
  getInterestingCases: (data) =>
    api.get('/MemberCard/GetInterestingCases', { params: data }),
  getComments: (data) => api.get('/MemberCard/GetComments', { params: data }),
  getReview: (data) => api.get('/MemberCard/GetReview', { params: data }),
};

export default teamAPIRoutes;
