import { Box, Button, Drawer, Typography } from '@mui/material';
import Container from 'elements/UI/Container';
import PageContainer from 'elements/UI/PageContainer';
import FeaturedNews from 'elements/components/FeaturedNews';
import Masonry from '@mui/lab/Masonry';
import { blogMock } from './mock';
import CardNews from 'elements/UI/CardNews';
import ArrowButton from 'elements/UI/ArrowButton';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { AddArticleModal } from './AddArticleModal';
import { TagModal } from './TagsModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  articleRemove,
  getArticleList,
  getEventList,
} from 'store/actions/articleActions';
import { clearArticleList, clearEventList } from 'store/slices/articleSlices';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { useNavigate } from 'react-router-dom';
import Loader from 'elements/UI/Loader';

const BlogPage = ({}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDelItem, setCurrentDelItem] = useState(null);

  const { isAuth } = useAuth();

  const [isAddArticleOpen, setIsAddArticleOpen] = useState(false);
  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);

  const { articleList, articlePageMetaData, isLoadingArticleList, eventList } =
    useSelector((state) => state.article);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getArticleList());
    dispatch(getEventList());

    return () => {
      dispatch(clearArticleList());
      dispatch(clearEventList());
    };
  }, [dispatch]);

  const loadMore = () => {
    dispatch(getArticleList(articlePageMetaData?.pageNumber + 1));
  };

  const handleDelete = async () => {
    let form = { id: currentDelItem.id };
    try {
      const { ok } = await dispatch(articleRemove(form));
      if (ok) {
        setIsDeleteModalOpen(false);
        dispatch(clearArticleList());
        dispatch(getArticleList());
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <PageContainer>
      <Container>
        {isLoadingArticleList && articleList.length === 0 ? (
          <Loader />
        ) : (
          <>
            {isAuth ? (
              <>
                <Box mb='16px'>
                  <Button
                    variant='outlined'
                    sx={{ mr: '8px' }}
                    onClick={() => setIsAddArticleOpen(true)}
                  >
                    Добавить статью
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={() => setIsTagsModalOpen(true)}
                  >
                    Теги
                  </Button>
                </Box>

                <Drawer
                  anchor='bottom'
                  open={isAddArticleOpen}
                  onClose={() => setIsAddArticleOpen(false)}
                >
                  <AddArticleModal onClose={() => setIsAddArticleOpen(false)} />
                </Drawer>
                <Drawer
                  anchor='bottom'
                  open={isTagsModalOpen}
                  onClose={() => setIsTagsModalOpen(false)}
                >
                  <TagModal onClose={() => setIsTagsModalOpen(false)} />
                </Drawer>
              </>
            ) : null}

            <Typography variant='h2' mb='64px'>
              Главное на сегодня
            </Typography>
            <FeaturedNews />
            <Box>
              <Masonry
                columns={{ xs: 1, sm: 2, md: 3 }}
                spacing={'24px'}
                sx={{
                  mt: '24px',
                  '& > div': {
                    width: {
                      xs: '100%',
                      sm: 'calc(50% - 24px * 1 / 2)',
                      md: 'calc(33.33% - 24px * 2 / 3)',
                    },
                  },
                }}
              >
                {eventList.length > 0 ? (
                  <Box>
                    <Typography variant='h3' fontWeight={300}>
                      Наши мероприятия
                    </Typography>
                    {eventList.slice(0, 3).map((el, idx) => (
                      <Box key={idx} sx={{ mt: '24px' }}>
                        <CardNews
                          news={el}
                          showDescription={false}
                          hideImg={true}
                        />
                      </Box>
                    ))}
                    <Box sx={{ mt: '36px', mb: '24px' }}>
                      <ArrowButton
                        bgColor={'#DAE1ED'}
                        onClick={() => navigate('/events')}
                      >
                        Смотреть все
                      </ArrowButton>
                    </Box>
                  </Box>
                ) : null}
                {articleList?.map((el, idx) => (
                  <CardNews
                    news={el}
                    key={idx}
                    showDescription={false}
                    dltBtn={el.link && isAuth ? true : false}
                    onDelete={() => {
                      setCurrentDelItem(el);
                      setIsDeleteModalOpen(true);
                    }}
                  />
                ))}
              </Masonry>
            </Box>
            {articlePageMetaData?.hasNextPage ? (
              <Box sx={{ mt: '12px' }}>
                <ArrowButton
                  fullWidth={true}
                  withoutArrow={true}
                  onClick={loadMore}
                  disabled={isLoadingArticleList}
                >
                  <Typography variant='h6Normal'>
                    Ещё <span>↓</span>
                  </Typography>
                </ArrowButton>
              </Box>
            ) : null}
          </>
        )}
      </Container>
      {isAuth ? (
        <ConfirmModal
          isOpen={isDeleteModalOpen}
          onSubmit={handleDelete}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      ) : null}
    </PageContainer>
  );
};

export default BlogPage;
