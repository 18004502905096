import { Box, styled } from '@mui/system';

const Paper = styled(Box)(({ theme, bgColor }) => ({
  backgroundColor: bgColor ? bgColor : 'white',
  boxShadow:
    '0px 31px 13px rgba(16, 65, 115, 0.02), 0px 18px 11px rgba(16, 65, 115, 0.06), 0px 8px 8px rgba(16, 65, 115, 0.1), 0px 2px 4px rgba(16, 65, 115, 0.11), 0px 0px 0px rgba(16, 65, 115, 0.11)',
  borderRadius: '36px',
  overflow: 'hidden',
}));

const PaperBlank = ({ children, ...other }) => {
  return <Paper {...other}>{children}</Paper>;
};

export default PaperBlank;
