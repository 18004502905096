import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import ArrowButton from 'elements/UI/ArrowButton';
import Tag from 'elements/UI/Tag';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getLastNews } from 'store/actions/articleActions';
import { formattUtcTime } from 'utils/formattUtcTime';

const BlogContainer = styled(Box)(({ theme }) => ({
  border: '2px solid #FFFFFF',
  borderRadius: '36px',
  padding: '40px 40px 24px 40px',
  position: 'relative',
  '& .practicesLabel': {
    color: 'black',
    background: theme.palette.whiteBg.main,
    textShadow: 'none',
    position: 'absolute',
    top: '-18px',
    left: '40px',
  },
}));

const FooterBlog = () => {
  const { lastNews } = useSelector((state) => state.article);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClickBlog = () => {
    setTimeout(() => {
      navigate('/blog');
    }, 300);
  };

  useEffect(() => {
    dispatch(getLastNews());
  }, [dispatch]);

  return (
    <BlogContainer>
      <Typography variant='h5' className='practicesLabel'>
        Блог
      </Typography>
      {lastNews?.slice(0, 3).map((el, idx) => (
        <Box mb='24px' key={idx}>
          <Box
            sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap', mb: '8px' }}
          >
            <Tag>{formattUtcTime(el?.createdDateTimeUtc)}</Tag>
            {el.tagDtos?.[0] ? (
              <Tag color={el.tagDtos?.[0]?.color}>{el.tagDtos?.[0]?.name}</Tag>
            ) : null}
          </Box>
          <Typography
            variant='body2'
            component={Link}
            to={`article/${el.id}`}
            sx={{ textDecoration: 'none', color: 'black' }}
          >
            {el.title}
          </Typography>
        </Box>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <ArrowButton onClick={handleClickBlog}>
          <Typography variant='body2'>Перейти в блог</Typography>
        </ArrowButton>
      </Box>
    </BlogContainer>
  );
};

export default FooterBlog;
