import api from '../http/index';
var qs = require('qs');

const articleAPIRoutes = {
  getMainArticleList: () => api.get('/Article/GetMainPageArticles'),
  getArticleList: (data) =>
    api.get('/Article/Index', {
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }),
  getArticle: (data) => api.get('/Article/GetInfo', { params: data }),
  getArticleEditInfo: (data) =>
    api.get('/Article/CreateOrUpdate', { params: data }),
  articleCreateOrEdit: (data) =>
    api.post('/Article/CreateOrUpdate', JSON.stringify(data)),
  articleRemove: (data) => api.post('/Article/Remove', null, { params: data }),

  getArticleComments: (data) =>
    api.get('/Article/GetComments', { params: data }),
  articleCommentCreate: (data) =>
    api.post('/Article/AddComment', JSON.stringify(data)),
  articleCommentRemove: (data) =>
    api.post('/Article/RemoveComment', null, { params: data }),
};

export default articleAPIRoutes;
