import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const TaxDdisputesArticle = () => {
  return (
    <Box
      sx={{
        '& ul > li:not(:last-of-type)': {
          marginBottom: '0.8em',
        },
      }}
    >
      <Typography textAlign='justify'>
        Объемность и многоаспектность налогового законодательства требует от
        бизнеса проведения оценки налоговых рисков в текущих бизнес-процессах и
        совершаемых сделках, с учетом позиций, применяемых фискальными органами.
        Претензии налоговых органов, в том числе по признакам дробления бизнеса,
        взаимодействию налогоплательщика с «техническими» компаниями, об
        использовании «серых схем» при выплате заработной платы, по
        переквалификации совершенных налогоплательщиком сделок и др., необходимо
        проверять на предмет их обоснованности и законности, как на досудебных
        стадиях, так и в суде.
        <br />
        <br />
        Налоговая практика является одним из ключевых направлений деятельности
        адвокатского бюро PRIM group. Мы предлагаем комплексный подход к оценке
        ситуаций, возникающей в деятельности налогоплательщика привлекаем при
        необходимости специалистов для решения поставленных задач, отстаиваем
        налогоплательщиков на всех стадиях в государственных и судебных органах,
        защищаем доверителей в уголовных делах по налоговым правонарушениям.
      </Typography>
      <br />
      <Typography variant='h7' component='p' fontWeight='bold'>
        Налоговый консалтинг
      </Typography>
      <Typography>
        <ul>
          <li>
            Налоговое консультирование в рамках текущей деятельности фирмы и
            групп компаний
          </li>
          <li>
            Выявление и анализ налоговых рисков по бизнес-процессам и сделкам
          </li>
          <li>Взаимодействие с налоговыми органами</li>
          <li>Налоговый аудит</li>
        </ul>
      </Typography>
      <br />
      <Typography variant='h7' component='p' fontWeight='bold'>
        Налоговые споры
      </Typography>
      <Typography>
        <ul>
          <li>Сопровождение выездных и камеральных проверок;</li>
          <li>
            Обжалование решений налоговых органов: досудебная и судебная стадии;
          </li>
          <li>
            Защита доверителя по уголовному делу по обстоятельствам, связанным с
            налоговыми правонарушениями
          </li>
        </ul>
      </Typography>
      <br />
      <Typography>АБ PRIM group</Typography>
    </Box>
  );
};

export default TaxDdisputesArticle;
