import { Typography, Grid, useMediaQuery } from '@mui/material';
import adressElement from 'assets/elements/contact-we-here.svg';
import timeElement from 'assets/elements/contact-work-time.svg';
import phoneElement from 'assets/elements/contact-phone-number.svg';
import emailElement from 'assets/elements/contact-email.svg';
import { useTheme } from '@emotion/react';

const ContackTsk = () => {
  const theme = useTheme();
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const greaterThanMmd = useMediaQuery(theme.breakpoints.up('mmd'));
  return (
    <>
      <Grid container spacing='15px'>
        <Grid item xs={12} sm={7}>
          <Typography
            variant='h6Normal'
            sx={{
              display: 'inline-block',
              position: 'relative',
              '&::after': {
                content: "''",
                position: 'absolute',
                width: greaterThanSm ? '190px' : '170px',
                height: '85px',
                backgroundImage: `url(${adressElement})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',

                top: '-58px',
                left: '95px',

                opacity: greaterThanMmd ? 0 : 1,
                transition: 'all .5s',
              },

              '&:hover:after': {
                opacity: 1,
              },
            }}
          >
            634041,
            <br /> ул. Белинского, д. 29, оф. 16
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} sx={{ display: 'flex', alignItems: 'end' }}>
          <Typography
            variant='h6Normal'
            sx={{
              display: 'inline-block',
              position: 'relative',
              '&::after': {
                content: "''",
                position: 'absolute',
                width: greaterThanSm ? '217px' : '190px',
                height: '69px',
                backgroundImage: `url(${timeElement})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',

                bottom: '-70px',
                left: greaterThanSm ? '20px' : '120px',

                opacity: greaterThanMmd ? 0 : 1,
                transition: 'all .5s',
              },

              '&:hover:after': {
                opacity: 1,
              },
            }}
          >
            пн-вс: 9:00-18:00
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={{ xs: '64px' }} spacing='15px'>
        <Grid item xs={12} sm={7}>
          <Typography
            variant='h6Normal'
            sx={{
              display: 'inline-block',
              position: 'relative',
              '&::after': {
                content: "''",
                position: 'absolute',
                width: greaterThanSm ? '185px' : '160px',
                height: '120px',
                backgroundImage: `url(${phoneElement})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',

                bottom: greaterThanSm ? '-110px' : '-50px',
                left: greaterThanSm ? '20px' : '185px',

                opacity: greaterThanMmd ? 0 : 1,
                transition: 'all .5s',
              },

              '&:hover:after': {
                opacity: 1,
              },
            }}
          >
            +7 (3822) 56-41-84
            <br />
            +7 (3822)56-37-11
            <br />
            +7 (3822)56-36-80
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography
            variant='h6Normal'
            sx={{
              display: 'inline-block',
              position: 'relative',
              '&::after': {
                content: "''",
                position: 'absolute',
                width: greaterThanSm ? '245px' : '215px',
                height: '150px',
                backgroundImage: `url(${emailElement})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',

                bottom: '-120px',
                left: '10px',

                opacity: greaterThanMmd ? 0 : 1,
                transition: 'all .5s',
              },

              '&:hover:after': {
                opacity: 1,
              },
            }}
          >
            tomsk@prim.group
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ContackTsk;
