import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use((config) => {
  if (localStorage.getItem('token')?.length) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (config.method !== 'post' || config.url !== '/File/Upload') {
    config.headers['Content-Type'] = 'application/json';
  } else {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

// axios.interceptors.response.use(
//   (response) => {
//     // Обработка успешного ответа
//     return response;
//   },
//   (error) => {
//     console.log(error.response, 'qq');
//     if (error.response.status === 400) {
//       return Promise.reject(error);
//     }
//     return Promise.reject(error);
//   }
// );

export default api;
