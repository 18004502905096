import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  practiceInfo: {},
  isLoadingPracticeInfo: false,
  practiceInfoError: '',

  allMembers: [],
  isLoadingAllMembers: false,
  errorAllMembers: '',

  isLoadingAddingMember: false,
  createError: '',
};

export const practiceSlice = createSlice({
  name: 'practice',
  initialState,
  reducers: {
    getPracticeInfoFetching: (state) => {
      state.isLoadingPracticeInfo = true;
    },
    getPracticeInfoFetchingSuccess: (state, action) => {
      state.isLoadingPracticeInfo = false;
      state.practiceInfoError = '';
      state.practiceInfo = action.payload;
    },
    getPracticeInfoFetchingError: (state, action) => {
      state.isLoadingPracticeInfo = false;
      state.practiceInfoError = action.payload;
      state.practiceInfo = {};
    },

    getAllMembersFetching: (state) => {
      state.isLoadingAllMembers = true;
    },
    getAllMembersFetchingSuccess: (state, action) => {
      state.isLoadingAllMembers = false;
      state.errorAllMembers = '';
      state.allMembers = action.payload;
    },
    getAllMembersFetchingError: (state, action) => {
      state.isLoadingAllMembers = false;
      state.errorAllMembers = action.payload;
      state.allMembers = [];
    },

    addingMemberFetching: (state) => {
      state.isLoadingAddingMember = true;
    },
    addingMemberFetchingSuccess: (state, action) => {
      state.isLoadingAddingMember = false;
      state.createError = '';
    },
    addingMemberFetchingError: (state, action) => {
      state.isLoadingAddingMember = false;
      state.createError = action.payload;
    },

    clearPracticeInfo: (state) => {
      state.practiceInfo = {};
      state.isLoadingPracticeInfo = false;
      state.practiceInfoError = '';

      state.allMembers = [];
      state.isLoadingAllMembers = false;
      state.errorAllMembers = '';

      state.isLoadingAddingMember = false;
      state.createError = '';
    },
  },
});

export const {
  getPracticeInfoFetching,
  getPracticeInfoFetchingSuccess,
  getPracticeInfoFetchingError,
  getAllMembersFetching,
  getAllMembersFetchingSuccess,
  getAllMembersFetchingError,
  addingMemberFetching,
  addingMemberFetchingSuccess,
  addingMemberFetchingError,
  clearPracticeInfo,
} = practiceSlice.actions;

export default practiceSlice.reducer;
