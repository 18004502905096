import { useTheme } from '@emotion/react';
import { Typography, useMediaQuery } from '@mui/material';
import CardNews from 'elements/UI/CardNews';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const ArticlesTab = ({ content }) => {
  const theme = useTheme();
  const greaterThanXl = useMediaQuery(theme.breakpoints.up('xl'));
  const greaterThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const greaterThanMmd = useMediaQuery(theme.breakpoints.up('mmd'));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const greaterThanSsm = useMediaQuery(theme.breakpoints.up('ssm'));

  return (
    <>
      {content.length > 0 ? (
        <Swiper
          className='mySwiper swiperOverflow'
          slidesPerView={greaterThanMd ? 3 : greaterThanSm ? 2 : 1}
          spaceBetween={40}
          style={{ cursor: 'grab' }}
          modules={[Autoplay]}
          speed={3000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: true,
          }}
        >
          {content.map((el) => (
            <SwiperSlide key={el.id}>
              <CardNews news={el} showDescription={false} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Typography textAlign='center' variant='h6'>
          Здесь пока ничего нет...
        </Typography>
      )}
    </>
  );
};

export default ArticlesTab;
