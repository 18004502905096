import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import filesAPIRoutes from 'apiRoutes/files';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import Container from 'elements/UI/Container';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createMemberContent } from 'store/actions/teamActions';
import { bool, number, object, string } from 'yup';
import { typesModel } from '../models';

const tabsModel = {
  materials: {
    key: 0,
    name: 'Материалы',
  },
  publications: {
    key: 1,
    name: 'Публикации',
  },
};

const re =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

const schema = object().shape({
  title: string().required('Название обязательно'),
  type: number().required(),
  ifFile: bool(),
  link: string().when('ifFile', {
    is: false,
    then: (shemaInner) =>
      shemaInner.required('Ссылка обязательна').matches(re, 'Неверный формат'),
    otherwise: (shemaInner) => shemaInner.notRequired(),
  }),
  file: object().when('ifFile', {
    is: true,
    then: (shemaInner) => shemaInner.required('Файл обязателен'),
    otherwise: (shemaInner) => shemaInner.notRequired(),
  }),
});

export const AddContentModal = ({ memberId, onClose }) => {
  const [title, setTitle] = useState('');
  const [type, setType] = useState(typesModel.externalVideo.key);
  const [link, setLink] = useState('');
  const [file, setFile] = useState(null);
  const [tab, setTab] = useState(tabsModel.materials.key);

  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [validationErrors, setValidationErrors] = useState('');

  const dispatch = useDispatch();

  const handleClose = () => {
    setTitle('');
    setType(typesModel.externalVideo.key);
    setLink('');
    setFile(null);
    setIsLoadingFile(false);
    setTab(tabsModel.materials.key);
    setValidationErrors('');
    onClose();
  };

  const handleSubmit = async () => {
    let form = {
      memberId: memberId,
      title: title,
      isPublication: tab === tabsModel.publications.key,
      type: type,
      ifFile: type === typesModel.file.key,
    };
    if (type !== typesModel.file.key) {
      form = { ...form, link: link };
    } else {
      form = { ...form, file: file };
    }

    try {
      setValidationErrors('');
      await schema.validate(form);
      delete form.ifFile;
      const { ok } = await dispatch(createMemberContent(form));
      if (ok) {
        handleClose();
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrors(error.errors[0]);
      }
      throw error;
    }
  };

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('uploadFile', file);

    try {
      setIsLoadingFile(true);
      const response = await filesAPIRoutes.upload(formData);
      setIsLoadingFile(false);
      let imageUrl = response?.data?.entity;
      if (imageUrl) {
        setFile(imageUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveFile = async (img) => {
    try {
      let form = {
        id: img.id,
        secretKey: img.secretKey,
      };
      const response = await filesAPIRoutes.remove(form);

      setFile(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Container>
        <Grid
          mt={2}
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography variant='h3'>Контент сотрудника</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon stroke='black' />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing='24px' mt='24px'>
          <Grid item xs={12}>
            <TextField
              label='Название'
              variant='outlined'
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id='demo-radio-buttons-group-label'>Тип</FormLabel>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='female'
                name='radio-buttons-group'
                value={type}
                onChange={(e) => setType(+e.target.value)}
              >
                {Object.values(typesModel).map((typeElement) => (
                  <FormControlLabel
                    key={typeElement.key}
                    value={typeElement.key}
                    control={<Radio />}
                    label={typeElement.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {type === typesModel.file.key ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                  mb: '24px',
                  mt: '24px',
                }}
              >
                <input
                  alt='Cover'
                  id='contained-button-file'
                  style={{ display: 'none' }}
                  type='file'
                  //   accept='image/*'
                  onChange={handleUploadFile}
                />
                <Typography variant='h6'>Файл</Typography>
                {file ? (
                  <>
                    {/* <img
                      alt='cover img'
                      src={file?.path}
                      style={{ maxHeight: '100px', maxWidth: '100%' }}
                    /> */}
                    <Typography>{file?.path}</Typography>
                    <Button
                      variant='outlined'
                      component='span'
                      onClick={() => handleRemoveFile(file)}
                    >
                      Удалить
                    </Button>
                  </>
                ) : (
                  <label htmlFor='contained-button-file'>
                    <Button
                      variant='outlined'
                      component='span'
                      disabled={isLoadingFile}
                    >
                      Загрузить
                    </Button>
                  </label>
                )}
              </Box>
            ) : (
              <TextField
                label='Ссылка'
                variant='outlined'
                fullWidth
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id='demo-radio-buttons-group-label'>Вкладка</FormLabel>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='female'
                name='radio-buttons-group'
                value={tab}
                onChange={(e) => setTab(+e.target.value)}
              >
                {Object.values(tabsModel).map((typeElement) => (
                  <FormControlLabel
                    key={typeElement.key}
                    value={typeElement.key}
                    control={<Radio />}
                    label={typeElement.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Typography
          sx={{ color: '#ca0000', mb: '28px', mt: '8px' }}
          variant='subtitle1'
        >
          {validationErrors}
        </Typography>
        <Box
          mt='32px'
          pb='32px'
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={handleSubmit}
        >
          <Button fullWidth variant='outlined'>
            Сохранить
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
