import { Box, Button, Drawer, Grid, Typography } from '@mui/material';
import Container from 'elements/UI/Container';
import parse from 'html-react-parser';
import mockImg from 'assets/mock/image 3.png';

import 'react-quill/dist/quill.snow.css';
import PageContainer from 'elements/UI/PageContainer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNavbarEvent } from 'store/slices/uiSlices';
import styled from '@emotion/styled';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Navbar from 'elements/components/Navbar';
import CommentSection from './components/CommentSection';
import {
  articleRemove,
  getArticle,
  getArticleEditInfo,
} from 'store/actions/articleActions';
import { clearArticle } from 'store/slices/articleSlices';
import { useAuth } from 'hooks/useAuth';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { AddArticleModal } from 'pages/Blog/AddArticleModal';
import Loader from 'elements/UI/Loader';

const NavContainer = styled(Box)(({ theme }) => ({
  flexGrow: '1',
  marginTop: '24px',
  position: 'relative',
  zIndex: 0,
  '& .navbar': {
    position: 'sticky',
    top: '150px',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const regex = /<p>\[\[(.+?)\]\]<\/p>/g;

const ArticlePage = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [articleFormat, setArticleFormat] = useState('');
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const {
    article,
    errorArticle,
    articleEditInfo,
    isLoadingEdit,
    isLoadingArticle,
  } = useSelector((state) => state.article);
  const navigate = useNavigate();
  let { id } = useParams();
  const { isAuth } = useAuth();

  useEffect(() => {
    if (errorArticle) {
      navigate('/404', { replace: true });
    }
  }, [errorArticle]);

  useEffect(() => {
    dispatch(getArticle({ id: +id }));
    if (isAuth) {
      dispatch(getArticleEditInfo({ id: +id }));
    }
    return () => {
      dispatch(clearArticle());
    };
  }, [dispatch, id, isAuth]);

  useEffect(() => {
    let oldText = article?.text ?? '';
    let newText = oldText.replace(regex, (match, name) => {
      return `<div id='${name}-anchor' data-name='${name}' class='anchor'></div>`;
    });
    setArticleFormat(newText);
  }, [article]);

  useEffect(() => {
    dispatch(updateNavbarEvent());
  }, [dispatch, articleFormat]);

  const handleDelete = async () => {
    let form = { id: id };
    try {
      const { ok } = await dispatch(articleRemove(form));
      if (ok) {
        setIsDeleteModalOpen(false);
        navigate('/blog');
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <PageContainer>
      <Container>
        {isLoadingArticle ? (
          <Loader />
        ) : (
          <>
            {isAuth ? (
              <>
                <Box mb='16px'>
                  <Button
                    variant='outlined'
                    sx={{ mr: '8px' }}
                    onClick={() => setIsEditModalOpen(true)}
                  >
                    Редактировать статью
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    Удалить
                  </Button>
                </Box>

                <Drawer
                  anchor='bottom'
                  open={isEditModalOpen}
                  onClose={() => setIsEditModalOpen(false)}
                >
                  <AddArticleModal
                    onClose={() => setIsEditModalOpen(false)}
                    article={articleEditInfo}
                    articleId={id}
                  />
                </Drawer>
                <ConfirmModal
                  isOpen={isDeleteModalOpen}
                  onSubmit={handleDelete}
                  onClose={() => setIsDeleteModalOpen(false)}
                  isLoading={isLoadingEdit}
                />
              </>
            ) : null}
            <Grid container spacing='24px'>
              <Grid
                item
                xs={12}
                lg={3}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <Typography
                  variant='body2'
                  mb={1}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: 'black',
                    width: 'fit-content',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  component={Link}
                  to={'/blog'}
                >
                  ← Назад в блог
                </Typography>
                <Typography variant='body2'>
                  13.02.2023{article?.tagDtos?.map((el) => ' | ' + el.name)}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ mt: '12px', textDecoration: 'underline' }}
                >
                  {article?.title}
                </Typography>
                <NavContainer>
                  <Navbar pathname={pathname} />
                </NavContainer>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container>
                  {article?.coverPath ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        maxWidth: '100%',
                      }}
                    >
                      <img
                        src={article?.coverPath}
                        alt='обложка'
                        style={{ maxWidth: '100%' }}
                      ></img>
                    </Box>
                  ) : null}

                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      '& *': { lineHeight: '1.2em' },
                      '* img': {
                        maxWidth: '100%',
                      },
                    }}
                  >
                    {parse(articleFormat)}
                    <Box mt='48px'>
                      {article?.memberCard ? (
                        <>
                          <Typography variant='body1'>Автор:</Typography>
                          <Typography
                            variant='body1'
                            component={Link}
                            sx={{
                              textDecoration: 'none',
                              color: 'black',
                              width: 'fit-content',
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                            }}
                            to={`/team/${article?.memberCard?.id}`}
                          >
                            {article?.memberCard?.firstName}{' '}
                            {article?.memberCard?.middleName}{' '}
                            {article?.memberCard?.lastName}
                          </Typography>
                          <Typography variant='body2' mt='8px' mb='16px'>
                            {article?.memberCard?.position}{' '}
                          </Typography>

                          <Typography
                            variant='h7'
                            sx={{
                              borderBottom: '1px solid black',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              navigate('/team/' + article?.memberCard?.id)
                            }
                          >
                            Подробнее
                          </Typography>
                        </>
                      ) : null}
                      <CommentSection articleId={id} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default ArticlePage;
