import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { memo } from 'react';

const Block = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  boxSizing: 'border-box',
  paddingRight: 16,
  paddingLeft: 16,
  [theme.breakpoints.up('sm')]: {
    paddingRight: 32,
    paddingLeft: 32,
  },
  [theme.breakpoints.up('mmd')]: {
    paddingRight: 120,
    paddingLeft: 120,
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 1800,
    paddingRight: 200,
    paddingLeft: 200,
  },
}));

const Container = memo(({ children, ...other }) => {
  return <Block {...other}>{children}</Block>;
});

export default Container;
