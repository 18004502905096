import api from '../http/index';

const filesAPIRoutes = {
  upload: (formData) =>
    api.post('/File/Upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  remove: (form) => api.post('/File/Remove', JSON.stringify(form)),
};

export default filesAPIRoutes;
