import loginAPIRoutes from 'apiRoutes/login';
import {
  loginFetching,
  loginFetchingError,
  loginFetchingSuccess,
} from 'store/slices/loginSlices';
import getResponse from 'utils/getResponse';

export const login = (loginData) => async (dispatch) => {
  try {
    dispatch(loginFetching());

    const response = await loginAPIRoutes.login(loginData);
    const { ok, data, errors, isSuccess } = getResponse(response);

    if (ok && isSuccess) {
      localStorage.setItem('token', data.token);
      // localStorage.setItem('refreshToken', data.refreshToken);

      //   await dispatch(getMe(data.id));
      dispatch(loginFetchingSuccess(data));
    } else {
      dispatch(loginFetchingError(errors[0].value));
    }
    return ok && isSuccess;
  } catch (e) {
    dispatch(loginFetchingError(e.message));
  }
};
