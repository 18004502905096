import { styled } from '@mui/system';

const Tag = ({
  children,
  color = '#757389',
  textColor = 'white',
  ...other
}) => {
  const StyledTag = styled('span')(({ theme }) => ({
    display: 'inline-flex',
    borderRadius: '12.5px',
    backgroundColor: color,
    color: textColor,
    padding: '4px 12px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    height: 'fit-content',

    gap: '10px',
    alignItems: 'baseline',
  }));
  return (
    <StyledTag className='tag' {...other}>
      <span
        style={{
          fontWeight: '600',
          background: 'inherit',
          '-webkit-background-clip': 'text',
          'background-clip': 'text',
          color: 'transparent',
          filter: 'sepia(5) saturate(100) invert(1) grayscale(1) contrast(9)',
        }}
      >
        {children}
      </span>
    </StyledTag>
  );
};

export default Tag;
