import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import CollapsedTextBlock from '../components/collapsedTextBlock';
import Anchor from 'elements/UI/Anchor';
import { useEffect } from 'react';
// import { Anchor } from '@mui/icons-material';

const AdministrativePressure = ({ toPathAnchor = null }) => {
  useEffect(() => {
    if (toPathAnchor) {
      document.getElementById(toPathAnchor)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [toPathAnchor]);
  return (
    <Box
      sx={{
        '& ul > li:not(:last-of-type)': {
          marginBottom: '0.8em',
        },
      }}
    >
      <Anchor name='aдминистративное давление' linkId={'first'} />
      <Typography
        variant='h3'
        fontWeight='500'
        mb={{ xs: '32px', sm: '40px', md: '24px' }}
      >
        Административное давление и регуляторные вопросы
      </Typography>
      <CollapsedTextBlock>
        <Typography textAlign='justify'>
          Административное давление на бизнес является тормозом его развития.
          Государственные органы наделены множеством контрольно-надзорных
          полномочий, и до настоящего времени никакая «регуляторная гильотина»
          не помогает справиться с избыточностью различных проверок, требований,
          запретов и ограничений, налагаемых на субъектов предпринимательской
          деятельности. Актуальным является вопрос использования
          административного ресурса в частных интересах, в «корпоративных
          войнах».
          <br />
          <br />
          Выживать на конкурентном рынке, в деятельность которого активно
          вмешиваются государственные органы, подвергая при этом ревизии
          хозяйственные операции, с учетом действующего массива нормативных
          актов, зачастую противоречивых и непонятных, непростая задача.
          <br />
          <br />
          Если вы хотите укрепить свои позиции в ситуациях проверок, запретов и
          требований, в случаях привлечения к административной ответственности,
          наша команда адвокатов готова оказать юридическую помощь.
          <br />
          <br />
          Представление интересов клиентов во взаимоотношениях антимонопольными
          и иными государственными и муниципальными органами, консультирование
          по вопросам участия в госзакупках, таможенного права, применения
          законодательства о рекламе и персональных данных, защиты при
          привлечении к административной ответственности, использования
          механизмов защиты от недобросовестной конкуренции – осуществляется
          высококвалифицированной командой наших адвокатов.
          <br />
          <br />
          Отдельной задачей адвокатов нашего Бюро является участие в разработке
          образовательных программ в сфере регулирования цифровой экономики, IT,
          автоматизации и цифровых рынков, вопросов искусственного интеллекта и
          блокчейн.
          <br />
          <br />
          Наша команда имеет опыт защиты прав и законных интересов бизнеса при
          конфликтах с таможенными органами, включая успешную защиту по
          таможенным правонарушениям в арбитражных, административных и уголовных
          процессах; при проверках Ростехнадзора, претензиях и спорах с
          Росприроднадзором, Росздравнадзором, требованиях и предписаниях
          Росалкогольрегулирования, спорах с Росимуществом.
          <br />
          <br />
          Одним из драйверов влияния на бизнес являются органы прокуратуры. Для
          успешного функционирования бизнесу приходится адаптироваться к реалиям
          всеохватного прокурорского надзора, учитывая риск последующих
          переходов конфликтов в область уголовно-процессуального
          законодательства либо судебных исков. Адвокаты Бюро помогут обеспечить
          как конструктивное взаимодействие с органами прокуратуры, так и защиту
          бизнеса от необоснованных предписаний или протестов прокуроров.
          <br />
          <br />
          Нормативные и ненормативные акты, принимаемые региональными органами
          власти и органами местного самоуправления, порой имеют существенное
          негативное влияние на хозяйственную деятельность. Адвокаты Бюро имеют
          опыт эффективного оспаривания муниципальных правовых актов,
          обжалования незаконных отказов в выдаче разрешительной документации на
          строительство, иных незаконных решений органов власти.
          <br />
        </Typography>
      </CollapsedTextBlock>
      <br />
      <br />
      <Anchor name='государственные закупки' linkId={'second'} />
      <Typography
        variant='h3'
        fontWeight='500'
        mb={{ xs: '32px', sm: '40px', md: '24px' }}
      >
        Государственные закупки
      </Typography>
      <CollapsedTextBlock>
        <Typography textAlign='justify'>
          B2G на сегодняшний день представляет собой огромную часть сегмента
          деятельности многих предприятий. Сотрудничество с государством всегда
          видится бизнесу очень выгодным. Это понятно, потому как государство —
          один из самых щедрых заказчиков. Помимо этого, существует большое
          количество предпринимателей, которые через сотрудничество с
          государством желают взять на себя социально полезную функцию,
          связанную с ежедневной жизнью страны.
          <br />
          <br />
          Причин для работы с государством великое множество. Однако, такое
          взаимодействие имеет множество юридических нюансов: государственный
          механизм огромен, и зачастую не так поворотлив, как того хотелось бы.
          К тому же, порядок взаимодействия с государством строго
          регламентирован. Чтобы стать участником взаимодействия с бюджетными
          учреждениями, нужно обратить пристальное внимание на 2 основных
          нормативных акта:
          <ul>
            <li>
              Федеральный закон "О закупках товаров, работ, услуг отдельными
              видами юридических лиц" от 18.07.2011 N 223-ФЗ, который касается
              закупок товаров и услуг для предприятий с долей участия
              государства не менее 1/2;
            </li>
            <li>
              Федеральный закон "О контрактной системе в сфере закупок товаров,
              работ, услуг для обеспечения государственных и муниципальных нужд"
              от 05.04.2013 N 44-ФЗ, который касается правил закупки всех
              бюджетных учреждений.
            </li>
          </ul>
          <br />
          <br />
          Важно чётко соблюдать требования указанных федеральных законов, потому
          что, как показывает практика, нередки случаи возникновения серьёзных
          проблем при участии в процедурах заключения договоров, вплоть до
          возбуждения уголовных дел в отношении не соблюдавших установленные
          процедуры предпринимателей. Именно поэтому к сотрудничеству с
          государством просто необходимо подходить с должной, в том числе и
          правовой, подготовкой, которая обезопасит от непредвиденных и очень
          больших неприятностей. С этой задачей помогут справиться адвокаты АБ
          «PRIM Group», которые имеют большой опыт в сопровождении сделок в
          рамках государственных закупок и способны эффективно защищать интересы
          бизнеса в случаях возникновения различных споров.
          <br />
          <br />
          Мы оказываем следующие услуги:
          <ul>
            <li>
              Консультирование по вопросам заключения гос.контрактов в рамках
              223-ФЗ и 44-ФЗ;
            </li>
            <li>
              Сопровождение процедуры заключения государственного контракта с
              бюджетными учреждениями;
            </li>
            <li>
              Подготовка необходимой юридической отчётности в ходе исполнения
              контракта и по итогам его реализации;
            </li>
            <li>
              Защита интересов бизнеса в государственных органах и судах от
              неправомерных действий контрагента;
            </li>
            <li>
              Защита предприятий от неправомерного административного давления,
              связанного с реализацией государственных закупок;
            </li>
            <li>Иные услуги.</li>
          </ul>
        </Typography>
      </CollapsedTextBlock>
      <br />
      <br />
      <Anchor name='экологическое право' linkId={'third'} />
      <Typography
        variant='h3'
        fontWeight='500'
        mb={{ xs: '32px', sm: '40px', md: '24px' }}
      >
        Экологическое право
      </Typography>
      <CollapsedTextBlock>
        <Typography textAlign='justify'>
          Тенденции внедрения ESG в практику управления бизнесом актуальны не
          только для западных компаний. Социально ответственный бизнес в России
          активно внедряет их, чтобы сформировать в своей структуре новые
          ценности, и в конечном счёте стать более привлекательным для
          потенциальных инвесторов. Мир юриспруденции не отстаёт: в последние
          десятилетия практика нормативного регулирования различных отраслей
          деятельности бизнеса в плоскости защиты природы от антропогенного
          воздействия развилось до такого состояния, что сегодня существует
          отдельная отрасль — экологическое право.
          <br />
          <br />
          Экологические нормы в России представлены разветвлённой системой
          нормативных правовых актов, которые регламентируют осуществление
          различных процедур ведения бизнеса. Их первоочередная задача — защита
          территорий и населения от негативных последствий деятельности
          человека, возможного исчерпания потенциала почв, угнетения природных
          ресурсов страны. Именно поэтому со стороны правоохранительных органов
          к бизнесу, который потенциально может оказать негативное воздействие
          на экологическую среду в месте ведения бизнеса, возникает особое
          внимание. Для того, чтобы не преступить действующее законодательство,
          не допустить неосторожного причинения вреда окружающей среде важно
          вовремя обратиться к компетентному специалисту в области
          экологического права, который сумеет не только разъяснить нормативные
          положения в области экологических норм, но и поможет применить их
          таким образом, чтобы бизнес не допустил фатальных ошибок, которые
          будут стоить ему слишком большую цену.
          <br />
          <br />
          Экологическое право занимает значительную часть практики АБ «PRIM
          Group». Наши адвокаты обладают глубокими познаниями в различных
          областях экологического права, нам удалось наработать колоссальную
          практику работы в области защиты предпринимателей от экологических
          правонарушений. Этот уникальный опыт является бесспорным конкурентным
          преимуществом не только в Сибирском регионе, но и во всей стране.
          <br />
          <br />
          Наши услуги :
          <ul>
            <li>
              Эколого-правовой комплаенс и консультирование на любом этапе
              осуществления предпринимательской деятельности;
            </li>
            <li>
              Юридическое сопровождение административно-разрешительных процедур
              в рамках экологических норм;
            </li>
            <li>
              Защита собственников, управляющих и бенефициаров бизнеса от
              уголовного преследования и необоснованного административного
              давления.
            </li>
          </ul>
        </Typography>
      </CollapsedTextBlock>
    </Box>
  );
};

export default AdministrativePressure;
