import { Collapse } from '@mui/material';
import { Box } from '@mui/system';
import OpenMoreButton from 'elements/UI/OpenMoreButton';
import { useState } from 'react';

const CollapsedTextBlock = ({ children, height = 150 }) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  return (
    <>
      <Collapse in={openCollapse} collapsedSize={height}>
        {children}
      </Collapse>
      <Box
        mt={{ xs: '18px', sm: '24px' }}
        display='flex'
        justifyContent='start'
        style={{ position: 'relative' }}
        sx={
          !openCollapse
            ? {
                '&:before': {
                  content: "''",
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '225px',
                  background:
                    'linear-gradient(180deg, rgba(218, 225, 237, 0) 0%, #DAE1ED 58.85%)',
                },
              }
            : {}
        }
      >
        <OpenMoreButton
          onClick={() => setOpenCollapse((prev) => !prev)}
          isOpen={openCollapse}
          closenText='Показать полностью'
        />
      </Box>
    </>
  );
};

export default CollapsedTextBlock;
