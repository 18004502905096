export const mockFeaturedNews = [
  {
    id: 1,
    name: `Банкротство: 
MustRead за 12 - 18 января 2023 г.`,
    date: '19 января 2023',
    tag: {
      name: 'Банкротство',
      color: '#B048A6',
    },
    hasImg: true,
    img: 'https://sun9-61.userapi.com/impg/5-mQCpoZujl4rt4YcKP22yfoAIDGhpY0F_GwlQ/7CwmSRYgIJw.jpg?size=381x313&quality=96&sign=1310808a7ad7a1e44fb90ca516bc974b&type=album',
    description: `Главные новости:
Банкротство в цифрах
К вопросу о размере субсидиарной ответственности
Оценивай бухгалтерскую отчетность правильно!
Суд не прекратит процедуру, если должник совершал управляющих`,
  },
  {
    id: 1,
    name: `Мораторий на проверки ГИТ. Когда в компанию придут, несмотря на ограничения`,
    date: '19 января 2023',
    tag: {
      name: 'Недвижимость/строительство',
      color: '#1EADA2',
    },
    hasImg: false,
    // img: 'https://sun4-5.userapi.com/impg/2Hjlb8bRC-K_ZE7O2Hq4e3EOlndz2mJ5RwwalQ/CM-AtrzQbtA.jpg?size=720x884&quality=95&sign=d56918452ed1064acc133840372beeda&type=album',
    description: `В статье — алгоритмы действий работодателя на случай, если ГИТ сообщит о проверке, профилактическом визите или выдаст предостережение. Узнаете, как компании проверяют во время моратория, что об этом думают суды и что вам делать после того, как позвонил или написал инспектор. Рассказывают ведущий юрист практики трудового и миграционного права «Пепеляев Групп» Роман Жеребцов и младший юрист Александра Текутьева.`,
  },
  {
    id: 1,
    name: `Правительство расширило и продлило смягчение требований в сфере земельного регулирования`,
    date: '19 января 2023',
    tag: {
      name: 'Трудовое право',
      color: '#FFAD00',
    },
    hasImg: true,
    img: 'https://sun9-62.userapi.com/impg/ijh4AoCgcoElwLsi87WOvl9EEr0bsI90fziS3Q/iRq0ERT_wm8.jpg?size=480x313&quality=96&sign=ffdc08e0bc381c5a1d0171e52aefb64d&type=album',
    description: `Главные новости:
Банкротство в цифрах
К вопросу о размере субсидиарной ответственности
Оценивай бухгалтерскую отчетность`,
  },
];

export const mockTags = [
  'Изменение законодательства',
  'Уголовное право',
  'Арбитраж/суд общей юрисдикции',
  'Банкротство',
  'Налоги',
  'Окружающая среда',
  'Антимонопольная служба',
  'Недвижимость/строительство',
  'Семейное право',
  'Изменение законодательства',
  'Уголовное право',
  'Арбитраж/суд общей юрисдикции',
  'Банкротство',
  'Налоги',
  'Окружающая среда',
  'Антимонопольная служба',
  'Недвижимость/строительство',
  'Семейное право',
  'Изменение законодательства',
  'Уголовное право',
  'Арбитраж/суд общей юрисдикции',
  'Банкротство',
  'Налоги',
  'Окружающая среда',
  'Антимонопольная служба',
  'Недвижимость/строительство',
  'Семейное право',
  'Изменение законодательства',
  'Уголовное право',
  'Арбитраж/суд общей юрисдикции',
  'Банкротство',
  'Налоги',
  'Окружающая среда',
  'Антимонопольная служба',
  'Недвижимость/строительство',
  'Семейное право',
  'Изменение законодательства',
  'Уголовное право',
  'Арбитраж/суд общей юрисдикции',
  'Банкротство',
  'Налоги',
  'Окружающая среда',
  'Антимонопольная служба',
  'Недвижимость/строительство',
  'Семейное право',
  'Изменение законодательства',
  'Уголовное право',
  'Арбитраж/суд общей юрисдикции',
  'Банкротство',
  'Налоги',
  'Окружающая среда',
  'Антимонопольная служба',
  'Недвижимость/строительство',
  'Семейное право',
  'Изменение законодательства',
  'Уголовное право',
  'Арбитраж/суд общей юрисдикции',
  'Банкротство',
  'Налоги',
  'Окружающая среда',
  'Антимонопольная служба',
  'Недвижимость/строительство',
  'Семейное право',
  'Изменение законодательства',
  'Уголовное право',
  'Арбитраж/суд общей юрисдикции',
  'Банкротство',
  'Налоги',
  'Окружающая среда',
  'Антимонопольная служба',
  'Недвижимость/строительство',
  'Семейное право',
  'Изменение законодательства',
  'Уголовное право',
  'Арбитраж/суд общей юрисдикции',
  'Банкротство',
  'Налоги',
  'Окружающая среда',
  'Антимонопольная служба',
  'Недвижимость/строительство',
  'Семейное право',
];

export const mockPrimInfo = [
  {
    name: `Россияне с фальшивыми рецептами атаковали аптеки. Что говорит закон по этому поводу?`,
    date: '19 января 2023',
    tag: {
      name: 'Ведомости',
      color: '#2E23E0',
      isExternal: true,
    },
  },
  {
    name: `Эксперты обсудили изменения в трудовом законодательстве на встрече клуба работодателей Петербурга`,
    date: '19 января 2023',
    tag: {
      name: 'РИА Новости',
      color: '#2E23E0',
      isExternal: true,
    },
  },
  {
    name: `Импортозамещение – путь к независимости`,
    date: '19 января 2023',
    tag: {
      name: 'Лента.ру',
      color: '#2E23E0',
      isExternal: true,
    },
  },
  {
    name: `Россияне с фальшивыми рецептами атаковали аптеки. Что говорит закон по этому поводу?`,
    date: '19 января 2023',
    tag: {
      name: 'Ведомости',
      color: '#2E23E0',
      isExternal: true,
    },
  },
  {
    name: `Россияне с фальшивыми рецептами атаковали аптеки. Что говорит закон по этому поводу?`,
    date: '19 января 2023',
    tag: {
      name: 'Ведомости',
      color: '#2E23E0',
      isExternal: true,
    },
  },
  {
    name: `Россияне с фальшивыми рецептами атаковали аптеки. Что говорит закон по этому поводу?`,
    date: '19 января 2023',
    tag: {
      name: 'Ведомости',
      color: '#2E23E0',
      isExternal: true,
    },
  },
  {
    name: `Россияне с фальшивыми рецептами атаковали аптеки. Что говорит закон по этому поводу?`,
    date: '19 января 2023',
    tag: {
      name: 'Ведомости',
      color: '#2E23E0',
      isExternal: true,
    },
  },
];

export const lastNewsMock = [
  {
    name: `Правительство расширило и продлило смягчение требований в сфере земельного регулирования`,
    date: '19 января 2023',
    tag: {
      name: 'Налоги',
      color: '#F5B741',
    },
  },
  {
    name: `Заканчивается срок подачи налоговой декларации за 2022 год. Кто обязан отчитаться по форме 3-НДФЛ`,
    date: '19 января 2023',
    tag: {
      name: 'Налоги',
      color: '#F5B741',
    },
  },
  {
    name: `Как получить налоговый вычет по ДМС`,
    date: '19 января 2023',
    tag: {
      name: 'Налоги',
      color: '#F5B741',
    },
  },
];

export const mockTeam = [
  {
    firstName: 'Вихлянцева',
    middleName: 'Марина',
    lastName: 'Валентиновна',
    photoPath:
      'https://psv4.userapi.com/c240331/u144933613/docs/d49/ece4ac8d0262/IMG_5405.png?extra=8ZjYo1LTEZ8gZjOPOTd6qDqWDG1-q28XaEuGhVPI7POhbpkvJq-EM-EBLe2WFmtCVaBICyMZJYztovWybbWQYnULeA0xoxiz6D80zyMPwfoZdYe3-F921UQagMB9PU-9oceeZEaOEBS6z3XfrwBbwfHqgw',
    description:
      'Неумелый адвокат может затянуть процесс на месяцы и годы. Умелый — еще дольше.',
  },
  {
    firstName: 'Андреенко',
    middleName: 'Виктория',
    lastName: 'Борисовна',
    photoPath:
      'https://sun9-14.userapi.com/impg/owrbY51V0m2IMmyHUbqoDwyJohvjE7YzEqU9Tw/iPDRyAmqeng.jpg?size=350x300&quality=96&sign=586aba2401116ffa7af2b16bd03962d2&type=album',
    description:
      'Адвокат готов пойти на все что угодно, чтобы выиграть процесс. Иногда он даже готов сказать правду.',
  },
  {
    firstName: 'Михайлов',
    middleName: 'Андрей',
    lastName: 'Дмитриевич',
    photoPath:
      'https://sun9-5.userapi.com/impg/rgcuVczSe7t6bV0pSpOluF-7pPdRRSnYMzAHOg/loqij_PABkI.jpg?size=260x289&quality=96&sign=41cf4e13ab1d7593e461869e739006fe&type=album',
    description:
      'Если вы и с пятого раза не понимаете, что читаете, значит, это писал юрист.',
  },
  {
    firstName: 'Анкудинов',
    middleName: 'Олег',
    lastName: 'Дмитриевич',
    photoPath:
      'https://sun9-22.userapi.com/impg/jySwsCcA0a7PDNw-keZSLZ4sEXVyKX3DzpXixQ/n-S3lP9dqbY.jpg?size=260x289&quality=96&sign=5ece2e57e5e1dbd6d1dd084bbf116658&type=album',
    description:
      'Каждый человек должен что нибудь знать о законах; а если он знает достаточно, чтобы их обходить, значит, он хороший юрист.',
  },
  {
    firstName: 'Валынина',
    middleName: 'Виктория',
    lastName: 'Константиновна',
    photoPath:
      'https://sun9-68.userapi.com/impg/JTRYlaBOMJM-oS-CfXsnI4jLPt_Oc_ujtVDZgg/sCNE8l0JLy0.jpg?size=260x289&quality=96&sign=4152738fbda899a6cd81f8a5464906da&type=album',
    description:
      'Адвокат готов пойти на все что угодно, чтобы выиграть процесс. Иногда он даже готов сказать правду.',
  },
  {
    firstName: 'Вихлянцева',
    middleName: 'Марина',
    lastName: 'Валентиновна',
    photoPath: '/static/media/team1.ec4f411d3795b792bcf3.png',
    description:
      'Адвокат готов пойти на все что угодно, чтобы выиграть процесс. Иногда он даже готов сказать правду.',
  },
];

export const mockShowedNews = [
  {
    name: `Банкротство: 
MustRead за 12 - 18 января 2023 г.`,
    date: '19 января 2023',
    tag: {
      name: 'Банкротство',
      color: '#B048A6',
    },
    hasImg: true,
    description: `Главные новости:
Банкротство в цифрах
К вопросу о размере субсидиарной ответственности
Оценивай бухгалтерскую отчетность правильно!
Суд не прекратит процедуру, если должник совершал управляющих`,
  },
  {
    name: `Мораторий на проверки ГИТ. Когда в компанию придут, несмотря на ограничения`,
    date: '19 января 2023',
    tag: {
      name: 'Налоги',
      color: '#F5B741',
    },
    hasImg: false,
    description: `В статье — алгоритмы действий работодателя на случай, если ГИТ сообщит о проверке, профилактическом визите или выдаст предостережение. Узнаете, как компании проверяют во время моратория, что об этом думают суды и что вам делать после того, как позвонил или написал инспектор. Рассказывают ведущий юрист практики трудового и миграционного права «Пепеляев Групп» Роман Жеребцов и младший юрист Александра Текутьева.`,
  },
  {
    name: `Правительство расширило и продлило смягчение требований в сфере земельного регулирования`,
    date: '19 января 2023',
    tag: {
      name: 'Окружающая среда',
      color: '#46BA43',
    },
    hasImg: true,
    description: `Главные новости:
Банкротство в цифрах
К вопросу о размере субсидиарной ответственности
Оценивай бухгалтерскую отчетность`,
  },
];

export const mockEmployee = {
  name: 'Михайлов Сергей Дмитриевич',
  status: 'Старший партнёр',
  education: 'Кандидат юридических наук',
  email: 'michaylov@prim.group',
  phone: '+7 (913) 134-54-76',
  practics: [
    { name: 'Окружающая среда', color: '#46BA43' },
    { name: 'Недвижимость/строительство', color: '#1EADA2' },
    { name: 'Семейное право', color: '#5995B1' },
  ],
  description: `Практический опыт Сергея в юридической сфере составляет более 20 лет. Он предоставляет поддержку клиентам и курирует ключевые проекты в области слияний и поглощений, реструктуризации и реорганизации бизнеса, сделки с землей и недвижимостью; крупные промышленные и инфраструктурные проекты.

Обладая большим практическим опытом взаимодействия с органами государственной власти, Сергей представляет интересы клиентов при проведении стратегически важных для них переговоров / решении приоритетных бизнес-задач, требующих согласования или участия со стороны соответствующих ведомств.`,
};
