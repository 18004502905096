import { Box, Button, ButtonBase, Grid } from '@mui/material';
import { styled } from '@mui/system';
import Container from 'elements/UI/Container';
import { NavLink } from 'react-router-dom';
import Header from '../Header';
import { useAuth } from 'hooks/useAuth';
import HeaderBlog from './components/HeaderBlog';

const Wrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  // backgroundImage: `url(${blueFilter}), url(${animatedBg})`,
  // backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',

  position: 'relative',
  '& .video-container': {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& video': {
      minHeight: '100vh',
      minWidth: '100vw',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: `rgba(18, 60, 100, 0.75)`,
    },
  },
}));

const MenuItemsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  color: 'white',
}));
const LinkBtn = styled(ButtonBase)(({ theme }) => ({
  width: 'fit-content',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '48px',
  lineHeight: '58px',
  [theme.breakpoints.up('xl')]: {
    fontSize: '64px',
    lineHeight: '77px',
  },
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '.2s',
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    height: '4px',
    right: '100%',
    left: 0,
    bottom: 0,
    position: 'absolute',
    backgroundColor: 'white',
    transition: '.2s',
  },
  '&:hover, &.active': {
    opacity: '100%',
    '&::after': {
      right: 0,
    },
  },
}));

const Menu = ({ onClose }) => {
  const { isAuth, logout } = useAuth();
  return (
    <Wrapper>
      <div className='video-container'>
        <video
          loop='loop'
          autoPlay='autoplay'
          muted='muted'
          id='myVideo'
          playsInline='playsInline'
        >
          <source
            src={
              'https://storage.yandexcloud.net/prim-group-files/animation_site_corected.mp4'
            }
            type='video/mp4'
          />
        </video>
      </div>

      <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
        <Header
          whiteLogo={true}
          invertBtnColors={true}
          menuIconClose={true}
          transparentStyle={true}
        />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '90px',
          paddingBottom: '30px',
        }}
      >
        <Container>
          <Grid
            container
            justifyContent={{ xs: 'start', sm: 'space-around' }}
            alignItems='center'
          >
            <Grid item xs={6}>
              <MenuItemsContainer>
                <LinkBtn component={NavLink} to='/main' onClick={onClose}>
                  Главная
                </LinkBtn>
                <LinkBtn component={NavLink} to='/practices' onClick={onClose}>
                  Практики
                </LinkBtn>
                <LinkBtn component={NavLink} to='/team' onClick={onClose}>
                  Команда
                </LinkBtn>
                <LinkBtn component={NavLink} to='/contacts' onClick={onClose}>
                  Контакты
                </LinkBtn>
                <LinkBtn component={NavLink} to='/blog' onClick={onClose}>
                  Блог
                </LinkBtn>
                {isAuth && (
                  <Button
                    variant='contained'
                    sx={{ width: '10em' }}
                    onClick={logout}
                  >
                    Выйти
                  </Button>
                )}
              </MenuItemsContainer>
            </Grid>
            <Grid
              item
              sx={{ display: { xs: 'none', sm: 'unset' } }}
              xs={6}
              lg={4}
            >
              <HeaderBlog />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Wrapper>
  );
};

export default Menu;
