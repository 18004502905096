import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)(({ theme }) => ({
  color: 'initial',
  textDecoration: 'none',

  '& .MuiTypography-root': {
    fontWeight: 300,
  },
  '& .MuiBreadcrumbs-separator': {
    fontWeight: 'bold',
    fontSize: '1.5em',
    lineHeight: 'initial',
  },

  '&:hover': {
    textDecoration: 'underline',
  },
}));

const StyledContainer = styled(MuiBreadcrumbs)(({ theme }) => ({
  // marginBottom: '64px',
  '& .MuiBreadcrumbs-separator': {
    fontWeight: 'bold',
    fontSize: '1.5em',
    lineHeight: 'initial',
  },
  '& li:last-of-type a': {
    textDecoration: 'underline',
  },
}));

const Breadcrumbs = ({ links, ...other }) => {
  return (
    <StyledContainer separator='·' aria-label='breadcrumb' {...other}>
      {links.map((el, idx) => (
        <StyledLink to={el.to ? '/' + el.to : ''} key={idx}>
          <Typography variant='h4'>{el.name}</Typography>
        </StyledLink>
      ))}
    </StyledContainer>
  );
};

export default Breadcrumbs;
