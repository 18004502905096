import contentAPIRoutes from 'apiRoutes/content';
import teamAPIRoutes from 'apiRoutes/team';
import {
  createOrUpdateMemberFetching,
  createOrUpdateMemberFetchingError,
  createOrUpdateMemberFetchingSuccess,
  getMemberFetching,
  getMemberFetchingError,
  getMemberFetchingSuccess,
  getMemberEditInfoFetching,
  getMemberEditInfoFetchingError,
  getMemberEditInfoFetchingSuccess,
  getTeamFetching,
  getTeamFetchingError,
  getTeamFetchingSuccess,
  getMemberContentFetching,
  getMemberContentFetchingSuccess,
  getMemberContentFetchingError,
  getMemberPublicationsFetching,
  getMemberPublicationsFetchingSuccess,
  getMemberPublicationsFetchingError,
  getMemberInterestingCasesFetching,
  getMemberInterestingCasesFetchingSuccess,
  getMemberInterestingCasesFetchingError,
  getMemberCommentsFetching,
  getMemberCommentsFetchingSuccess,
  getMemberCommentsFetchingError,
  getMemberReviewFetching,
  getMemberReviewFetchingSuccess,
  getMemberReviewFetchingError,
} from 'store/slices/teamSlices';
import getResponse from 'utils/getResponse';

export const getTeam = () => async (dispatch) => {
  try {
    dispatch(getTeamFetching());

    const response = await teamAPIRoutes.getTeamList();
    const { ok, data, errors } = getResponse(response);
    if (ok) {
      dispatch(getTeamFetchingSuccess(data));
    } else {
      dispatch(getTeamFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getTeamFetchingError(e.message));
  }
};

export const getMember = (form) => async (dispatch) => {
  try {
    dispatch(getMemberFetching());

    const response = await teamAPIRoutes.getMember(form);
    const { ok, data, errors, httpStatusCode } = getResponse(response);

    if (ok) {
      dispatch(getMemberFetchingSuccess(data));
      await Promise.allSettled([
        dispatch(getMemberContent({ memberCardId: form.id })),
        dispatch(getMemberPublications({ memberCardId: form.id })),
        dispatch(getMemberComments({ memberCardId: form.id })),
        dispatch(getMemberInterestingCases({ memberCardId: form.id })),
        dispatch(getMemberReview({ memberCardId: form.id })),
      ]);
    } else {
      dispatch(
        getMemberFetchingError(
          httpStatusCode === 404 ? 'not_found' : errors[0].value
        )
      );
    }
  } catch (e) {
    dispatch(getMemberFetchingError(e.message));
  }
};
export const getMemberEditInfo = (form) => async (dispatch) => {
  try {
    dispatch(getMemberEditInfoFetching());

    const response = await teamAPIRoutes.getMemberEditInfo(form);
    const { ok, data, errors } = getResponse(response);
    if (ok) {
      dispatch(getMemberEditInfoFetchingSuccess(data));
    } else {
      dispatch(getMemberEditInfoFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getMemberEditInfoFetchingError(e.message));
  }
};

export const createOrUpdateMember = (form) => async (dispatch) => {
  try {
    dispatch(createOrUpdateMemberFetching());

    const response = await teamAPIRoutes.memberCreateOrEdit(form);

    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(createOrUpdateMemberFetchingSuccess(data));
      dispatch(getTeam());
      if (form.id) {
        dispatch(getMember({ id: form.id }));
        dispatch(getMemberEditInfo({ id: form.id }));
      }
    } else {
      dispatch(createOrUpdateMemberFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(createOrUpdateMemberFetchingError(e.message));
  }
};

export const memberRemove = (form) => async (dispatch) => {
  try {
    dispatch(createOrUpdateMemberFetchingSuccess());

    const response = await teamAPIRoutes.memberRemove(form);
    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(createOrUpdateMemberFetchingSuccess(data));
      dispatch(getTeam());
    } else {
      dispatch(createOrUpdateMemberFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(createOrUpdateMemberFetchingError(e.message));
  }
};

export const getMemberContent = (form) => async (dispatch) => {
  try {
    dispatch(getMemberContentFetching());

    const response = await teamAPIRoutes.getContent(form);
    const { ok, data, errors } = getResponse(response);
    if (ok) {
      dispatch(getMemberContentFetchingSuccess(data));
    } else {
      dispatch(getMemberContentFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getMemberContentFetchingError(e.message));
  }
};

export const getMemberPublications = (form) => async (dispatch) => {
  try {
    dispatch(getMemberPublicationsFetching());

    const response = await teamAPIRoutes.getPublications(form);
    const { ok, data, errors } = getResponse(response);
    if (ok) {
      dispatch(getMemberPublicationsFetchingSuccess(data));
    } else {
      dispatch(getMemberPublicationsFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getMemberPublicationsFetchingError(e.message));
  }
};

export const getMemberInterestingCases = (form) => async (dispatch) => {
  try {
    dispatch(getMemberInterestingCasesFetching());

    const response = await teamAPIRoutes.getInterestingCases(form);
    const { ok, data, errors } = getResponse(response);
    if (ok) {
      dispatch(getMemberInterestingCasesFetchingSuccess(data));
    } else {
      dispatch(getMemberInterestingCasesFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getMemberInterestingCasesFetchingError(e.message));
  }
};

export const getMemberComments = (form) => async (dispatch) => {
  try {
    dispatch(getMemberCommentsFetching());

    const response = await teamAPIRoutes.getComments(form);
    const { ok, data, errors } = getResponse(response);
    if (ok) {
      dispatch(getMemberCommentsFetchingSuccess(data));
    } else {
      dispatch(getMemberCommentsFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getMemberCommentsFetchingError(e.message));
  }
};

export const getMemberReview = (form) => async (dispatch) => {
  try {
    dispatch(getMemberReviewFetching());

    const response = await teamAPIRoutes.getReview(form);
    const { ok, data, errors } = getResponse(response);
    if (ok) {
      dispatch(getMemberReviewFetchingSuccess(data));
    } else {
      dispatch(getMemberReviewFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getMemberReviewFetchingError(e.message));
  }
};

export const createMemberContent = (form) => async (dispatch) => {
  try {
    dispatch(createOrUpdateMemberFetching());

    const response = await contentAPIRoutes.addContent(form);

    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(createOrUpdateMemberFetchingSuccess(data));

      if (form.memberId) {
        dispatch(getMember({ id: form.memberId }));
      }
    } else {
      dispatch(createOrUpdateMemberFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(createOrUpdateMemberFetchingError(e.message));
  }
};

export const removeMemberContent = (form) => async (dispatch) => {
  try {
    dispatch(createOrUpdateMemberFetching());

    const memberId = form.memberId;
    delete form.memberId;

    const response = await contentAPIRoutes.removeContent(form);

    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(createOrUpdateMemberFetchingSuccess(data));

      if (form.id) {
        dispatch(getMember({ id: memberId }));
      }
    } else {
      dispatch(createOrUpdateMemberFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(createOrUpdateMemberFetchingError(e.message));
  }
};
