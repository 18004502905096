import {
  Box,
  ButtonBase,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { createComment } from 'store/actions/articleActions';
import { object, string } from 'yup';

const schema = object().shape({
  text: string().min(5, 'Миниальное колличество символов - 5'),
});

const CommentTextfield = ({ articleId }) => {
  const [text, setText] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [validationErrors, setValidationErrors] = useState('');

  const captchaRef = useRef(null);

  const dispatch = useDispatch();

  const capthaSubmit = async () => {
    let form = { articleId: articleId, text: text };

    const { ok } = await dispatch(createComment(form));
    if (ok) {
      setIsOpenModal(false);
      setText('');
      captchaRef.current.reset();
    }
  };

  const onSubmit = async () => {
    let form = { text: text };
    try {
      setValidationErrors('');
      await schema.validate(form);
      setIsOpenModal(true);
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrors(error.errors[0]);
      }
      throw error;
    }
  };

  return (
    <>
      <Box
        sx={{
          p: '14px 28px',
          borderRadius: '8px',
          mt: '32px',
          backgroundColor: 'white',
          transition: 'all .25s',
          border: !isFocus ? '2px solid #DAE1ED' : '2px solid #7DACFB',
        }}
      >
        <TextField
          fullWidth
          multiline
          placeholder='Написать комментарий...'
          sx={{
            '& fieldset': { border: 'none' },
            '& .MuiInputBase-root': { padding: 'unset' },
          }}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography sx={{ color: '#ca0000' }} variant='subtitle1'>
              {validationErrors}
            </Typography>
          </Grid>
          <Grid item>
            <ButtonBase
              sx={{
                mt: '14px',
                ml: 'auto',
                display: 'block',
                p: '12px 40px',
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '8px',
              }}
              onClick={onSubmit}
            >
              Отправить
            </ButtonBase>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={isOpenModal}
        keepMounted
        onClose={() => setIsOpenModal(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
            onChange={capthaSubmit}
          />
        </Box>
      </Modal>
    </>
  );
};

export default CommentTextfield;
