import { TextField } from '@mui/material';
import { styled } from '@mui/system';

const CustomInput = styled(TextField)(({ theme }) => ({
  marginTop: '64px',
  width: '100%',
  '& .MuiInputBase-root': {
    // height: '40px',
    fontStyle: '  ',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '39px',
    '&:before': {
      bottom: '2px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '&:after': {
      bottom: '2px',
      borderBottom: '2px solid black',
    },
  },
}));

const SearchInput = (props) => {
  return (
    <>
      <CustomInput
        id='standard-basic'
        variant='standard'
        placeholder='Найти'
        {...props}
      />
    </>
  );
};

export default SearchInput;
