import { Button, Grid, Typography } from '@mui/material';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { formattUtcTime, getTimeFromUtc } from 'utils/formattUtcTime';

const Comment = ({ comment = null, canBeDeleted = false, onDelete }) => {
  return (
    <>
      <Grid container spacing='14px' alignItems='center' mt='18px'>
        <Grid item>
          <UserIcon />
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ fontWeight: 900 }}>
            Гость
          </Typography>
          <Typography
            variant='caption'
            sx={{ color: '#717171', display: 'block' }}
          >
            {formattUtcTime(comment?.createdDateTime)} в{' '}
            {getTimeFromUtc(comment?.createdDateTime)}
          </Typography>
        </Grid>
        {canBeDeleted ? (
          <Grid item sx={{ ml: 'auto' }}>
            <Button variant='outlined' onClick={() => onDelete(comment.id)}>
              Удалить
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Typography variant='body1' mt='8px' sx={{ whiteSpace: 'pre-line' }}>
        {comment?.text}
      </Typography>
    </>
  );
};

export default Comment;
