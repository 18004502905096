import { Box, styled } from '@mui/system';

const Wrapper = styled('div')(({ theme, color, height }) => ({
  position: 'relative',
  zIndex: 1,
  height: height,
  '&:before': {
    position: 'absolute',
    zIndex: '-1',
    content: "''",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    border: `2px solid ${color}`,
    borderRadius: '36px',
    transform: 'translate(8px, 8px)',
  },
}));

const StrokeWrapper = ({ children, color = 'white', height = '' }) => {
  return (
    <Wrapper color={color} height={height}>
      {children}
    </Wrapper>
  );
};

export default StrokeWrapper;
