import { Box, Button, Grid, Modal, Typography } from '@mui/material';

export const ConfirmModal = ({ isOpen, onClose, onSubmit, isLoading }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          width: '400px',
          padding: '24px',
          backgroundColor: 'white',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '24px',
        }}
      >
        <Typography variant='h4' sx={{ mb: '24px' }}>
          Подтверждение
        </Typography>
        <Typography variant='body2' sx={{ mb: '24px' }}>
          Вы действительно хотите это сделать?
        </Typography>

        <Grid container justifyContent='space-around' mt='24px'>
          <Grid item>
            <Button onClick={onSubmit} disabled={isLoading}>
              Подтвердить
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose}>Отмена</Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
