import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import Practices from 'elements/components/Practices';
import ArrowButton from 'elements/UI/ArrowButton';
import Container from 'elements/UI/Container';
import { useCallback, useState } from 'react';
import { ReactComponent as ArrowAngle } from 'assets/elements/arrow-angle.svg';
import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { updateChangePageHeightEvent } from 'store/slices/uiSlices';
import OpenMoreButton from 'elements/UI/OpenMoreButton';

const PracticesContainer = styled('div')(({ theme }) => ({
  border: '2px solid #FFFFFF',
  borderRadius: '36px',
  paddingTop: '78px',
  paddingBottom: 0,
  position: 'relative',
  // paddingLeft: '6px',
  // paddingRight: '6px',
  margin: '0 -6px',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    paddingLeft: '16px',
    paddingRight: '16px',
    margin: '0 -16px',
  },
  [theme.breakpoints.up('md')]: {
    // paddingLeft: '40px',
    // paddingRight: '40px',
    margin: '0 -40px',
  },
  '& > div': {
    paddingLeft: '12px',
    paddingRight: '12px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '22px',
      paddingRight: '22px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '40px',
      paddingRight: '40px',
    },
  },
  '& .practicesLabel': {
    color: 'black',
    background: theme.palette.blueBg.main,
    textShadow: 'none',
    position: 'absolute',
    top: '-36px',
    left: '84px',
  },
}));
const HiddenBlock = styled('div')(({ theme }) => ({
  maxHeight: '425px',
  overflow: 'hidden',
  position: 'relative',
  transition: 'all 0.6s',
  paddingBottom: '180px',
  '&.open': {
    maxHeight: '2000px',
  },
}));
const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  height: '180px',
  [theme.breakpoints.down('sm')]: {
    height: '120px',
  },
  paddingLeft: '12px',
  paddingRight: '12px',
  [theme.breakpoints.up('sm')]: {
    paddingLeft: '22px',
    paddingRight: '22px',
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  '&:before': {
    content: "''",
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    background:
      'linear-gradient(180deg, rgba(218, 225, 237, 0) 0%, #DAE1ED 58.85%)',
    [theme.breakpoints.down('sm')]: {
      top: '-64px',
    },
  },
}));

const CollapsePracticesBlock = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();

  const toggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
    setTimeout(() => dispatch(updateChangePageHeightEvent()), 600);
  }, [dispatch]);

  return (
    <>
      <Typography
        variant='h2'
        className='practicesLabel'
        component='span'
        sx={{
          position: 'relative',
          top: '0.5em',
          left: { xs: '16px', sm: '24px' },
          zIndex: 10,
          color: 'black',
          background: theme.palette.blueBg.main,
        }}
      >
        Практики
      </Typography>
      <PracticesContainer>
        <HiddenBlock className={`${isOpen ? 'open' : 'close'}`}>
          <Practices />
          <ButtonWrapper>
            <OpenMoreButton
              onClick={toggle}
              isOpen={isOpen}
              closenText='Смотреть все практики'
              invertColor={false}
            ></OpenMoreButton>
          </ButtonWrapper>
        </HiddenBlock>
      </PracticesContainer>
    </>
  );
};

export default CollapsePracticesBlock;
