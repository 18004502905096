import api from '../http/index';

const tagAPIRoutes = {
  getTagSelectList: () => api.get('/Tag/GetTagSelectList'),
  tagCreateOrEdit: (data) =>
    api.post('/Tag/CreateOrEdit', JSON.stringify(data)),
  tagRemove: (data) => api.post('/Tag/Remove', null, { params: data }),
};

export default tagAPIRoutes;
