import { useTheme } from '@emotion/react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Box, styled } from '@mui/system';
import { Swiper, SwiperSlide } from 'swiper/react';
import { generalJurisdictionMock } from '../mock';
import CollapsedTextBlock from '../components/collapsedTextBlock';
import PaperBlank from 'elements/UI/PaperBlank';
import { EmployeeItemHorizontal } from 'elements/components/EmployeeItem';
import { useNavigate } from 'react-router-dom';
import { Autoplay } from 'swiper';
import { useRef } from 'react';

const GeneralJurisdiction = ({ memberCards }) => {
  const theme = useTheme();
  const greaterThanXl = useMediaQuery(theme.breakpoints.up('xl'));
  const greaterThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const greaterThanMmd = useMediaQuery(theme.breakpoints.up('mmd'));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const greaterThanSsm = useMediaQuery(theme.breakpoints.up('ssm'));

  const swiperRef = useRef(null);

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        '& ul > li:not(:last-of-type)': {
          marginBottom: '0.8em',
        },
      }}
    >
      {Object.values(generalJurisdictionMock).map((el, idx) => (
        <Box key={idx} mb={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              {el.name && (
                <Typography variant='h5' fontWeight={600} mb={3}>
                  {el.name}
                </Typography>
              )}

              {/* <PersonBlock person={memberCards?.[idx]} /> */}
              <EmployeeItemHorizontal
                employee={memberCards?.[idx]}
                withContact={true}
                onClick={() => navigate(`/team/${memberCards?.[idx]?.id}`)}
                showPosition={true}
                heigth='245px'
                noPhone={true}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <CollapsedTextBlock height='400px'>{el.text}</CollapsedTextBlock>
            </Grid>
          </Grid>
          <Swiper
            ref={swiperRef}
            className='mySwiper swiperOverflow'
            slidesPerView={
              greaterThanXl
                ? 3
                : greaterThanLg
                ? 2.6
                : greaterThanMd
                ? 2.2
                : greaterThanMmd
                ? 1.8
                : greaterThanSm
                ? 1.8
                : greaterThanSsm
                ? 1
                : 1
            }
            style={{ cursor: 'grab' }}
            spaceBetween={40}
            modules={[Autoplay]}
            speed={3000}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
          >
            {el.cards &&
              el.cards.map((card, idx) => (
                <SwiperSlide
                  key={idx}
                  onClick={() => swiperRef.current.swiper.autoplay.stop()}
                >
                  <PaperBlank mt={4}>
                    <Box sx={{ p: '24px' }}>
                      <Typography fontWeight='bold' mb={2}>
                        {card.title}
                      </Typography>
                      <ol style={{ paddingLeft: '24px' }}>
                        {card.content.map((point, pIdx) => (
                          <li key={pIdx} style={{ marginTop: '8px' }}>
                            {point}
                          </li>
                        ))}
                      </ol>
                    </Box>
                  </PaperBlank>
                </SwiperSlide>
              ))}
          </Swiper>
        </Box>
      ))}
    </Box>
  );
};

export default GeneralJurisdiction;
