import practiceAPIRoutes from 'apiRoutes/practice';
import {
  addingMemberFetching,
  addingMemberFetchingError,
  addingMemberFetchingSuccess,
  getAllMembersFetching,
  getAllMembersFetchingError,
  getAllMembersFetchingSuccess,
  getPracticeInfoFetching,
  getPracticeInfoFetchingError,
  getPracticeInfoFetchingSuccess,
} from 'store/slices/practiceSlices';
import getResponse from 'utils/getResponse';

export const getPracticeInfo = (form) => async (dispatch) => {
  try {
    dispatch(getPracticeInfoFetching());

    const response = await practiceAPIRoutes.getPracticeInfo(form);
    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(getPracticeInfoFetchingSuccess(data));
    } else {
      dispatch(getPracticeInfoFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getPracticeInfoFetchingError(e.message));
  }
};

export const getPracticeAllMembers = (form) => async (dispatch) => {
  try {
    dispatch(getAllMembersFetching());

    const response = await practiceAPIRoutes.getPracticeMembers(form);
    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(getAllMembersFetchingSuccess(data));
    } else {
      dispatch(getAllMembersFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getAllMembersFetchingError(e.message));
  }
};

export const addPracticeMember = (form) => async (dispatch) => {
  try {
    dispatch(addingMemberFetching());

    const response = await practiceAPIRoutes.addMember(form);

    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(addingMemberFetchingSuccess(data));
      //   dispatch(getTags()); TODO
    } else {
      dispatch(addingMemberFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(addingMemberFetchingError(e.message));
  }
};

export const removePracticeMember = (form) => async (dispatch) => {
  try {
    dispatch(addingMemberFetching());

    const response = await practiceAPIRoutes.removeMember(form);

    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(addingMemberFetchingSuccess(data));
      //   dispatch(getTags()); TODO
    } else {
      dispatch(addingMemberFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(addingMemberFetchingError(e.message));
  }
};
