import AdministrativePressure from './pages/administrativePressure';
import Bankruptcy from './pages/bankruptcy';
import CorporateConflictsArticle from './pages/corporateConflicts';
import CriminalPractice from './pages/criminalPractice';
import FamilyLowArticle from './pages/familyLaw';
import GeneralJurisdiction from './pages/generalJurisdiction';
import LandArtcle from './pages/land';
import TaxDdisputesArticle from './pages/taxDdisputes';

export const practiceArticlesModel = [
  {
    title: 'Судебное представительство',
    name: 'Арбитражные споры и споры в судах общей юрисдикции',
    component: (props) => <GeneralJurisdiction {...props} />,
    customPage: true,
  },
  {
    title: 'Уголовно-правовая практика',
    name: 'Уголовно–правовая защита бизнеса и физических лиц',
    component: (props) => <CriminalPractice {...props} />,
    customPage: true,
  },
  {
    title: 'Банкротство: споры и сопровождение процедур',
    name: 'Банкротство',
    component: (props) => <Bankruptcy {...props} />,
    customPage: true,
  },
  {
    title: 'Налоговые споры. Налоговый консалтинг',
    name: 'Налоговые споры. Налоговый консалтинг',
    component: (props) => <TaxDdisputesArticle {...props} />,
  },
  {
    title:
      'Разрешение корпоративных конфликтов. Защита прав инвесторов и акционеров. M&A',
    name: 'Разрешение корпоративных конфликтов. Защита прав инвесторов и акционеров. M&A',
    component: (props) => <CorporateConflictsArticle {...props} />,
  },

  {
    title:
      'Защита от неправомерного административного давления и регуляторные вопросы',
    name: 'Административное давление и регуляторные вопросы',
    isSeveralBlocks: true,
    component: (props) => <AdministrativePressure {...props} />,
    toPathAnchor: 'first',
  },
  {
    title: 'Госзакупки',
    name: 'Административное давление и регуляторные вопросы',
    isSeveralBlocks: true,
    component: (props) => <AdministrativePressure {...props} />,
    toPathAnchor: 'second',
  },
  {
    title: 'Экологическое право ',
    name: 'Административное давление и регуляторные вопросы',
    isSeveralBlocks: true,
    component: (props) => <AdministrativePressure {...props} />,
    toPathAnchor: 'third',
  },

  {
    title: 'Земля, недвижимость и строительство',
    name: 'Земля, недвижимость, ЖКХ',
    component: (props) => <LandArtcle {...props} />,
    toPathAnchor: 'first',
  },
  {
    title: 'Жилищно-коммунальное хозяйство',
    name: 'Земля, недвижимость, ЖКХ',
    component: (props) => <LandArtcle {...props} />,
    toPathAnchor: 'second',
  },

  {
    title: 'Семейное право',
    name: 'Семейное право, трудовое право, наследственное право',
    isSeveralBlocks: true,
    component: (props) => <FamilyLowArticle {...props} />,
    toPathAnchor: 'first',
  },
  {
    title: 'Трудовое право',
    name: 'Семейное право, трудовое право, наследственное право',
    isSeveralBlocks: true,
    component: (props) => <FamilyLowArticle {...props} />,
    toPathAnchor: 'second',
  },
  {
    title: 'Наследственное право',
    name: 'Семейное право, трудовое право, наследственное право',
    isSeveralBlocks: true,
    component: (props) => <FamilyLowArticle {...props} />,
    toPathAnchor: 'third',
  },
];
