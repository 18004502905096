import Container from 'elements/UI/Container';
import { useCallback, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import parse from 'html-react-parser';

import 'react-quill/dist/quill.snow.css';
import { articleHtml } from '../mock';
import filesAPIRoutes from 'apiRoutes/files';

const icons = ReactQuill.Quill.import('ui/icons');
icons.anchor =
  '<?xml version="1.0" ?><svg baseProfile="tiny" height="24px" id="Layer_1" version="1.2" viewBox="0 0 24 24" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M18,13.5c-0.553,0-1,0.447-1,1c0,2.414-1.721,4.434-4,4.898V12h4c0.553,0,1-0.447,1-1s-0.447-1-1-1h-4V8.816   C14.162,8.403,15,7.305,15,6c0-1.657-1.343-3-3-3S9,4.343,9,6c0,1.305,0.838,2.403,2,2.816V10H7c-0.553,0-1,0.447-1,1s0.447,1,1,1   h4v7.398c-2.279-0.465-4-2.484-4-4.898c0-0.553-0.447-1-1-1s-1,0.447-1,1c0,3.859,3.141,7,7,7s7-3.141,7-7   C19,13.947,18.553,13.5,18,13.5z M12,5c0.551,0,1,0.449,1,1s-0.449,1-1,1s-1-0.449-1-1S11.449,5,12,5z"/></g></svg>';

function handleTags() {
  const cursorPosition = this.quill.getSelection().index;
  const value = '[[Название_якоря]]\n';
  this.quill.insertText(cursorPosition, '\n');
  this.quill.removeFormat(cursorPosition + 1, this.quill.getSelection().length);
  this.quill.insertText(cursorPosition + 1, value);
  // this.quill.setSelection(cursorPosition + value.length);
}

const TextEditor = ({ value, setValue }) => {
  const quillRef = useRef(null);

  const handleImageUpload = useCallback((file) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('uploadFile', file);

      try {
        const response = await filesAPIRoutes.upload(formData);
        // const response = await fetch('http://your-api-url.com/upload-image', {
        //   method: 'POST',
        //   body: formData,
        // });
        let imageUrl = response?.data?.entity?.path;
        if (imageUrl) {
          const range = quillRef.current.getEditor().getSelection();
          if (range) {
            quillRef.current
              .getEditor()
              .insertEmbed(range.index, 'image', imageUrl, 'user');
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  // const handleInsertLink = useCallback(() => {
  //   const url = prompt('Enter the URL');
  //   if (url) {
  //     const quill = quillRef.current.getEditor();
  //     quill.focus();
  //     const { index } = quill.getSelection();
  //     quill.insertText(index, url, 'link', url);
  //     quill.setSelection(index + url.length, 0);
  //   }
  // }, []);

  return (
    <div style={{ background: 'white' }}>
      <ReactQuill
        ref={quillRef}
        theme='snow'
        value={value}
        onChange={setValue}
        modules={{
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, false] }],
              [{ size: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
              ],
              ['link', 'image'],
              ['anchor'],
            ],
            handlers: {
              anchor: handleTags,
              image: handleImageUpload,
              // link: handleInsertLink,
            },
          },
        }}
      />
    </div>
    // {/* {parse(value)} */}
  );
};

export default TextEditor;
