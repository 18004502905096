export const formattUtcTime = (dateString) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('ru-RU', options);
  return formattedDate;
};

export const getTimeFromUtc = (dateString) => {
  const date = new Date(dateString);
  const options = { hour: 'numeric', minute: 'numeric', hour12: false };
  const localTime = new Intl.DateTimeFormat('default', options).format(date);
  return localTime;
};
