import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Autoplay } from 'swiper';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Container from 'elements/UI/Container';

const CustomSwiper = styled(Swiper)(({ theme }) => ({
  '& .swiper-pagination': {
    position: 'static',
    // marginTop: '50px',
    // width: 'calc((100% - 200px * 2) / 3 * 2)',

    margin: '40px auto 0 auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: `calc((100% - 2 * 120px) * 2 / 3)`,
      marginLeft: 120,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: `1800px`,
      paddingRight: `calc((min(100%, 1800px) - 400px) * 2 / 3)`,
      paddingLeft: '200px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& .swiper-pagination-bullet': {
      width: '16px',
      height: '16px',
    },
  },
}));

const CarouselBlock = () => {
  const TextContainer = styled(Box)(({ theme }) => ({
    color: 'white',
    '& h2': {
      marginTop: '24px',
    },
  }));

  const Slide = ({ text, subtext }) => {
    return (
      <Container>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <TextContainer>
              <Typography variant='h1' sx={{ whiteSpace: 'pre-line' }}>
                {text}
              </Typography>
              {subtext ? <Typography variant='h2'>{subtext}</Typography> : null}
            </TextContainer>
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <>
      <CustomSwiper
        style={{
          '--swiper-pagination-color': '#FFFFFF',
          '--swiper-pagination-bullet-height': '24px',
          '--swiper-pagination-bullet-width': '24px',
          '--swiper-pagination-bullet-horizontal-gap': '12px',
          '--swiper-pagination-bullet-inactive-color': '#B6B6D5',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-bullet-opacity': '1',
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        loop={true}
        className='mySwiper'
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <Slide text={`Адвокатское бюро\nПРИМ ГРУПП`} subtext={`PRIM GROUP`} />
        </SwiperSlide>

        <SwiperSlide>
          <Slide
            text={`20 лет в партнерстве\nс бизнесом,\nс заботой о людях`}
          />
        </SwiperSlide>

        <SwiperSlide>
          <Slide text={`PRIM\n‘от латинского PRIMus`} subtext={`Первый`} />
        </SwiperSlide>

        <SwiperSlide>
          <Slide
            text={`Regulae primae –\nnoli esse numero duo`}
            subtext={`Первое правило – не быть номером два`}
          />
        </SwiperSlide>
      </CustomSwiper>
    </>
  );
};

export default CarouselBlock;
