import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import ContactBlock from 'elements/components/ContactBlock';
import FeaturedNews from 'elements/components/FeaturedNews';
import PrimInfoBlock from 'elements/components/PrimInfoBlock';
import ArrowButton from 'elements/UI/ArrowButton';
import Container from 'elements/UI/Container';
import AboutUsBlock from './components/AboutUsBlock';
import AnimatedBlock from './components/AnimatedBlock';
import CollapsePracticesBlock from './components/CollapsePracticesBlock';
import GalleryBlock from './components/GalleryBlock';
import TeamBlock from './components/TeamBlock';
import Anchor from 'elements/UI/Anchor';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const MainPage = () => {
  const { team } = useSelector((state) => state.team);
  const navigate = useNavigate();
  const handleClickBlog = () => {
    setTimeout(() => {
      navigate('/blog');
    }, 300);
  };
  return (
    <>
      <Anchor name='наверх' linkId={'up-anchor'} />
      <AnimatedBlock />
      <Container>
        <Box sx={{ mt: '-150px' }}>
          <FeaturedNews />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '48px' }}>
          <ArrowButton onClick={handleClickBlog}>
            <Typography variant='h6Normal'>Перейти в блог</Typography>
          </ArrowButton>
        </Box>
        <Box sx={{ mt: '144px' }}>
          <Anchor name='практики' linkId={'practices-anchor'} />
          <CollapsePracticesBlock />
        </Box>
      </Container>
      <Box sx={{ mt: '120px' }}>
        <Anchor name='о нас' linkId={'about-anchor'} />
        <AboutUsBlock />
      </Box>
      <Container>
        <Box sx={{ mt: '120px' }}>
          <Anchor name='команда' linkId={'team-anchor'} />
          <TeamBlock team={team} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '64px' }}>
          <ArrowButton onClick={() => navigate('/team')}>
            <Typography variant='h6Normal'>
              Перейти на страницу команды
            </Typography>
          </ArrowButton>
        </Box>
        <Box sx={{ mt: '88px' }}>
          <GalleryBlock />
        </Box>
        <Box sx={{ mt: '150px' }}>
          <Anchor name='контакты' linkId={'contact-anchor'} />
          <ContactBlock />
        </Box>
        <Box sx={{ mt: '100px', display: { xs: 'none', ssm: 'block' } }}>
          <PrimInfoBlock />
        </Box>
      </Container>
    </>
  );
};

export default MainPage;
