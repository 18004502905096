import { Grid, Typography } from '@mui/material';
import Container from 'elements/UI/Container';
import Video from './Video';
import pravo300logo from './../../../assets/img/pravo300logo.png';

const AboutUsBlock = () => {
  return (
    <>
      <Container>
        <Typography variant='h2' mb={{ xs: '24px', md: '48px' }}>
          О нас
        </Typography>
        <Grid container spacing={5} mb='80px' justifyContent='center'>
          <Grid item sm={5}>
            <Typography>
              Адвокатское бюро Юридическая группа PRIM group - объединение
              успешных адвокатских практик в энергичную команду. География нашей
              деятельности охватывает все регионы России и страны СНГ.
              <br />
              <br />
              Защита, помощь, поиск эффективных решений для достижения успешного
              результата позволили нам за десятилетия командной работы найти
              партнеров во всех сферах бизнеса, удачно представлять интересы
              региона и муниципалитетов в различных спорах и переговорах,
              оправдывать надежды людей на получение справедливого решения в
              суде.
              <br />
              <br />
              Мы последовательны в отстаивании интересов наших клиентов на всех
              стадиях и во всех судебных и административных инстанциях для
              разрешения спора или конфликта.
            </Typography>
          </Grid>
          <Grid item sm={5}>
            <Typography>
              Мы будем там, где мы нужны. Мы умеем быстро реагировать на запросы
              клиентов и специализируемся на решении сложных правовых задач,
              используя комплексный командный подход и взвешенные экспертные
              оценки
              <br />
              <br />
              Компетентность команды Юридической группы PRIM group базируется на
              сочетании опыта и молодой энергии, теоретических знаниях и
              постоянном повышении квалификации, последовательности и
              настойчивости в достижении поставленных перед нами целей.
              <br />
              <br />
              Мы не боимся ответственности за правильность нашего выбора в
              интересах клиента.
              <br />
              <br />
              Нам нравится обсуждать сложные профессиональные вопросы как на
              уровне идеи за чашкой кофе, так и обмениваться знаниями и опытом с
              коллегами на конференциях, форумах и иных профессиональных (и не
              очень) площадках.
              <br />
              <br />
              Уровень профессионализма нашей команды подтверждается лидирующими
              позициями рейтинга «Право.ru-300».
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <img
              src={pravo300logo}
              alt='pravo300'
              style={{ maxWidth: '100%', borderRadius: '10%' }}
            />
          </Grid>
        </Grid>
      </Container>
      <Video />
    </>
  );
};

export default AboutUsBlock;
