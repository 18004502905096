export const blogMock = [
  {
    id: 1,
    name: `Первый обзор практики ВС за 2023: интересные позиции`,
    date: '19 января 2023',
    tag: {
      name: 'Гражданские дела',
      color: '#B048A6',
    },
    hasImg: true,
    img: 'https://sun9-68.userapi.com/impg/HTJNzsaGLdIA6iOH8Uy9dTP5jAVeFja2KtQ-lg/NosQTJxD9o0.jpg?size=480x309&quality=96&sign=f55ad037e36e028d8d0a8265d53be051&type=album',
  },
  {
    id: 1,
    name: `Правительство расширило и продлило смягчение требований в сфере земельного регулирования`,
    date: '19 января 2023',
    tag: {
      name: 'Комментарий адвоката',
      color: '#abab0c',
    },
    hasImg: false,
  },
  {
    id: 1,
    name: `Правительство расширило и продлило смягчение требований в сфере земельного регулирования`,
    date: '19 января 2023',
    tag: {
      name: 'Семейное право',
      color: '#5995B1',
    },
    hasImg: false,
  },
  {
    id: 1,
    name: `Правительство расширило и продлило смягчение требований в сфере земельного регулирования`,
    date: '19 января 2023',
    tag: {
      name: 'Изменение законодательства',
      color: '#E57B6D',
    },
    hasImg: true,
    // img: 'https://sun4-5.userapi.com/impg/2Hjlb8bRC-K_ZE7O2Hq4e3EOlndz2mJ5RwwalQ/CM-AtrzQbtA.jpg?size=720x884&quality=95&sign=d56918452ed1064acc133840372beeda&type=album',
  },
  {
    id: 1,
    name: `Эксперты оценили рост цен на аренду жилья в майские праздники`,
    date: '19 января 2023',
    tag: {
      name: 'Недвижимость/строительство',
      color: '#1EADA2',
    },
    hasImg: true,
    img: 'https://sun9-62.userapi.com/impg/ijh4AoCgcoElwLsi87WOvl9EEr0bsI90fziS3Q/iRq0ERT_wm8.jpg?size=480x313&quality=96&sign=ffdc08e0bc381c5a1d0171e52aefb64d&type=album',
  },
  {
    id: 1,
    name: `Импортозамещение – путь к независимости`,
    date: '19 января 2023',
    tag: {
      name: 'На злобу дня',
      color: '#9595F3',
    },
    hasImg: true,
    img: 'https://sun9-8.userapi.com/impg/Vtu5SAVzspjaE7p_6fjeP3haBEOzLuz9kVMm3A/iLlr51hsNDo.jpg?size=480x309&quality=96&sign=e61ad8225c036c49e794567336eea1d5&type=album',
  },
  {
    id: 1,
    name: `Эксперты обсудили изменения в трудовом законодательстве на встрече клуба работодателей Петербурга`,
    date: '19 января 2023',
    tag: {
      name: 'Трудовое право',
      color: '#FFAD00',
    },
    hasImg: true,
    // img: 'https://sun4-5.userapi.com/impg/2Hjlb8bRC-K_ZE7O2Hq4e3EOlndz2mJ5RwwalQ/CM-AtrzQbtA.jpg?size=720x884&quality=95&sign=d56918452ed1064acc133840372beeda&type=album',
  },
  {
    id: 1,
    name: `Мораторий на проверки ГИТ. Когда в компанию придут, несмотря на ограничения`,
    date: '19 января 2023',
    tag: {
      name: 'Недвижимость/строительство',
      color: '#1EADA2',
    },
    hasImg: false,
  },
  {
    id: 1,
    name: `Россияне с фальшивыми рецептами атаковали аптеки. Что говорит закон по этому поводу?`,
    date: '19 января 2023',
    tag: {
      name: 'Окружающая среда',
      color: '#46BA43',
    },
    hasImg: true,
    img: 'https://sun9-61.userapi.com/impg/5-mQCpoZujl4rt4YcKP22yfoAIDGhpY0F_GwlQ/7CwmSRYgIJw.jpg?size=381x313&quality=96&sign=1310808a7ad7a1e44fb90ca516bc974b&type=album',
  },
  {
    id: 1,
    name: `Правительство расширило и продлило смягчение требований в сфере земельного регулирования`,
    date: '19 января 2023',
    tag: {
      name: 'Комментарий адвоката',
      color: '#abab0c',
    },
    hasImg: false,
  },
];
