import { Box, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTabs = styled((props) => <Box />)({
  width: 'fit-content',
  overflow: 'visible',

  '& .MuiTabs-flexContainer': {
    gap: '48px',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

export const StyledTab = styled((props) => (
  <span {...props}>{props.label}</span>
))(({ theme }) => ({
  cursor: 'pointer',
  padding: '0',
  textTransform: 'none',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '29px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '32px',
    lineHeight: '39px',
  },
  // [theme.breakpoints.up('lg')]: {
  //   fontSize: '36px',
  //   lineHeight: '44px',
  // },
  color: 'black',
  opacity: '0.5',
  transition: 'all .3s',
  paddingBottom: '5px',
  position: 'relative',
  '&:after': {
    position: 'absolute',
    content: '""',
    bottom: 0,
    left: 0,
    right: 0,
    height: '2px',
    background: 'black',
  },
  '&.Mui-selected': {
    color: 'black',
    opacity: '1',
  },
}));
