import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import CollapsedTextBlock from '../components/collapsedTextBlock';
import { useEffect } from 'react';
import Anchor from 'elements/UI/Anchor';

const FamilyLowArticle = ({ toPathAnchor = null }) => {
  useEffect(() => {
    if (toPathAnchor) {
      document.getElementById(toPathAnchor)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [toPathAnchor]);
  return (
    <Box
      sx={{
        '& ul > li:not(:last-of-type)': {
          marginBottom: '0.8em',
        },
      }}
    >
      <Anchor name='Семейные споры' linkId={'first'} />
      <Typography
        variant='h2'
        fontWeight='500'
        mb={{ xs: '32px', sm: '40px', md: '24px' }}
      >
        Семейные споры
      </Typography>
      <CollapsedTextBlock>
        <>
          <Typography textAlign='justify'>
            Семейные отношения хотя и являются непубличной стороной жизни любого
            человека, но всё же не обходятся без самостоятельного правового
            регулирования. Законодательство в этой сфере направлено на решение
            множества специфических задач — начиная регулированием порядка
            создания семьи, и заканчивая защитой интересов детей при разводе
            родителей.
            <br />
            <br />
            На первый взгляд, вопросы семейного права могут показаться
            достаточно простыми и понятными. Но это совершенно не так. Кажущаяся
            простота обманчива: чаще всего для правильного разрешения любой
            ситуации, вытекающей из семейного законодательства, необходим
            комплексный подход к анализу всех существенных для дела
            обстоятельств, реалистичное понимание перспектив достижения желаемой
            цели и эффективность совершаемых действий для её разрешения.
            Семейные дела регулярно выходят за пределы регулирования семейного
            законодательства, поэтому для работы с ними необходима глубокая
            компетенция в различных областях российского законодательства. К
            тому же, жизненно необходимы навыки адекватного и своевременного
            реагирования в сложной обстановке, ведь семейные споры чаще всего
            связаны с серьёзным личностным конфликтом, предельно эмоциональными
            и сложными спорами.
            <br />
            <br />
            Раздор между супругами и другими членами семьи нередко приводит их к
            состоянию затяжных семейных войн и длительных судебных тяжб. В
            центре подобного конфликта всегда находится раздел имущества и
            борьба за детей. Для эффективной защиты интересов участников любого
            семейного конфликта необходимо учитывать множество факторов:
            личностные данные его участников, имущественное и наследственное
            состояние спорящих, уровень взаимодействия между членами семьи друг
            с другом, детьми, и другими участниками правоотношений. Отдельные
            специальные познания и профессиональные навыки необходимы при
            взаимодействии с государственными органами, в том числе и органами
            опеки и попечительства, поскольку учёт особенностей работы с ними
            может существенно повлиять на исход конкретной ситуации.
            <br />
            <br />
            Что же до бизнес-среды, то нужно осознавать, что любые семейные
            распри могут оказать прямое негативное воздействие на экономические
            показатели бизнеса. А членам семьи любого предпринимателя важно
            помнить, что отрицательные результаты бизнеса могут серьёзно
            сказаться на них самих. Поэтому в случае возникновения любых споров
            просто необходимо своевременно обратиться к профессионалу, который в
            сложной ситуации поможет разрешить её с минимальными потерями для
            всех участников отношений.
            <br />
            <br />
            Наши адвокаты имеют широкий спектр профессиональных компетенций в
            области разрешения сложных семейных дел и споров. Наше бюро имеет
            многолетний опыт помощи клиентам в ведении переговоров об условиях
            различных соглашений, в том числе соглашений о разделе супругами
            совместно нажитого имущества, которые вот уже десятки лет позволяют
            всем участникам спорных отношений эффективно защищать свои права,
            ведении семейных споров, установлении порядка общения с детьми,
            определения места жительства несовершеннолетних детей с одним из
            родителей. Деликатный и вместе с тем результативный подход к защите
            интересов несовершеннолетних существенно выделяет нас на рынке
            юридических услуг в сфере семейного права.
          </Typography>
          <br />
          <Typography variant='h7' component='p' fontWeight='bold'>
            Адвокаты АБ «PRIM Group» оказывают следующие услуги:
          </Typography>
          <Typography textAlign='justify'>
            <ul>
              <li>
                консультирование по вопросам заключения брачного договора и его
                подготовка с учетом имущественных интересов всех
                заинтересованных сторон;
              </li>
              <li>
                судебное представительство по делам о разделе общего имущества
                супругов (защита личного имущества, поиск практичных решений для
                предотвращения конфликтного совладения неделимыми объектами);
              </li>
              <li>
                судебное представительство по делам об определении места
                жительства ребенка и порядка общения с ним, медиативная помощь в
                достижении соглашения между родителями;
              </li>
              <li>
                консультирование и судебное представительство по вопросам
                расторжения брака, урегулирование взаимоотношений сторон при
                исполнении алиментных обязательств;
              </li>
              <li>сопровождение процедуры банкротства супруга/супругов;</li>
            </ul>
          </Typography>
        </>
      </CollapsedTextBlock>
      <br />
      <br />
      <Anchor name='Трудовые споры' linkId={'second'} />
      <Typography
        variant='h2'
        fontWeight='500'
        mb={{ xs: '32px', sm: '40px', md: '24px' }}
      >
        Трудовые споры
      </Typography>
      <CollapsedTextBlock>
        <>
          <Typography textAlign='justify'>
            Кадры действительно решают всё. Любой бизнес прекрасно осознаёт эту
            простую истину, и поэтому внимательно подходит к подбору персонала.
            Однако, ненадлежащее юридическое оформление отношений с работниками
            может привести к плачевным последствиям в виде больших штрафов,
            возмещений и иных издержек. Поэтому крайне важно юридически
            правильно оформлять отношения со своими сотрудниками.
            <br />
            <br />
            Ненадлежащее юридическое сопровождение между работником и
            работодателем чревато различными негативными последствиями. Хорошие
            кадры от этого пострадают, и не будут надлежащим образом исполнять
            свои обязанности, а вот плохие кадры, если неверно уволить их,
            смогут вернуться, и продолжат наносить ущерб бизнесу.
            <br />
            <br />
            В сфере трудовых отношений возникает огромное количество
            разнообразных споров. Специфика каждого спора вытекает в первую
            очередь из выполняемой человеком трудовой функции — для наиболее
            эффективной работы в конкретном деле всегда необходимо чёткое
            понимание специфики выполнения конкретной задачи, а иногда —
            понимание всего технологического процесса. Вовлечение в этот процесс
            всегда требует времени и больших усилий, и наши адвокаты, понимая
            важность вовлечения в процесс, всегда вкладывают максимум усилий,
            чтобы эффективно решить поставленную перед ними задачу.
            <br />
            <br />
            К сожалению, в сфере бизнеса трудовые споры — частое явление.
            Работодатели, желая сэкономить, идут на различные уловки, и не
            осознают, что в конечном итоге они сработают против них. Но и
            работники зачастую позволяют себе халатное отношение к выполняемой
            работе, невыполнение служебных обязанностей, по неизвестным причинам
            не являются на рабочее место — словом, нарушают условия трудового
            договора с работодателем. В обоих случаях всегда необходима
            профессиональная помощь, чтобы не преступить закон, не нарваться на
            лишнее внимание со стороны правоохранительных органов, и
            одновременно с этим решить возникшие разногласия.
            <br />
            <br />
            Адвокаты АБ «PRIM group» обладают большим багажом знаний в сфере
            трудового права. Мы всегда готовы оказать квалифицированную помощь
            как работникам, так и работодателям, которые столкнулись с
            трудностями в области трудового права.
          </Typography>
          <br />
          <Typography variant='h7' component='p' fontWeight='bold'>
            Мы оказываем различные услуги в сфере трудового права:
          </Typography>
          <Typography textAlign='justify'>
            <ul>
              <li>
                Консультации по вопросам трудового права, в том числе и трудовых
                конфликтов;
              </li>
              <li>
                Досудебное и судебное урегулирование споров как на стороне
                работодателя, так и на стороне работника;
              </li>
              <li>Разработка полного комплекта кадровой документации;</li>
              <li>Комплексное сопровождение трудовых отношений.</li>
            </ul>
          </Typography>
        </>
      </CollapsedTextBlock>
      <br />
      <br />
      <Anchor name='Наследственные споры' linkId={'third'} />
      <Typography
        variant='h2'
        fontWeight='500'
        mb={{ xs: '32px', sm: '40px', md: '24px' }}
      >
        Наследственные споры
      </Typography>
      <CollapsedTextBlock>
        <>
          <Typography textAlign='justify'>
            Наследственное право по праву признаётся одной из наиболее сложных с
            точки зрения содержания правового регулирования отраслей российского
            права. Любые, особенно спорные, наследственные дела всегда содержат
            в себе не только правовую, но и моральную составляющую поведения
            потенциальных наследодателей и наследников. Личные взаимоотношения и
            морально-нравственные качества в подобных делах играют свою
            существенную роль.
            <br />
            <br />
            Нужно чётко понимать, что наследство нечасто приносит исключительно
            финансовую выгоду. Принимая наследство, далеко не каждый наследник
            допускает мысль о том, что вместе с ним он принимает еще и долги
            умершего наследодателя. Долг такого рода может еще и не существовать
            на момент принятия наследства и не быть очевидным наследнику.
            <br />
            <br />
            Тенденциозным стало привлечение наследников к субсидиарной
            ответственности в делах о банкротстве за действия наследодателей,
            совершенные при жизни.
            <br />
            <br />
            С наследника могут также взыскать долг по сделке наследодателя,
            оспоренной уже после его смерти.
            <br />
            <br />
            Хотя по закону наследники и отвечают в пределах стоимости
            наследственной массы, порой им приходится расплачиваться за действия
            наследодателя собственным имуществом. Показательными являются случаи
            наследования долей в уставном капитале общества с ограниченной
            ответственностью, оцениваемых по данным бухгалтерского баланса. При
            этом баланс не всегда отражает действительное финансовое состояние
            компании и может быть завышен. В результате, в состав принятого
            наследства попадает компания-банкрот, стоимость которой существенно
            завышена. Именно в рамках этой стоимости наследник будет отвечать по
            долгам наследодателя, что может привести и к его собственному
            банкротству.
          </Typography>
          <br />
          <Typography variant='h7' component='p' fontWeight='bold'>
            Адвокаты АБ «PRIM group» оказывают следующие услуги:
          </Typography>
          <Typography textAlign='justify'>
            <ul>
              <li>
                Оказание консультационных услуг по вопросам налоговых
                правоотношений;
              </li>
              <li>Сопровождение процедуры принятия наследства;</li>
              <li>Оспаривание завещаний;</li>
              <li>Проведение аудита наследуемого имущества;</li>
              <li>
                Оценка рисков принятия наследства и конструирование решений,
                направленных на защиту от притязаний кредиторов наследодателя;
              </li>
              <li>
                Выработка алгоритмов управления бизнесом после смерти
                бенефициара;
              </li>
              <li>
                Защита интересов наследников в рамках исполнения договора
                доверительного управления наследственным имуществом.
              </li>
            </ul>
          </Typography>
        </>
      </CollapsedTextBlock>
    </Box>
  );
};

export default FamilyLowArticle;

{
  /* <Typography
        variant='h2'
        fontWeight='500'
        mb={{ xs: '32px', sm: '40px', md: '24px' }}
      >
        
      </Typography>
      <CollapsedTextBlock>
        <>
          <Typography>
            
            <br />
            <br />
            
            <br />
            <br />
            
            <br />
            <br />
            
          </Typography>
          <br />
          <Typography variant='h7' component='p' fontWeight='bold'>
            Адвокаты PRIM group оказывают следующие услуги:
          </Typography>
          <Typography>
            <ul>
              <li>
                
              </li>
              <li>
                
              </li>
              <li>
                
              </li>
              <li>
                
              </li>
              <li></li>
            </ul>
          </Typography>
        </>
      </CollapsedTextBlock> */
}
