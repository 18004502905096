import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PaperBlank from 'elements/UI/PaperBlank';
import userSvg from 'assets/icons/user.svg';

export const EmployeeItemVertical = ({ employee, onClick }) => {
  const ImgContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    border: 'none',
    overflow: 'hidden',

    paddingTop: '110%',
    '&:before': {
      content: '""',
      display: 'block',
      // backgroundColor: 'white',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,

      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: employee.photoPath ? 'cover' : '60%',
      backgroundColor: '#ebebeb',
      backgroundImage: employee.photoPath
        ? `url(${employee.photoPath})`
        : `url(${userSvg})`,
    },
  }));
  return (
    <PaperBlank onClick={onClick} sx={{ cursor: 'pointer' }}>
      <ImgContainer />
      <Box sx={{ p: { xs: '24px 12px', sm: '24px' } }}>
        <Typography variant='h6'>
          {[employee.firstName, employee.middleName, employee.lastName].join(
            ' '
          )}
        </Typography>
        <Typography mt='18px' variant='body1'>
          {employee.position}
        </Typography>
        <Typography
          mt='24px'
          variant='body1'
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: '6',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}
        >
          {employee.shortComment}
        </Typography>
      </Box>
    </PaperBlank>
  );
};

export const EmployeeItemHorizontal = ({
  employee,
  onClick = () => {},
  withContact = false,
  showPosition = false,

  heigth = '290px',
  noPhone = false,
}) => {
  const ImgContainer = styled('div')(({ theme }) => ({
    flexShrink: 0,
    flexBasis: '290px',
    maxWidth: '40%',
    position: 'relative',
    border: 'none',
    overflow: 'hidden',
    cursor: 'pointer',

    // paddingTop: '100%', //1:1
    '&:before': {
      content: '""',
      display: 'block',
      // backgroundColor: 'white',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,

      // backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: employee.photoPath ? 'cover' : '60%',
      backgroundColor: '#ebebeb',
      backgroundImage: employee.photoPath
        ? `url(${employee.photoPath})`
        : `url(${userSvg})`,
    },
  }));

  if (!employee) return <></>;
  return (
    <PaperBlank
      sx={
        {
          // cursor: 'pointer',
        }
      }
    >
      <Box
        sx={{
          display: 'flex',
          height: heigth,
        }}
      >
        <ImgContainer onClick={() => onClick(employee.id)} />
        <Box
          sx={{
            p: { xs: '24px 12px', sm: '24px' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant='h6'>
              {employee.firstName + ' ' + employee.lastName}
            </Typography>
            <Typography mt='18px' variant='body1'>
              {employee.position}
            </Typography>
            {!withContact ? (
              <Typography
                mt='24px'
                variant='body1'
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: '6',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                {employee.shortComment}
              </Typography>
            ) : null}

            {withContact ? (
              <>
                <Typography mt='24px' variant='body1' sx={{ color: '#104173' }}>
                  {employee.email}
                </Typography>
                {!noPhone && (
                  <Typography
                    mt='4px'
                    variant='body1'
                    sx={{ color: '#104173' }}
                  >
                    {employee.phone}
                  </Typography>
                )}
              </>
            ) : null}
          </Box>
          {/* TODO убрать */}
          <Box>
            <Typography
              mt='24px'
              variant='h6'
              component='span'
              sx={{
                marginTop: 0,
                cursor: 'pointer',
                position: 'relative',
                display: '-webkit-box',
                width: 'fit-content',
                // WebkitLineClamp: '6',
                // WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                '&::after': {
                  content: '""',
                  height: '2px',
                  right: '100%',
                  left: 0,
                  bottom: 0,
                  position: 'absolute',
                  backgroundColor: 'black',
                  transition: '.2s',
                },
                '&:hover, &.active': {
                  opacity: '100%',
                  '&::after': {
                    right: 0,
                  },
                },
              }}
              onClick={() => onClick(employee.id)}
            >
              Подробнее
            </Typography>
          </Box>
        </Box>
      </Box>
    </PaperBlank>
  );
};
