import { Hidden, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import LastNews from 'elements/components/LastNews';
import Breadcrumbs from 'elements/UI/Breadcrums';
import PageContainer from 'elements/UI/PageContainer';

const { default: ContactBlock } = require('elements/components/ContactBlock');
const { default: Container } = require('elements/UI/Container');

const ContactsPage = () => {
  const theme = useTheme();
  const greaterThanXl = useMediaQuery(theme.breakpoints.up('xl'));
  return (
    <PageContainer>
      <Container>
        <Breadcrumbs
          links={[{ name: 'Главная', to: 'main' }, { name: 'Контакты' }]}
          mb={{ xs: '30px', sm: '94px' }}
        />
        <ContactBlock />
        <Box sx={{ display: greaterThanXl ? 'block' : 'none' }}>
          <LastNews />
        </Box>
      </Container>
    </PageContainer>
  );
};

export default ContactsPage;
