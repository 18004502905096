import { Box, styled } from '@mui/system';
import Container from 'elements/UI/Container';
import SearchInput from 'elements/UI/SearchInput';
import { useCallback, useEffect, useState } from 'react';
import { mockTags } from 'mock';
import Header from '../Header';
import { Typography, useTheme } from '@mui/material';
import { Masonry } from '@mui/lab';
import CardNews from 'elements/UI/CardNews';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchArticleList } from 'store/actions/articleActions';
import {
  clearArticleSearchList,
  setSelectedTagForSearch,
} from 'store/slices/articleSlices';
import ArrowButton from 'elements/UI/ArrowButton';
import { useDebounce } from 'hooks/useDebounce';

const Wrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  backgroundColor: theme.palette.whiteBg.main,
  flexDirection: 'column',
  '& > div': {
    backgroundColor: theme.palette.whiteBg.main,
  },
}));

const Tags = styled('span')(({ theme, color }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 16px',
  border: '2px solid #000000',
  borderRadius: '12.5px',
  backgroundColor: color ?? 'transparent',
  '& > span': color
    ? {
        background: 'inherit',
        '-webkit-background-clip': 'text',
        'background-clip': 'text',
        color: 'transparent',
        filter: 'sepia(5) saturate(100) invert(1) grayscale(1) contrast(9)',
      }
    : {},
}));

const TagsContainer = styled(Box)(({ theme }) => ({
  marginTop: '32px',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '24px',
}));

const Search = ({ onClose }) => {
  const [count, setCount] = useState(6);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [unselectedTag, setUnselectedTags] = useState([]);
  const [name, setName] = useState('');
  const theme = useTheme();

  const {
    articleSearchList,
    articleSearchPageMetaData,
    isLoadingArticleSearchList,
  } = useSelector((state) => state.article);
  const { tags } = useSelector((state) => state.tag);
  const { selectedTagForSearch } = useSelector((state) => state.article);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSearchArticleList({ TagIds: [-1] }));

    return () => {
      dispatch(setSelectedTagForSearch(null));
      dispatch(clearArticleSearchList());
    };
  }, [dispatch]);

  useEffect(() => {
    setUnselectedTags(tags);
  }, [tags]);

  const getSearchCb = useCallback(() => {
    dispatch(
      getSearchArticleList(
        name || selectedTagIds.length
          ? { page: 1, Name: name, TagIds: selectedTagIds }
          : { TagIds: [-1] }
      )
    );
  }, [dispatch, name, selectedTagIds]);

  const loadMore = () => {
    dispatch(
      getSearchArticleList(
        {
          page: articleSearchPageMetaData?.pageNumber + 1,
          Name: name,
          TagIds: selectedTagIds,
        },
        false
      )
    );
  };

  const onSelectTag = (tag) => {
    setSelectedTags([...selectedTags, tag]);
    setUnselectedTags(unselectedTag.filter((el) => el.id !== tag.id));
    setSelectedTagIds([...selectedTagIds, tag.id]);
  };

  const onUnselectTag = (tag) => {
    setUnselectedTags([...unselectedTag, tag]);
    setSelectedTags(selectedTags.filter((el) => el.id !== tag.id));
    setSelectedTagIds(selectedTagIds.filter((el) => el !== tag.id));
  };

  useEffect(() => {
    selectedTagForSearch && onSelectTag(selectedTagForSearch);
  }, [selectedTagForSearch]);

  useDebounce(getSearchCb, 500);

  return (
    <Wrapper>
      <div>
        <Header searchIconClose={true} transparentStyle={true} />
        <Container
          sx={{ backgroundColor: theme.palette.whiteBg.main, pb: '32px' }}
        >
          <SearchInput value={name} onChange={(e) => setName(e.target.value)} />
          <TagsContainer>
            {selectedTags.map((el) => (
              <Tags
                key={el.id}
                onClick={() => onUnselectTag(el)}
                color={el.color}
              >
                <span>{el.name}</span>
              </Tags>
            ))}
            {unselectedTag
              .slice(0, Math.max(count - selectedTags.length, 0))
              .map((el) => (
                <Tags key={el.id} onClick={() => onSelectTag(el)} color={null}>
                  <span>{el.name}</span>
                </Tags>
              ))}
            {selectedTags.length + unselectedTag.length > count ? (
              <Tags onClick={() => setCount(tags.length)}>
                <span>Ещё...</span>
              </Tags>
            ) : null}
          </TagsContainer>
          <Box>
            {articleSearchPageMetaData?.totalItemCount === 0 ? (
              (name || selectedTagIds.length) && !isLoadingArticleSearchList ? (
                <Typography
                  variant='h4'
                  sx={{ mt: '48px', textAlign: 'center' }}
                >
                  По запросу ничего не найдено
                </Typography>
              ) : null
            ) : (
              <Masonry
                columns={{ xs: 1, sm: 2, md: 3 }}
                spacing={'24px'}
                sx={{
                  mt: '24px',
                  '& > div': {
                    width: {
                      xs: '100%',
                      sm: 'calc(50% - 24px * 1 / 2)',
                      md: 'calc(33.33% - 24px * 2 / 3)',
                    },
                  },
                }}
              >
                {articleSearchList.map((el, idx) => (
                  <CardNews news={el} key={idx} showDescription={false} />
                ))}
              </Masonry>
            )}
            {articleSearchPageMetaData?.hasNextPage ? (
              <Box sx={{ mt: '12px' }}>
                <ArrowButton
                  fullWidth={true}
                  withoutArrow={true}
                  onClick={loadMore}
                  disabled={isLoadingArticleSearchList}
                >
                  <Typography variant='h6Normal'>
                    Ещё <span>↓</span>
                  </Typography>
                </ArrowButton>
              </Box>
            ) : null}
          </Box>
        </Container>
      </div>
    </Wrapper>
  );
};

export default Search;
