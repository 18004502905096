import { Box, styled } from '@mui/system';
import CardNews from 'elements/UI/CardNews';
import Container from 'elements/UI/Container';
import { mockFeaturedNews } from 'mock';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMainArticleList } from 'store/actions/articleActions';

const FeaturedNews = () => {
  const NewsContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridGap: '24px',

    gridTemplateColumns: 'repeat(3, 1fr)',
    '& > div:nth-child(1)': {
      gridColumn: '1 / 3',
    },
    '& > div:nth-child(2)': {
      gridColumn: '1 / 3',
    },
    '& > div:nth-child(3)': {
      gridColumn: '3 / 4',
      gridRow: '1 / 3',
    },

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      '& > div:nth-child(1)': {
        gridColumn: '1 / 2',
        gridRow: '1 / 2',
      },
      '& > div:nth-child(2)': {
        gridColumn: '2 / 3',
        gridRow: '1 / 2',
      },
      '& > div:nth-child(3)': {
        gridColumn: '1 / 3',
        gridRow: '2 / 3',
      },
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      '& > div:nth-child(1)': {
        gridColumn: '1 / 3',
        gridRow: '1 / 2',
      },
      '& > div:nth-child(2)': {
        gridColumn: '1 / 3',
        gridRow: '2 / 3',
      },
      '& > div:nth-child(3)': {
        gridColumn: '1 / 3',
        gridRow: '3 / 4',
      },
    },
  }));

  const { mainArticleList } = useSelector((state) => state.article);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMainArticleList());
  }, [dispatch]);

  return (
    <NewsContainer>
      {mainArticleList?.map((el, idx) => (
        <div key={idx}>
          <CardNews news={el} isFeatureNews={true} horizontalImg={idx !== 2} />
        </div>
      ))}
    </NewsContainer>
  );
};

export default FeaturedNews;
