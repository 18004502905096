import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const CorporateConflictsArticle = () => {
  return (
    <Box
      sx={{
        '& ul > li:not(:last-of-type)': {
          marginBottom: '0.8em',
        },
      }}
    >
      <Typography
        variant='h3'
        fontWeight='500'
        mb={{ xs: '32px', sm: '40px', md: '24px' }}
      >
        Корпоративные конфликты
      </Typography>
      <Typography textAlign='justify'>
        Бизнес зачастую — явление коллективное. Любой предприниматель, и уж тем
        более собственник крупного бизнеса знает, что в одиночку построить
        бизнес, который будет показывать стабильно высокие результаты своего
        функционирования, и уследить за всеми аспектами его жизни просто
        невозможно. В итоге крупный бизнес насыщен соглашениями, определяющими
        порядок взаимодействия акционерами, и иными участниками предприятия.
        <br />
        <br />
        Вполне естественно, что жёсткая среда рыночной конкуренции — идеальная
        почва для ростков корпоративных конфликтов. Они возникают из
        корпоративных правоотношений, либо иных правоотношений, которые
        непосредственно связаны с деятельностью корпорации, и могут быть
        губительны даже для самого крепкого бизнеса.
        <br />
        <br />
        Адвокаты АБ «PRIM Group» не первое десятилетие участвуют в разрешении
        больших корпоративных конфликтов. Наша специализация — это разрешение
        корпоративных конфликтов, защита и осуществление корпоративных прав
        наших клиентов в сложных спорных обстоятельствах, разработка стратегии
        по защите от недружественных поглощений и противоправных попыток захвата
        корпоративного контроля и незаконных прав на акции или доли. Наша
        главная задача — сохранить бизнес и отношения между его участниками,
        сделать максимум для недопущения разрушения деловых связей и в конечном
        счёте обезопасить бизнес от ликвидации.
      </Typography>
      <br />
      <Typography variant='h7' component='p' fontWeight='bold'>
        Мы предоставляем следующие услуги:
      </Typography>
      <Typography>
        <ul>
          <li>
            Консультирование по вопросам разрешения корпоративных конфликтов;
          </li>
          <li>
            Обеспечение безопасного для интересантов конфликта его разрешения;
          </li>
          <li>
            Выработка оптимальных решений и стратегий ведения деятельности,
            направленных на урегулирование корпоративного конфликта;
          </li>
          <li>
            Сопровождение переговоров между участниками корпоративного
            конфликта, разработка условий мирного разрешения спора;
          </li>
          <li>Иные услуги.</li>
        </ul>
      </Typography>

      <Typography
        variant='h3'
        fontWeight='500'
        mb={{ xs: '32px', sm: '40px', md: '24px' }}
      >
        Защита прав инвесторов и акционеров
      </Typography>
      <Typography textAlign='justify'>
        Инвестиционная деятельность — своего рода искусство, которое имеет
        огромное множество нюансов. Для принятия того или иного инвестиционного
        решения проводится фундаментальный анализ, исследуется огромное
        множество различных бенчмарков — словом, эта деятельность обширна и
        многогранна.
        <br />
        <br />
        Российская Федерация стремится к тому, чтобы привлекать не только
        российских, но и иностранных инвесторов. При этом нередки ситуации
        незаконного изъятия активов или собственности инвесторов, несоблюдения
        государством или государственными компаниями договорных обязательств,
        включая несвоевременную выплату долгов или нарушение условий соглашений,
        и других негативно сказывающихся на инвестиционном климате ситуаций. Для
        защиты как российских, так и иностранных инвесторов, в России действует
        специализированное законодательное регулирование, которое направлено на
        защиту прав и законных интересов инвесторов. Важно понимать, что это
        законодательство полно нюансов и тонкостей, и для эффективной защиты
        своих интересов необходимо своевременно обратиться к профессиональному
        адвокату, который не только является высококлассным специалистом с
        большим опытом, но и сам понимает, как работает экономический механизм
        инвестиционной деятельности.
        <br />
        <br />
        Адвокаты АБ «PRIM сопровождения инвестиционных проектов на территории
        всей страны. Нам доверяют свои деньги — это главный показатель высокого
        качества нашей работы.
      </Typography>
      <br />
      <Typography variant='h7' component='p' fontWeight='bold'>
        Мы оказываем следующие услуги:
      </Typography>
      <Typography>
        <ul>
          <li>
            Консультирование по правовым вопросам реализации инвестиционных
            проектов;
          </li>
          <li>
            Представление интересов инвесторов и акционеров на переговорах с
            контрагентами, совладельцами бизнеса, и другими участниками
            предпринимательской деятельности;
          </li>
          <li>
            Защита интересов инвесторов и акционеров в государственных органах и
            судах.
          </li>
        </ul>
      </Typography>

      <Typography
        variant='h3'
        fontWeight='500'
        mb={{ xs: '32px', sm: '40px', md: '24px' }}
      >
        M&A
      </Typography>
      <Typography textAlign='justify'>
        Сделки слияния и поглощения (M&A - Mergers and Acquisitions)
        представляют собой процесс объединения двух или более компаний, где одна
        компания поглощает или покупает другую компанию. Целью M&A может быть
        улучшение позиции на рынке, расширение бизнеса, сокращение конкуренции,
        получение новых технологий или доступ к новым рынкам.
        <br />
        <br />
        Сделки M&A могут быть различными по своей природе, включая слияния
        компаний, при которых две (или более) компании объединяются для создания
        новой компании, а также поглощение, при котором одна компания
        приобретает другую и интегрирует ее в свою деятельность.
        <br />
        <br />
        Процесс M&A включает в себя множество различных действий: оценку
        компании, проведение переговоров о стоимости и условиях сделки,
        финансовый анализ предполагаемой сделки, а также прохождение
        соответствующих регулирующих и юридических процедур.
        <br />
        <br />
        Сделки M&A могут иметь важное значение для компаний, поскольку любые
        подобные сделки могут существенно изменить стратегию, размеры и
        направление их деятельности. Однако, такие сделки также могут быть
        сложными и рискованными, требуя тщательного анализа, планирования и
        выполнения для успешного завершения. Как показывает практика, именно
        юридическая составляющая этого процесса в силу своей сложности
        представляет наибольшие сложности для предпринимателей. Именно поэтому в
        этом процессе просто необходима помощь квалифицированного юриста,
        готового взять все связанные с юридической стороной сделки вопросы на
        себя. Адвокаты АБ «PRIM Group» имеют обширный опыт проведения сделок по
        слиянию и поглощению компаний. Предложенные нами решения всегда
        позволяют точно и максимально выгодно для всех участников сделки
        удовлетворить интересы доверителей, и защитить их при возникновении
        любых разногласий.
      </Typography>
      <br />
      <Typography variant='h7' component='p' fontWeight='bold'>
        Мы оказываем следующие услуги:
      </Typography>
      <Typography>
        <ul>
          <li>
            Консультирование бизнеса по вопросам юридического сопровождения
            сделок слияния и поглощения;
          </li>
          <li>Комплексное сопровождение процедуры проведения сделки;</li>
          <li>
            Защита интересов доверителя в суде при нарушении его интересов в
            результате проведения слияния или поглощения.
          </li>
        </ul>
      </Typography>
    </Box>
  );
};

export default CorporateConflictsArticle;
