import { Grid, Typography } from '@mui/material';
import Container from 'elements/UI/Container';
import { Link, useLocation } from 'react-router-dom';
import FooterBlog from './FooterBlog';
import FooterContacts from './FooterContacts';
import FooterMenu from './FooterMenu';
import LogoPrimGroup from '../Logo';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';

const SmallLink = ({ name, to }) => (
  <Typography
    variant='body2'
    component={Link}
    to={to}
    sx={{ textDecoration: 'none', color: 'black' }}
  >
    {name}
  </Typography>
);

const Footer = () => {
  const { pathname } = useLocation();
  const [showBlog, setShowBlog] = useState(true);

  useEffect(() => {
    let page = pathname.split('/')?.[1];
    setShowBlog(page !== 'article' && page !== 'blog');
  }, [pathname]);

  return (
    <Container mt='80px'>
      <Grid container pb='64px' alignItems={{ xs: 'center', sm: 'start' }}>
        <Grid item xs={4} sm={6} lg={4} order={{ xs: 1, lg: 0 }}>
          <Box mb='32px'>
            <LogoPrimGroup />
          </Box>

          <FooterMenu />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          alignSelf='center'
          order={{ xs: 0, lg: 1 }}
          mb={{ xs: '80px', lg: 0 }}
        >
          {showBlog ? (
            <FooterBlog />
          ) : (
            <Typography variant='body1'>
              Использование всех текстовых материалов без изменений в
              некоммерческих целях разрешается со ссылкой на Prim Group.
              <br />
              Все аудиовизуальные произведения являются собственностью своих
              авторов и правообладателей и используются только в образовательных
              и информационных целях.
              <br />
              Если вы являетесь собственником того или иного произведения и не
              согласны с его размещением на нашем сайте, пожалуйста, напишите на{' '}
              <u>news@prim.group</u>
              <br />
              Связь с редакцией: <u>info@prim.group</u>
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          lg={4}
          order={{ xs: 2, lg: 2 }}
          display={{ xs: 'none', sm: 'block' }}
        >
          <FooterContacts />
        </Grid>
        <Grid item xs={8} sm={12} order={{ xs: 3, lg: 3 }}>
          <Grid
            container
            justifyContent='space-between'
            pt={{ xs: '64px', sm: '64px' }}
            spacing={{ xs: '24px' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Grid item>
              <Grid
                container
                spacing={{ xs: '24px', lg: '48px' }}
                flexDirection={{ xs: 'column', sm: 'row' }}
              >
                <Grid item textAlign={{ xs: 'end', sm: 'start' }}>
                  <SmallLink name='Карта сайта' to='/sitemap' />
                </Grid>
                <Grid item textAlign={{ xs: 'end', sm: 'start' }}>
                  <SmallLink name='Правовая информация' to='/sitemap' />
                </Grid>
                <Grid item textAlign={{ xs: 'end', sm: 'start' }}>
                  <SmallLink
                    name='Политика обработки персональных данных'
                    to='/sitemap'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item textAlign={{ xs: 'end', sm: 'start' }}>
              <Typography variant='body2'>
                © 2023 Адвокатское Бюро "Юридическая группа Prim Group"
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
