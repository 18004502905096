import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Container from 'elements/UI/Container';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Tag from 'elements/UI/Tag';
import { object, string } from 'yup';
import filesAPIRoutes from 'apiRoutes/files';
import { createOrUpdateMember } from 'store/actions/teamActions';

const schema = object().shape({
  firstName: string().required('Имя обязательно'),
  lastName: string().required('Фамилия обязательна'),
});

export const AddTeamModal = ({ onClose, member = null, memberId = null }) => {
  const [id, setId] = useState(null);
  const [firstName, setFirstame] = useState('');
  const [middleName, setMiddlename] = useState('');
  const [lastName, setLasname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [position, setPosition] = useState('');
  const [degree, setDegree] = useState('');
  const [description, setDescription] = useState('');
  const [shortComment, setShortComment] = useState('');
  const [orderNumber, setOrderNumber] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [articlePhoto, setArticlePhoto] = useState(null);
  const [imgLoading, setImgLoading] = useState(null);

  const [validationErrors, setValidationErrors] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setId(memberId);
    if (member) {
      setFirstame(member.firstName);
      setMiddlename(member.middleName);
      setLasname(member.lastName);
      setEmail(member.email);
      setPhone(member.phone);
      setPosition(member.position);
      setDegree(member.degree);
      setOrderNumber(member.orderNumber);
      setDescription(member.description);
      setShortComment(member.shortComment);
      setPhoto(member.photo);
      setArticlePhoto(member.articlePhoto);
    }
  }, [member, memberId]);

  const handleClose = () => {
    setId(null);
    setFirstame('');
    setMiddlename('');
    setLasname('');
    setEmail('');
    setPhone('');
    setPosition('');
    setDegree('');
    setOrderNumber(null);
    setDescription('');
    setShortComment('');
    setPhoto(null);
    setArticlePhoto(null);

    setValidationErrors('');

    onClose();
  };

  const handleSubmit = async () => {
    let form = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      email: email,
      phone: phone,
      position: position,
      degree: degree,
      orderNumber: orderNumber,
      description: description,
      shortComment: shortComment,
      photo: photo,
      articlePhoto: articlePhoto,
    };
    if (id) {
      form = { ...form, id: id };
    }
    try {
      await schema.validate(form);
      setValidationErrors('');
      const { ok } = await dispatch(createOrUpdateMember(form));
      if (ok) {
        handleClose();
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrors(error.errors[0]);
      }
      throw error;
    }
  };

  const handleUploadCover = async (e, setFunc) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('uploadFile', file);

    try {
      setImgLoading(true);
      const response = await filesAPIRoutes.upload(formData);
      setImgLoading(false);
      let imageUrl = response?.data?.entity;
      if (imageUrl) {
        setFunc(imageUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveCover = async (img, setFunc) => {
    if (member) {
      setFunc(null);
    } else {
      try {
        let form = {
          id: img.id,
          secretKey: img.secretKey,
        };
        await filesAPIRoutes.remove(form);
        setFunc(null);

        // let imageUrl = response?.data?.entity;
        // if (imageUrl) {
        //   setCoverImg(imageUrl);
        // }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Container>
        <Grid
          mt={2}
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography variant='h3'>Сотрудник бюро</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon stroke='black' />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing='24px' mt='24px'>
          <Grid item xs={6}>
            <TextField
              label='Имя'
              variant='outlined'
              fullWidth
              value={firstName}
              onChange={(e) => setFirstame(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Фамилия'
              variant='outlined'
              fullWidth
              value={lastName}
              onChange={(e) => setLasname(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Отчество'
              variant='outlined'
              fullWidth
              value={middleName}
              onChange={(e) => setMiddlename(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Почта'
              variant='outlined'
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Телефон'
              variant='outlined'
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Позиция'
              variant='outlined'
              fullWidth
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Образование'
              variant='outlined'
              fullWidth
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Порядковый номер в выдаче'
              variant='outlined'
              fullWidth
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              label='Описание'
              variant='outlined'
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              label='Короткий комментарий'
              variant='outlined'
              fullWidth
              value={shortComment}
              onChange={(e) => setShortComment(e.target.value)}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            mb: '24px',
            mt: '24px',
          }}
        >
          <input
            alt='Cover'
            id='contained-button-file'
            style={{ display: 'none' }}
            type='file'
            accept='image/*'
            // value={coverImg}
            onChange={(e) => handleUploadCover(e, setPhoto)}
          />
          <Typography variant='h6'>Фото работника</Typography>
          {photo ? (
            <>
              <img
                alt='cover img'
                src={photo?.path}
                style={{ maxHeight: '100px', maxWidth: '100%' }}
              />
              <Button
                variant='outlined'
                component='span'
                onClick={() => handleRemoveCover(photo, setPhoto)}
              >
                Удалить
              </Button>
            </>
          ) : (
            <label htmlFor='contained-button-file'>
              <Button variant='outlined' component='span' disabled={imgLoading}>
                Загрузить
              </Button>
            </label>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            mb: '24px',
            mt: '24px',
          }}
        >
          <input
            alt='Cover'
            id='contained-button-file-article'
            style={{ display: 'none' }}
            type='file'
            accept='image/*'
            // value={coverImg}
            onChange={(e) => handleUploadCover(e, setArticlePhoto)}
          />
          <Typography variant='h6'>Фото работника для статьи</Typography>
          {articlePhoto ? (
            <>
              <img
                alt='cover img'
                src={articlePhoto?.path}
                style={{ maxHeight: '100px', maxWidth: '100%' }}
              />
              <Button
                variant='outlined'
                component='span'
                onClick={() => handleRemoveCover(articlePhoto, setArticlePhoto)}
              >
                Удалить
              </Button>
            </>
          ) : (
            <label htmlFor='contained-button-file-article'>
              <Button variant='outlined' component='span' disabled={imgLoading}>
                Загрузить
              </Button>
            </label>
          )}
        </Box>
        <Typography
          sx={{ color: '#ca0000', mb: '28px', mt: '8px' }}
          variant='subtitle1'
        >
          {validationErrors}
        </Typography>
        <Box
          mt='32px'
          pb='32px'
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={handleSubmit}
        >
          <Button fullWidth variant='outlined'>
            Сохранить
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
