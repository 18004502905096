import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { routes } from 'routes';
import { getTags } from 'store/actions/tagActions';
import { getTeam } from 'store/actions/teamActions';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    const fietching = async () => {
      await dispatch(getTags());
      await dispatch(getTeam());
    };
    fietching();
  }, [dispatch]);

  const rootRoutes = useRoutes(routes);

  return <>{rootRoutes}</>;
};

export default App;
