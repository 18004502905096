import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PaperBlank from '../PaperBlank';
import StrokeWrapper from '../StrokeWrapper';
import Tag from '../Tag';
import { ReactComponent as LinkIcon } from 'assets/icons/external-link.svg';
import { useNavigate } from 'react-router-dom';
import { formattUtcTime } from 'utils/formattUtcTime';
import { articleTypesModel } from 'pages/Blog/models';

import userSvg from 'assets/icons/user.svg';

const CardNews = ({
  news,
  showDescription = true,
  isFeatureNews = false,
  horizontalImg = false,
  dltBtn = false,
  hideImg = false,
  onDelete = () => {},
}) => {
  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/article/${id}`);
  };
  return (
    <>
      <StrokeWrapper
        color={
          news?.type === articleTypesModel.external.key
            ? '#2E23E0'
            : news?.tagDtos?.[0]?.color || '#000000'
        }
      >
        <Grid
          container
          spacing='4px'
          direction={{ xs: 'column', md: horizontalImg ? 'row' : 'column' }}
        >
          {news?.coverPath && !hideImg ? (
            <Grid
              item
              xs={12}
              md={horizontalImg ? 5 : 12}
              sx={{
                '& div': {
                  width: '100%',
                  height: {
                    xs: '250px',
                    md: horizontalImg ? '100%' : '250px',
                  },
                  borderRadius: '36px',

                  backgroundImage: `url(${news?.coverPath})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                },
              }}
            >
              <Box></Box>
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            sx={{ maxWidth: '100% !important' }}
            md={horizontalImg && news?.coverPath && !hideImg ? 7 : 12}
          >
            <PaperBlank
              bgColor={
                news?.type === articleTypesModel.event.key ? '#DAE1ED' : null
              }
            >
              <Box sx={{ p: '24px' }}>
                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  <Tag>{formattUtcTime(news?.createdDateTimeUtc)}</Tag>
                  {news?.tagDtos?.[0] ? (
                    <Tag color={news?.tagDtos?.[0]?.color}>
                      {news?.tagDtos?.[0]?.name}
                    </Tag>
                  ) : null}
                  {news?.sourceName ? (
                    <Tag color={'#2E23E0'}>
                      {news?.sourceName}
                      {'  '}
                      <LinkIcon />
                    </Tag>
                  ) : null}
                  {dltBtn ? (
                    <Button size='small' variant='outlined' onClick={onDelete}>
                      Удалить
                    </Button>
                  ) : null}
                </Box>

                <Typography
                  variant={isFeatureNews ? 'h6' : 'h7'}
                  sx={{
                    mt: '16px',
                    display: 'block',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                    color: 'black',
                  }}
                  component={news?.link ? 'a' : 'p'}
                  href={news?.link}
                  target={'_blank'}
                  onClick={!news?.link ? () => handleClick(news.id) : null}
                >
                  {news?.title}
                </Typography>
                {showDescription ? (
                  <Typography sx={{ mt: '16px' }}>
                    {news?.description}
                  </Typography>
                ) : null}
                {news?.memberCard && (
                  <Box sx={{ mt: '18px' }}>
                    <Box
                      sx={{
                        display: 'inline-block',
                        width: '1.2em',
                        height: '1.2em',
                        borderRadius: '1em',
                        verticalAlign: 'middle',
                        mr: '8px',
                        backgroundImage: `url(${
                          news.memberCard.photoPath ?? userSvg
                        })`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    />
                    <Typography component='span'>
                      {news.memberCard.firstName} {news.memberCard.lastName}
                    </Typography>
                  </Box>
                )}
              </Box>
            </PaperBlank>
          </Grid>
        </Grid>
      </StrokeWrapper>
    </>
  );
};

export default CardNews;
