import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import ArrowButton from 'elements/UI/ArrowButton';
import Container from 'elements/UI/Container';
import PageContainer from 'elements/UI/PageContainer';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { emailRemove, getEmails } from 'store/actions/emailActions';

const EmailPage = () => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const { isAuth } = useAuth();

  const { emails } = useSelector((state) => state.email);

  useEffect(() => {
    if (isAuth) {
      dispatch(getEmails(page));
    }
  }, [dispatch, isAuth, page]);

  useEffect(() => {
    if (emails?.pageItems?.length === 0) {
      setPage((prevState) => (prevState !== 1 ? prevState - 1 : prevState));
    }
  }, [emails?.pageItems?.length]);

  const onRemove = (id) => {
    dispatch(
      emailRemove(
        { id: id },
        emails?.pageItems?.length > 1 || page === 1 ? page : page - 1
      )
    );
  };

  return (
    <PageContainer>
      <Container>
        {isAuth ? (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Почта</TableCell>
                    <TableCell align='right'>Действие</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emails?.pageItems?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component='th' scope='row'>
                        {row.email}
                      </TableCell>
                      <TableCell align='right'>
                        <Button
                          variant='outlined'
                          onClick={() => onRemove(row.id)}
                        >
                          Удалить
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                mt: '30px',
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Typography>
                Страница {emails?.pagedMetaData?.pageNumber} из{' '}
                {emails?.pagedMetaData?.pageCount}
              </Typography>
              <Button
                variant='outlined'
                disabled={!emails?.pagedMetaData?.hasPreviousPage}
                onClick={() => setPage((prevState) => prevState - 1)}
              >
                Назад
              </Button>
              <Button
                variant='outlined'
                disabled={!emails?.pagedMetaData?.hasNextPage}
                onClick={() => setPage((prevState) => prevState + 1)}
              >
                Далее
              </Button>
            </Box>
          </>
        ) : null}
      </Container>
    </PageContainer>
  );
};

export default EmailPage;
