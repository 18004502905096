import { useCallback, useEffect, useState } from 'react';

export const useAuth = () => {
  const [isAuth, setIsAuth] = useState(false);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setIsAuth(false);
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')?.length) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);
  return { isAuth, logout };
};
