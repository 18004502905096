import { Grid, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import ArrowButton from 'elements/UI/ArrowButton';
import Container from 'elements/UI/Container';
import Input from 'elements/UI/Input';

import planeGif from 'assets/img/gif/Comp 1.gif';
import { useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';
import { createEmail } from 'store/actions/emailActions';
import { useLocation } from 'react-router-dom';

const SubscribeBlockContainer = styled('div')(({ theme }) => ({
  borderRadius: '36px',
  paddingBottom: 0,
  margin: '0 -6px',
  padding: '64px 12px 24px',
  position: 'relative',
  backgroundColor: theme.palette.blueBg.main,
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    margin: '0 -16px',
    padding: '64px 32px 32px',
  },
  [theme.breakpoints.up('md')]: {
    margin: '0 -40px',
    padding: '64px 80px',
  },
  '& > img': {
    position: 'absolute',
    width: '500px',
    pointerEvents: 'none',
    right: '-30px',
    top: '-150px',
    zIndex: 10,
    [theme.breakpoints.down('lg')]: {
      top: '-90px',
      right: '30px',
      width: '300px',
    },
  },
}));

const schema = object().shape({
  email: string().required('Почта обязательна').email('Неверный формат почты'),
});

const SubscribeBlock = () => {
  const [email, setEmail] = useState('');
  const [validationErrors, setValidationErrors] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { pathname } = useLocation();
  useEffect(() => {
    setValidationErrors('');
    setSuccessMessage('');
    setEmail('');
  }, [pathname]);

  const dispatch = useDispatch();

  const onSubsribe = async () => {
    let form = { email: email };
    try {
      setValidationErrors('');
      setSuccessMessage('');
      await schema.validate(form);

      const { ok } = await dispatch(createEmail(form));
      if (ok) {
        setSuccessMessage('Вы успешно подписались');
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrors(error.errors[0]);
      }
      throw error;
    }
  };

  return (
    <Container>
      <SubscribeBlockContainer>
        <img src={planeGif} />
        <Typography variant='h3' mb='48px'>
          Следите за нашими последними обновлениями:
        </Typography>
        <Grid container spacing={{ xs: '20px', md: '60px' }}>
          <Grid item xs={12} md={6}>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            <Typography
              sx={{ color: '#ca0000', mb: '28px', mt: '8px' }}
              variant='subtitle1'
            >
              {validationErrors}
            </Typography>
            <Typography
              sx={{ color: '#029e43', mb: '28px', mt: '8px' }}
              variant='subtitle1'
            >
              {successMessage}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'start' },
            }}
          >
            <div>
              <ArrowButton onClick={onSubsribe}>Подписаться</ArrowButton>
            </div>
          </Grid>
        </Grid>
      </SubscribeBlockContainer>
    </Container>
  );
};

export default SubscribeBlock;
