import { useTheme } from '@emotion/react';
import {
  Button,
  Collapse,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import OpenMoreButton from 'elements/UI/OpenMoreButton';
import PaperBlank from 'elements/UI/PaperBlank';
import { useState } from 'react';
import { materialTypeIconModel, typesModel } from '../models';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { useDispatch } from 'react-redux';
import { removeMemberContent } from 'store/actions/teamActions';

const Item = ({ el, isAuth, onDelete }) => {
  return (
    <Grid item xs={12} sm={6} xl={4}>
      <PaperBlank
        sx={{
          p: '24px 38px',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: '38px',
          cursor: 'pointer',
          textDecoration: 'none',
          color: 'black',
        }}
        component={'a'}
        href={el.type !== typesModel.file.key ? el.link : el.viewLink}
        target={'_blank'}
        {...(el.type === typesModel.file.key ? { download: 'download' } : null)}
      >
        <Box
          sx={{
            '& svg': { height: '2em', width: 'auto' },
            height: 'fit-content',
          }}
        >
          {materialTypeIconModel[el.type]}
        </Box>
        <Typography variant='body1' sx={{ flexGrow: '1' }}>
          {el.title}
        </Typography>
        {isAuth ? (
          <Button
            size='small'
            variant='outlined'
            onClick={(e) => {
              e.preventDefault();
              onDelete();
            }}
          >
            Удалить
          </Button>
        ) : null}
      </PaperBlank>
    </Grid>
  );
};

const MaterialsTab = ({ content, isAuth, memberId }) => {
  const [contentToDelete, setContentToDelete] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [openCollapse, setOpenCollapse] = useState(false);
  const theme = useTheme();
  const greaterThanMmd = useMediaQuery(theme.breakpoints.up('mmd'));

  const dispatch = useDispatch();

  const onRemove = async (tag) => {
    setContentToDelete(tag);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async (el) => {
    let form = { id: el?.id, memberId: memberId };
    try {
      const { ok } = await dispatch(removeMemberContent(form));
      if (ok) {
        setIsDeleteModalOpen(false);
        setContentToDelete(null);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      {content.length > 0 ? (
        <>
          <Grid
            container
            columnSpacing={{ xs: '12px', sm: '32px', lg: '40px' }}
            rowSpacing={{ xs: '12px', sm: '24px', lg: '32px' }}
            justifyContent='start'
            mb={{ xs: '12px', sm: '24px', lg: '32px' }}
          >
            {content
              .slice(0, greaterThanMmd ? content.length : 4)
              .map((el, idx) => (
                <Item
                  el={el}
                  key={idx}
                  isAuth={isAuth}
                  onDelete={() => onRemove(el)}
                />
              ))}
          </Grid>

          {!greaterThanMmd && content.length > 4 ? (
            <>
              <Collapse in={openCollapse}>
                <Grid
                  container
                  columnSpacing={{ xs: '12px', sm: '32px', lg: '40px' }}
                  rowSpacing={{ xs: '12px', sm: '24px', lg: '32px' }}
                  justifyContent='start'
                >
                  {content.slice(4, content.length).map((el, idx) => (
                    <Item
                      el={el}
                      key={idx}
                      isAuth={isAuth}
                      onDelete={() => onRemove(el)}
                    />
                  ))}
                </Grid>
              </Collapse>
              <Box
                mt={{ xs: '18px', sm: '24px', lg: '32px' }}
                display='flex'
                justifyContent='center'
              >
                <OpenMoreButton
                  onClick={() => setOpenCollapse((prev) => !prev)}
                  isOpen={openCollapse}
                  closenText='Показать полностью'
                />
              </Box>
            </>
          ) : null}
        </>
      ) : (
        <Typography textAlign='center' variant='h6'>
          Здесь пока ничего нет...
        </Typography>
      )}
      {isAuth ? (
        <ConfirmModal
          isOpen={isDeleteModalOpen}
          onSubmit={() => handleDelete(contentToDelete)}
          onClose={() => {
            setIsDeleteModalOpen(false);
            setContentToDelete(null);
          }}
        />
      ) : null}
    </>
  );
};

export default MaterialsTab;
