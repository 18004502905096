import { Box, Typography } from '@mui/material';
import ArrowButton from 'elements/UI/ArrowButton';
import Container from 'elements/UI/Container';
import PageContainer from 'elements/UI/PageContainer';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <PageContainer>
      <Container>
        <Box
          sx={{
            minHeight: '50vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant='h2' sx={{ textAlign: 'center', mb: '40px' }}>
            Страница не найдена
          </Typography>
          <ArrowButton onClick={() => navigate('/')}>На главную</ArrowButton>
        </Box>
      </Container>
    </PageContainer>
  );
};

export default NotFoundPage;
