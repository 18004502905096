import {
  Box,
  Button,
  Drawer,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { EmployeeItemHorizontal } from 'elements/components/EmployeeItem';
import ArrowButton from 'elements/UI/ArrowButton';
import Breadcrumbs from 'elements/UI/Breadcrums';
import Container from 'elements/UI/Container';
import { practiceArticlesModel } from './model';
import PageContainer from 'elements/UI/PageContainer';
import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import CardNews from 'elements/UI/CardNews';
import Anchor from 'elements/UI/Anchor';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'hooks/useAuth';
import { clearPracticeInfo } from 'store/slices/practiceSlices';
import {
  getPracticeAllMembers,
  getPracticeInfo,
} from 'store/actions/practiceActions';
import { EditPracticeMembersModal } from './components/editPracticeMembersModal';
import { updateNavbarEvent } from 'store/slices/uiSlices';
import Loader from 'elements/UI/Loader';

const PracticePage = () => {
  const [isEditMembersModalOpen, setIsEditMembersModalOpen] = useState(false);
  const [showCount, setShowCount] = useState(2);
  const [isShowAllMembers, setIsShowAllMembers] = useState(false);

  const navigate = useNavigate();
  let { nameId } = useParams();
  let anchor = decodeURI(window.location?.hash)?.slice(1);
  const dispatch = useDispatch();
  const {
    practiceInfo,
    isLoadingPracticeInfo,
    practiceInfoError,
    allMembers,
    isLoadingAllMembers,
    errorAllMembers,
    isLoadingAddingMember,
    createError,
  } = useSelector((state) => state.practice);

  const { isAuth } = useAuth();

  const theme = useTheme();
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (greaterThanMd) {
      setShowCount(3);
    } else {
      setShowCount(2);
    }
  }, [greaterThanMd]);

  useEffect(() => {
    if (practiceInfoError) {
      navigate('/404', { replace: true });
    }
  }, [practiceInfoError]);

  useEffect(() => {
    dispatch(getPracticeInfo({ practiceName: decodeURI(nameId) }));

    return () => {
      dispatch(clearPracticeInfo());
    };
  }, [dispatch, nameId]);

  useEffect(() => {
    if (practiceInfo?.practiceId)
      dispatch(getPracticeAllMembers({ practiceId: practiceInfo?.practiceId }));
  }, [dispatch, practiceInfo]);

  useEffect(() => {
    dispatch(updateNavbarEvent());
  }, [dispatch, practiceInfo]);

  const isShowMember = useMemo(() => {
    return (
      practiceInfo?.shortListMemberCards?.length &&
      !practiceArticlesModel.find((el) => el.name === nameId).customPage
    );
  }, [nameId, practiceInfo?.shortListMemberCards?.length]);

  return (
    <PageContainer>
      <Container>
        {isLoadingPracticeInfo ? (
          <Loader />
        ) : (
          <>
            <Breadcrumbs
              links={[
                { name: 'Главная', to: 'main' },
                { name: 'Практики', to: 'practices' },
                { name: nameId },
              ]}
              mb='80px'
            />

            <Grid container spacing={'48px'}>
              <Grid item xs={12} lg={6} order={{ xs: 1, lg: 0 }}>
                {isAuth ? (
                  <>
                    <Button
                      variant='outlined'
                      onClick={() => setIsEditMembersModalOpen(true)}
                      sx={{ mb: '16px' }}
                    >
                      Редактировать контакты для связи
                    </Button>
                    <Drawer
                      anchor='bottom'
                      open={isEditMembersModalOpen}
                      onClose={() => setIsEditMembersModalOpen(false)}
                    >
                      <EditPracticeMembersModal
                        onClose={() => setIsEditMembersModalOpen(false)}
                        nameId={nameId}
                      />
                    </Drawer>
                  </>
                ) : null}
                {isShowMember ? (
                  <>
                    <Anchor name='контакты' linkId={'contact-anchor'} />
                    <Typography variant='h3' mb={{ xs: '32px', xl: '48px' }}>
                      Контакты для связи
                    </Typography>
                    {[
                      ...(isShowAllMembers
                        ? allMembers?.memberCards
                        : practiceInfo?.shortListMemberCards),
                    ]?.map((el, idx) => (
                      <Box key={idx} mb={{ xs: '12px', xl: '20px' }}>
                        <EmployeeItemHorizontal
                          employee={el}
                          withContact={true}
                          onClick={() => navigate(`/team/${el.id}`)}
                          showPosition={true}
                        />
                      </Box>
                    ))}
                  </>
                ) : null}

                {practiceInfo?.hasMoreMembers &&
                !isShowAllMembers &&
                isShowMember ? (
                  <Grid
                    container
                    mt='48px'
                    justifyContent={{ lg: 'start', xs: 'center' }}
                  >
                    <Grid item>
                      <ArrowButton onClick={() => setIsShowAllMembers(true)}>
                        Показать полностью
                      </ArrowButton>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={12} lg={isShowMember ? 6 : 12}>
                {!practiceArticlesModel.find((el) => el.name === nameId)
                  .isSeveralBlocks && (
                  <Typography
                    variant='h2'
                    fontWeight='500'
                    mb={{ xs: '32px', sm: '40px', md: '24px' }}
                  >
                    {nameId}
                  </Typography>
                )}
                {practiceArticlesModel
                  .find((el) => el.name === nameId)
                  ?.component({
                    memberCards: allMembers?.memberCards,
                    toPathAnchor: anchor,
                  })}
              </Grid>
            </Grid>

            {practiceInfo?.lastArticles?.length ? (
              <>
                <Anchor name='публикации' linkId={'last-news-anchor'} />
                <Typography
                  variant='h3'
                  mt='32px'
                  mb={{ xs: '32px', xl: '48px' }}
                >
                  Последние новости по этой теме
                </Typography>
                <Box sx={{ transition: 'height 0.3s' }}>
                  <Grid container spacing='40px'>
                    {practiceInfo?.lastArticles
                      ?.slice(0, showCount)
                      .map((el, idx) => (
                        <Grid item key={idx} xs={12} sm={6} md={4}>
                          <CardNews news={el} />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </>
            ) : null}
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default PracticePage;
