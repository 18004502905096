import { createTheme } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';

import GolosRegular from 'assets/font/GolosText-Regular.ttf';

const customBreakpointValues = {
  values: {
    xs: 0,
    ssm: 520,
    sm: 770,
    mmd: 1024,
    md: 1200,
    lg: 1400,
    xl: 1650,
  },
  keys: ['xs', 'ssm', 'sm', 'mmd', 'md', 'lg', 'xl'],
};

const breakpoints = createBreakpoints({ ...customBreakpointValues });

export const theme = createTheme({
  breakpoints: { ...breakpoints },
  palette: {
    blueBg: {
      main: '#DAE1ED',
      contrastText: '#000000',
    },
    whiteBg: {
      main: '#F0F0F0',
      contrastText: '#000000',
    },
    headerBtnBg: {
      main: '#104173',
    },
  },
  typography: {
    fontFamily: `'Golos Text', "Roboto", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '36px',
      lineHeight: '44px',
      // textShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
      [breakpoints.up('sm')]: {
        fontSize: '48px',
        lineHeight: '58px',
      },
      [breakpoints.up('md')]: {
        fontSize: '56px',
        lineHeight: '68px',
      },
      [breakpoints.up('lg')]: {
        fontSize: '72px',
        lineHeight: '87px',
      },
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: '600',
      // fontSize: '20px',
      // lineHeight: '24px',
      fontSize: '32px',
      lineHeight: '39px',
      // textShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
      [breakpoints.up('sm')]: {
        fontSize: '32px',
        lineHeight: '39px',
      },
      [breakpoints.up('md')]: {
        fontSize: '40px',
        lineHeight: '48px',
      },
      [breakpoints.up('lg')]: {
        fontSize: '48px',
        lineHeight: '58px',
      },
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '29px',
      // textShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
      [breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '34px',
      },
      [breakpoints.up('md')]: {
        fontSize: '32px',
        lineHeight: '39px',
      },
      [breakpoints.up('lg')]: {
        fontSize: '36px',
        lineHeight: '64px',
      },
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '19px',
      [breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },
      [breakpoints.up('md')]: {
        fontSize: '32px',
        lineHeight: '39px',
      },
      // textShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '24px',
      [breakpoints.up('md')]: {
        fontSize: '24px',
        lineHeight: '29px',
      },
    },
    h6Normal: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '24px',
      [breakpoints.up('md')]: {
        fontSize: '24px',
        lineHeight: '29px',
      },
    },
    h7: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '24px',
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '16px',
      lineHeight: '19px',
      [breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '22px',
      },
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '19px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Golos Text';
          src: local('Golos Text Regular'), local('GolosText-Regular'),
            url(${GolosRegular}) format('woff');
          font-style: 'normal';
          font-weight: 'normal';
        }
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        
        .swiperOverflow  {
          overflow: visible;
        }

        .anchor {
          position: relative;
          visibility: hidden;
          top: -150px;
        }

        // body {
        //   overflow: overlay;
        // }
        // ::-webkit-scrollbar {
        //   cursor:grab !important;
        //   width: 16px;
        //   background-color: transparent;
        // }
 
        // ::-webkit-scrollbar {
        //   cursor:grab !important;
        // }
        // ::-webkit-scrollbar-thumb {
        //     cursor:grab !important;
        //     background-color: rgb(0 0 0 / 50%);
        //     border-radius: 10px;
        //   border: 4px solid rgba(0, 0, 0, 0);
        //   background-clip: padding-box;
        // }
      `,
    },
  },
});
