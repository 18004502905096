import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Container from 'elements/UI/Container';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tag from 'elements/UI/Tag';
import { object, string } from 'yup';
import { createOrEditTags, tagRemove } from 'store/actions/tagActions';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import {
  addPracticeMember,
  getPracticeInfo,
  removePracticeMember,
} from 'store/actions/practiceActions';

export const EditPracticeMembersModal = ({ onClose, nameId }) => {
  const dispatch = useDispatch();
  const {
    practiceInfo,
    allMembers,
    isLoadingAllMembers,
    errorAllMembers,
    isLoadingAddingMember,
    createError,
  } = useSelector((state) => state.practice);
  const { team } = useSelector((state) => state.team);

  const onAdd = async (id) => {
    let form = { practiceId: practiceInfo?.practiceId, memberCardId: id };

    try {
      const { ok } = await dispatch(addPracticeMember(form));
      if (ok) {
        await dispatch(getPracticeInfo({ practiceName: decodeURI(nameId) }));
      }
    } catch (error) {
      throw error;
    }
  };

  const onDelete = async (id) => {
    let form = { practiceId: practiceInfo?.practiceId, memberCardId: id };

    try {
      const { ok } = await dispatch(removePracticeMember(form));
      if (ok) {
        await dispatch(getPracticeInfo({ practiceName: decodeURI(nameId) }));
      }
    } catch (error) {
      throw error;
    }
  };

  const filteredTeam = useMemo(() => {
    return team?.filter(
      (el) => !allMembers?.memberCards?.find((card) => card.id === el.id)
    );
  }, [team, allMembers]);

  //   const onSubmit = async () => {
  //     let form = { name: name, color: color };
  //     if (id) {
  //       form = { ...form, id: id };
  //     }
  //     try {
  //       const { ok } = await dispatch(createOrEditTags(form));

  //       if (ok) {
  //         onClose();
  //       }
  //     } catch (error) {
  //       throw error;
  //     }
  //   };

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Container>
        <Grid
          mt={2}
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography variant='h3'>Контакты для связи</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon stroke='black' />
            </IconButton>
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ mt: '32px' }}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Сотрудник</TableCell>
                <TableCell align='right'>Действие</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allMembers?.memberCards?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    {row.firstName} {row.middleName} {row.lastName}
                  </TableCell>
                  <TableCell align='right'>
                    <Button
                      variant='outlined'
                      color='error'
                      onClick={() => onDelete(row.id)}
                    >
                      Удалить
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {filteredTeam?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    {row.firstName} {row.middleName} {row.lastName}
                  </TableCell>
                  <TableCell align='right'>
                    <Button variant='outlined' onClick={() => onAdd(row.id)}>
                      Добавить
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};
