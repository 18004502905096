const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  menuIsOpen: false,
  searchIsOpen: false,
  changePageHeightEvent: {},
  updateNavbarEvent: {},
};
const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openMenu: (state) => {
      state.menuIsOpen = true;
      state.searchIsOpen = false;
    },
    openSearch: (state) => {
      state.menuIsOpen = false;
      state.searchIsOpen = true;
    },
    toggleMenu: (state) => {
      state.menuIsOpen = !state.menuIsOpen;
      state.searchIsOpen = false;
    },
    toggleSearch: (state) => {
      state.menuIsOpen = false;
      state.searchIsOpen = !state.searchIsOpen;
    },
    closeAll: (state) => {
      state.menuIsOpen = false;
      state.searchIsOpen = false;
    },
    updateChangePageHeightEvent: (state) => {
      state.changePageHeightEvent = {};
    },
    updateNavbarEvent: (state) => {
      state.updateNavbarEvent = {};
    },
  },
});

export const {
  openMenu,
  openSearch,
  toggleMenu,
  toggleSearch,
  closeAll,
  updateChangePageHeightEvent,
  updateNavbarEvent,
} = uiSlice.actions;

export default uiSlice.reducer;
