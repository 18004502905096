import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Navbar = ({ pathname }) => {
  const [anchorsItems, setAnchorsItems] = useState([]);
  const [anchorNodes, setAnchorNodes] = useState(null);
  const [activeItemIdx, setActiveItemIdx] = useState(null);

  const updateNavbarEvent = useSelector((state) => state.ui.updateNavbarEvent);

  useEffect(() => {
    let anchors = document.querySelectorAll('.anchor');
    let newItems = [];
    anchors.forEach((el) => {
      newItems.push({ name: el.dataset.name, id: el.id });
    });

    setAnchorsItems(newItems);
    setAnchorNodes(Array.from(anchors));
  }, [pathname, updateNavbarEvent]);

  const handleScroll = useCallback(() => {
    let newActiveIdx = 0;
    for (let i = anchorNodes?.length - 1; i >= 0; i--) {
      let nodeTop = anchorNodes?.[i]?.getBoundingClientRect().top - 150;
      if (nodeTop <= 0) {
        newActiveIdx = i;
        break;
      }
    }

    setActiveItemIdx(newActiveIdx);
  }, [anchorNodes]);

  useEffect(() => {
    handleScroll();
    const throttledScroll = _.throttle(handleScroll, 100);
    window.addEventListener('scroll', throttledScroll);

    return () => window.removeEventListener('scroll', throttledScroll);
  }, [anchorNodes, handleScroll]);

  const handleClick = (event, anchor) => {
    event.preventDefault();
    document.getElementById(anchor.id).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Box
      className='navbar'
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 'fit-content',
          //   ml: 'auto',
          mr: '12px',
          gap: '8px',
          //   borderLeft: '1px solid #00000033',
          paddingLeft: '8px',
          position: 'relative',
          '&:before': {
            content: "''",
            position: 'absolute',

            left: '0',
            top: '0.5em',
            bottom: '0.5em',
            width: '1px',
            background: '#00000033',
          },
          '& .current': {
            fontWeight: 'bold',
            opacity: 1,
            position: 'relative',
            '&:after': {
              content: "'•'",
              position: 'absolute',
              fontSize: '1.5em',
              left: '-8px',
              transform: 'translateX(-50%)',
            },
          },
        }}
      >
        {anchorsItems.map((anchor, idx) => (
          <Typography
            key={idx}
            component='span'
            sx={{
              width: 'fit-content',
              cursor: 'pointer',
              opacity: '0.5',
              transition: 'all 0.2s',
            }}
            onClick={(event) => handleClick(event, anchor)}
            className={idx === activeItemIdx ? 'current' : ''}
          >
            {anchor.name}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default Navbar;
