import { Typography } from '@mui/material';

export const mock = {
  id: 0,
  name: 'Налоговое право и налоговые сборы',
  contacts: [
    {
      name: 'Вихлянцева Марина Валентиновна',
      img: '/static/media/team1.ec4f411d3795b792bcf3.png',
      cardDescription: 'Управляющий партнёр Prim Group ',
      email: 'vihlyantseva@mail.ru',
      phone: '+7 (900) 123-12-12',
    },
    {
      name: 'Михайлов Андрей Дмитриевич',
      img: 'https://sun9-14.userapi.com/impg/Usonrh3vTOnbpNLC3gyQG-LnVQMQN4f1ev3ltA/ovucFyeUyGM.jpg?size=350x300&quality=96&sign=50c25780dbfd24903a54a1dd9b183407&type=album',
      cardDescription: 'Управляющий партнёр Prim Group',
      email: 'andrewprim@mail.ru',
      phone: '+7 (991) 233-33-22',
    },
    {
      name: 'Андреенко Виктория Борисовна',
      img: 'https://sun9-14.userapi.com/impg/owrbY51V0m2IMmyHUbqoDwyJohvjE7YzEqU9Tw/iPDRyAmqeng.jpg?size=350x300&quality=96&sign=586aba2401116ffa7af2b16bd03962d2&type=album',
      cardDescription: 'Адвокат первой инстанции и категории',
      email: 'andreenko@mail.ru',
      phone: '+7 (913) 555-35-35',
    },
    {
      name: 'Вихлянцева Марина Валентиновна',
      cardDescription: 'Управляющий партнёр Prim Group',
      email: 'vihlyantseva@mail.ru',
      phone: '+7 (9хх) ххх-хх-хх',
    },
  ],
};

export const bankruptcyMock = {
  blocks: [
    {
      name: 'Защита прав кредиторов при банкротстве должника',
      text: (
        <Typography textAlign='justify'>
          Одна из целей закона о банкротстве - обеспечить права реальных и
          потенциальных кредиторов. Поэтому на процедуры банкротства наиболее
          сильное влияние оказывают кредиторы, объединение которых
          осуществляется в общих интересах на общих принципах равенства и
          наличии равных рисков несостоятельности должника.
          <br />
          <br />
          Включение требований кредитора в реестр требований кредиторов (РТК)
          является способом удовлетворения прав кредитора в банкротстве -
          формирует объем подтвержденных обязательств должника, подлежащих
          удовлетворению по результатам рассмотрения дела и количество голосов
          при принятии ключевых решений по делу о банкротстве.
          <br />
          <br />В условиях банкротства должника и конкуренции между собой его
          кредиторов возможны ситуации, когда "дружественный" должнику кредитор
          инициирует судебный спор по мнимой задолженности с целью получения
          внешне безупречного судебного акта для включения требований в реестр.
          Практика выделяет сходные признаки «мнимой» задолженности:
          <ul>
            <li>минимальный набор доказательств,</li>
            <li>пассивность сторон при опровержении позиций друг друга,</li>
            <li>
              аффилированность должника (либо контролирующих должника лиц) и
              "дружественного" кредитора,
            </li>
            <li>
              участие одних и тех же представителей в различных спорах в
              интересах должника и "дружественного" кредитора,
            </li>
            <li>
              признание сторонами обстоятельств дела или признание иска и т.п.
            </li>
          </ul>
          Интересы "дружественного" кредитора и должника совпадают.
          <br />
          <br />
          Признанием судебным актом «мнимой» задолженности обоснованной и
          подлежащей включению в реестр могут нарушаться права независимых
          кредиторов, конкурирующих с "дружественным" должнику и имеющих с ним
          противоположные интересы.
          <br />
          <br />
          Для защиты прав независимых кредиторов от конкуренции с
          «дружественными» должнику кредиторами, как следствие, избираются
          противоположные процессуальные позиции.
          <br />
          <br />
          При установлении требований в реестре требований кредиторов (РТК) при
          банкротстве должника используется повышенный (по сравнению с обычным
          общегражданским) стандарт доказывания - "ясные и убедительные
          доказательства". Поэтому один и тот же набор доказательств может быть
          достаточным для подтверждения оснований для взыскания долга в обычном
          гражданском процессе и недостаточным при доказывании оснований для
          установления того же требования в РТК при банкротстве должника, если
          требование кредитора могло вызвать объективные (разумные) сомнения в
          обоснованности. Например, при рассмотрении денежного требования,
          вытекающего из векселя, отсутствие подтверждения обязательства -
          основания выдачи векселя, ставит под сомнение возможность включения
          требований по векселю в реестр требований кредиторов.
          <br />
          <br />
          Возражающему против требований кредитора лицу достаточно представить
          суду доказательства prima facie, подтвердив существенность сомнений в
          существовании долга.
          <br />
          <br />
          При установлении в РТК требований контролирующих должника лиц
          расширяется предмет доказывания - подлежит доказыванию как факт
          реальности долга, так и отсутствие у него корпоративной природы, а
          также повышается стандарт доказывания - "вне разумных сомнений"- при
          установлении этих фактов. Стандарт "вне разумных сомнений" существенно
          выше, чем обычный стандарт доказывания по гражданским делам и в
          настоящее время применяется не только при установлении оснований
          уголовной ответственности.
          <br />
          <br />
          Общей целью конкурсного производства является принятие мер,
          направленных на увеличение конкурсной массы должника, в том числе на
          поиск, выявление и возврат имущества должника, находящегося у третьих
          лиц, посредством подачи заявления о признании сделок
          недействительными, а также о применении последствий недействительности
          ничтожных сделок.
          <br />
          <br />
          Споры об очередности удовлетворения требований кредиторов и
          субординации (понижении) привилегий кредиторов - снижении конкуренции
          внутри кредиторов, являются частью практически любого банкротства.
          <br />
          <br />
          Очередность удовлетворения требований кредиторов – это применение
          понижающих или повышающих коэффициентов при распределении риска
          несостоятельности между различными группами кредиторов.
          <br />
          <br />
          Принцип равенства кредиторов (pari passu) проявляется в равном
          распределении конкурсной массы среди равных и предоставлении им
          равного права на управление.
          <br />
          <br />
          Однако, возникают отклонения /исключения от общего правила, приводящие
          к неравному распределению конкурсной массы при повышении или понижении
          очередности удовлетворения; а также при предоставлении выделенной
          части имущества должника для удовлетворения; к неравенству в
          предоставлении управленческих прав (лишение права голоса или,
          напротив, предоставление права решать с устранением остальных).
          <br />
          <br />
          Законом о банкротстве введены традиционные исключения-привилегии,
          такие как преимущественное удовлетворение требований залоговых
          кредиторов из выручки от реализации предмета залога; удовлетворение
          требований кредиторов по текущим обязательствам; удовлетворение
          требований кредиторов привилегированных очередей и др. Законом и
          судебной практикой введены субординирующие исключения, например,
          понижение очередности удовлетворения требований об уплате неустоек и
          иных финансовых санкций; понижение очередности удовлетворения
          требований связанных с должником лиц при недостаточной капитализации
          их финансирования в кризис; понижение очередности удовлетворения
          реституционного требования недобросовестного контрагента по оспоренной
          сделке; понижение очередности удовлетворения требований опоздавших
          кредиторов; понижение очередности удовлетворения обеспечившего долг
          лица, выплатившего частично кредитору, по отношению к требованию
          кредитора в части остатка и иные.
          <br />
          <br />
          Судебной практикой активно создаются изъятия из принципа равенства:
          например, предоставлением кредитору права не отдавать из своей массы
          причитающееся должнику (титульное обеспечение, право зачета),
          предоставлением конкретному кредитору права на преимущественное
          получение выручки из вещи. Однако, пожалуй, лишь вопросы сальдирования
          (которые не могут отождествляться с зачетом) приобрели в судах
          прогнозируемое разрешение.
          <br />
          <br />
          Защита прав кредиторов в случаях злоупотребления правом, приводящего к
          равенству в распределении и (или) управлении среди неравных, либо при
          правонарушениях, является одним из основных видов юридической помощи
          оказываемой нашими адвокатами в делах о банкротстве.
        </Typography>
      ),
      card: (
        <Typography textAlign='justify'>
          <ul style={{ paddingLeft: '24px' }}>
            <li>
              включение требований кредиторов в реестр - если хочешь получить
              удовлетворение в процедуре банкротства, включись в реестр
              требований кредиторов
            </li>
            <li>
              споры об очередности удовлетворения требований кредиторов
              (привилегии и субординации)
            </li>
            <li>
              защита интересов отдельных категорий кредиторов при банкротстве
            </li>
            <li>поиск и возврат имущества должника в конкурсную массу</li>
          </ul>
        </Typography>
      ),
    },
    {
      name: 'Оспаривание сделок должника',
      text: (
        <Typography textAlign='justify'>
          Любое исполнение, совершенное должником-банкротом, может быть оспорено
          по специальным «банкротным» или общим основаниям. Поэтому сделки,
          совершенные в преддверии банкротства и после принятия судом заявления
          о банкротстве должника – это всегда зона риска.
          <br />
          <br />
          В законе применен правовой прием, называемый банкротным специалитетом,
          когда все требования (за исключением специально оговоренных законом),
          предъявленные к должнику, в отношении которого возбуждено дело о
          банкротстве, исполнение таких требований, их обеспечение - не
          допускаются вне дела о банкротстве.
          <br />
          <br />
          При рассмотрении споров о недействительности сделок должника действует
          общий принцип распределения бремени доказывания. Однако, целый
          комплекс обстоятельств, входящих в предмет доказывания, исключается из
          бремени доказывания активной стороны при оспаривании сделок по
          специальным основаниям закона о банкротстве путем презумпций. Это
          презумпции цели причинения вреда кредиторам, осведомленности о цели
          совершения сделки во вред кредиторам, осведомленность о признаках
          неплатежеспособности должника при наличии публикации о возбуждении
          банкротства и др.
          <br />
          <br />
          Презюмирование законом значительного объема обстоятельств при
          оспаривании сделок банкрота обосновывается тем, что сделки,
          направленные на сокрытие активов должника, и сделки, совершенные в
          целях оказания предпочтения одному из кредиторов, являются сделками с
          пороками содержания. Нарушаются положения о добросовестности при
          осуществлении гражданских прав, что всегда непросто доказывать. Для
          упрощения установления состава признания сделок недействительными
          законом устанавливаются опровержимые презумпции, что соответствует
          балансу интересов прав кредиторов и ответчиков - сторон сделок с
          должником. Добросовестно действующей стороне сделки легче доказать
          законность своих действий, раскрыть мотивы и цели заключения сделки с
          должником и представить соответствующие доказательства.
          <br />
          <br />
          Суд распределяет бремя доказывания, в том числе путем возложения
          обязанности на сторону раскрыть разумные экономические мотивы
          совершения сделки, либо мотивы поведения в процессе ее исполнения.
          <br />
          <br />
          Повышенная активность суда при рассмотрении обособленных споров
          проявляется в необходимости:
          <ul>
            <li>
              произвести верную правовую квалификацию правоотношений (независимо
              от предложенной оспаривающим лицом) и признать сделку
              недействительной в соответствии с надлежащей нормой права;
            </li>
            <li>
              применить последствия недействительности сделки (вне зависимости
              от наличия или отсутствия такого требования при оспаривании
              сделки);
            </li>
            <li>
              квалифицировать действия участников при исполнении сделки в
              качестве злоупотребления правом при наличии к тому оснований,
              независимо от позиции лиц, участвующих в деле;
            </li>
            <li>
              самостоятельность суда при истребовании и получении доказательств
              злоупотребления правом при банкротстве;
            </li>
          </ul>
          <br />
          <br />
          При оспаривании сделок должника факт отказа арбитражного управляющего
          от иска о признании сделки недействительной не влечет прекращения
          производства по делу. Суд должен исходить из необходимости защиты прав
          кредиторов и пополнения конкурсной массы и продолжить рассмотрение
          требования. В подобных случаях судами шире, чем в делах других
          категорий, понимаются пределы проверки распорядительных действий на
          предмет нарушения прав и законных интересов третьих лиц. В этом
          проявляется ограничение принципа диспозитивности, поскольку судом
          применяется ограничение свободы распорядительных действий сторон
          процесса. Также при оспаривании сделок суд не связан заявленными
          требованиями и обязан независимо от наличия такого требования
          применить последствия недействительности сделки.
        </Typography>
      ),
      card: (
        <Typography textAlign='justify'>
          Защита интересов сторон по спорам о недействительности сделок,
          заявленных к должнику-банкроту, которые рассматриваются процедурах
          банкротства (банкротный специалитет) с особенностями по распределению
          бремени доказывания и применению правовых презумпции, а также при
          определенном отступлении от принципа диспозитивности в пользу активной
          роли суда
        </Typography>
      ),
    },
    {
      name: `Обособленные споры по возмещению убытков и привлечении к субсидиарной ответственности контролирующих должника-банкрота лиц (КДЛ); защита прав субсидиарных ответчиков`,
      text: (
        <Typography textAlign='justify'>
          Контролирующее должника лицо - физическое или юридическое лицо,
          имеющее либо имевшее не более чем за три года, предшествующих
          возникновению признаков банкротства, а также после их возникновения до
          принятия арбитражным судом заявления о признании должника банкротом
          право давать обязательные для исполнения должником указания или
          возможность иным образом определять действия должника, в том числе по
          совершению сделок и определению их условий.
          <br />
          <br />
          Закон о банкротстве не ставит знака равенства между руководителем
          должника и контролирующим его лицом. Необходимым условием отнесения
          лица к числу контролирующих должника является наличие у него
          фактической возможности давать должнику обязательные для исполнения
          указания или иным образом определять его действия, при этом
          осуществление фактического контроля над должником возможно вне
          зависимости от наличия формально- юридических признаков
          аффилированности (доктрина "прокалывания (снятия) корпоративной
          вуали").
          <br />
          <br />
          Признак контролирующего должника лица или руководителя этого
          контролирующего лица появился и в уголовном праве – в составе
          признаков, характеризующих субъекта преступлений, предусмотренных ч.
          1.1, 4 ст. 195, п. "а" ч. 2 ст. 196 УК РФ. Диспозиция бланкетная,
          понятие контролирующего должника лица определяется также, как оно
          определено в законе о банкротстве.
          <br />
          <br />
          В определении СКЭС от 30.09.2021 N 307-ЭС21-9176 судом указано: "На
          правовое положение контролирующего лица влияют два ключевых
          обстоятельства: 1) совокупный размер требований кредиторов к должнику
          и 2) объем конкурсной массы. Разница между двумя названными величинами
          и составляет размер ответственности контролирующего лица.
          Соответственно, контролирующему лицу должны быть предоставлены
          полномочия тем или иным образом влиять на две указанные величины, так
          как они ему объективно противопоставляются».
          <br />
          <br />
          Субсидиарная ответственность лиц, контролирующих должника, имеет
          деликтный характер, регулируется нормами закона о банкротстве, которые
          устанавливают не только детализированные юридические составы деликта,
          но и процессуальные правила оценки доказательств, определения круга
          лиц, участвующих в деле, особенности судебного разбирательства и др.
          Верховный Суд Российской Федерации указывает, что это ответственность
          контролирующего лица по собственному обязательству - обязательству из
          причинения вреда имущественным правам кредиторов, возникшего в
          результате его неправомерных действий (бездействия), которые выходят
          за пределы обычного делового риска, стали необходимой причиной
          банкротства должника и привели к невозможности удовлетворения
          требований кредиторов (к обесцениванию их обязательственных прав).
          <br />
          <br />
          В спорах о субсидиарной ответственности КДЛ также широко используются
          правовые презумпции, например, презумпция о статусе контролирующего
          лица; презумпция доведения до объективного банкротства; презумпция
          объективного банкротства по вине контролирующих лиц при сокрытии ими
          документов должника и др.
          <br />
          <br />
          При этом судебная практика использует подход, при котором бремя
          доказывания распределяется исходя из фактической возможности -
          удобства представления доказательств. Отсутствие у членов органов
          управления, иных контролирующих лиц (например, скрытые бенефициары)
          заинтересованности в раскрытии документов, отражающих реальное
          положение дел и действительный оборот, не должно снижать уровень
          правовой защищенности кредиторов при необоснованном посягательстве на
          их права. Поэтому убедительная совокупность косвенных доказательств
          (например, наличие рабочего кабинета в офисе должника, дача интервью о
          дальнейшем развитии бизнеса, общие сайты, встречи, переписка и т.д.)
          зачастую позволяет утверждать о наличии у привлекаемого к
          ответственности лица статуса контролирующего и о невозможности
          погашения требований кредиторов вследствие действий (бездействия)
          последнего. Бремя опровержения данных утверждений переходит на
          привлекаемое к ответственности лицо.
          <br />
          <br />
          Судами приводятся следующие критерии для выявления контролирующих лиц
          должника:
          <ul>
            <li>
              наличие у ответчика возможности оказывать существенное влияние на
              деятельность должника (что, например, исключает из круга
              потенциальных ответчиков рядовых сотрудников, миноритарных
              акционеров и т.д., при условии, что формальный статус этих лиц
              соответствует их роли и выполняемым функциям);
            </li>
            <li>
              реализация ответчиком соответствующих полномочий привела (ведет) к
              негативным для должника и его кредиторов последствиям; масштаб
              негативных последствий соотносится с масштабами деятельности
              должника, т.е. способен кардинально изменить структуру его
              имущества в качественно иное - банкротное - состояние;
            </li>
            <li>
              ответчик является инициатором (соучастником) такого поведения и
              (или) потенциальным выгодоприобретателем возникших в связи с этим
              негативных последствий.
            </li>
          </ul>
          <br />
          <br />
          Защита прав субсидиарных ответчиков
          <br />
          <br />
          Наличия только лишь подозрений в виновности ответчиков недостаточно
          для удовлетворения иска о привлечении к субсидиарной ответственности,
          необходимо привести ясные и убедительные доказательства такой вины.
          <br />
          <br />
          При разрешении вопроса о наличии оснований для привлечения директора к
          субсидиарной ответственности правовое значение имеют следующие
          обстоятельства:
          <ul>
            <li>
              являлся ли план выхода из кризиса разумным в момент его принятия;
            </li>
            <li>
              когда негативные тенденции, продолжившиеся в ходе его реализации,
              привели предприятие в состояние, свидетельствующее о том, что план
              себя исчерпал.
            </li>
          </ul>
          <br />
          <br />
          При разрешении вопроса о привлечении директора к ответственности за
          неподачу (несвоевременную подачу) заявления должника о собственном
          банкротстве существенное значение имеет установление момента
          возникновения соответствующей обязанности.
        </Typography>
      ),
      card: (
        <Typography textAlign='justify'>
          <ul style={{ paddingLeft: '24px' }}>
            <li>
              предъявление требований в банкротстве о возмещении убытков к
              контролирующим должника лицам (КДЛ) по основаниям ст. 53.1 ГК РФ,
            </li>
            <li>
              привлечение контролирующих должника лиц к субсидиарной
              ответственности по обязательствам должника
            </li>
            <li>защита прав субсидиарных ответчиков</li>
          </ul>
        </Typography>
      ),
    },
  ],
  textes: {
    textGeneral: `Банкротство, как бы печально это ни звучало, является инструментом рыночной экономики. Неспособность участника экономического оборота расплатиться по своим долгам вызывает множество конфликтов, урегулирование которых является целью применения процедур банкротства. Банкротство реализуется через особый процесс, где главная роль отведена суду. Спецификой дел о банкротстве является рассмотрение в рамках единого судебного разбирательства в арбитражном суде различных споров, получивших наименование "обособленных споров", двигающих процедуры банкротства. Защита прав и интересов лиц, участвующих в делах о банкротстве, требует определенных компетенций, задает необходимость отслеживания изменений не только законодательства, но судебной практики, формирующих правовые правила и подходы в процедурах банкротства.
Адвокаты Бюро осуществляют юридическую помощь бизнесу и физическим лицам во всех процедурах банкротства, проводят аналитику рисков и неблагоприятных последствий при угрозе банкротства. Наша команда осуществляет консультирование, судебную защиту в практике банкротства более 20 лет. Не пренебрегайте помощью профессионалов, обладающих опытом комплексного подхода к решению сложных вопросов банкротства и связанных с ним процедур.`,
  },
  lastBlock: {
    name: 'Отстранение и привлечение к ответственности арбитражного управляющего',
    person: {
      name: 'Дмитрий Иванов',
      status: 'Партнёр',
      email: 'dmivanov@prim.group',
      photo:
        'https://downloader.disk.yandex.ru/preview/0b845a53f309c36752b16d0afc7947689660fc8f4c164a00bdae1870aa9f7c2b/66368271/CBxM-YisCnJlR74orLAowdEG8FtHlksaLB_vTeEI9mAi10CQ6A8hEgSRGZ3iZx0ES2fYbdyY4RfhMX2VgJl_Wg%3D%3D?uid=0&filename=%D0%A4%D0%BE%D1%82%D0%BE%202.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=2560x1271',
    },
    text1: (
      <Typography textAlign='justify'>
        Производство по делу о несостоятельности не может проводиться без
        участия особого субъекта - арбитражного управляющего. Управляющий
        назначается в момент введения первой процедуры по делу о банкротстве и
        осуществляет возложенные на него полномочия, определенные законом. В
        своей деятельности в рамках арбитражного процесса по делу о банкротстве
        управляющий обладает специальным процессуальным статусом, отражающим его
        назначение в деле о банкротстве.
      </Typography>
    ),
    text2: (
      <Typography textAlign='justify'>
        Независимый характер деятельности арбитражного управляющего не
        предполагает наличие у него самостоятельного интереса в исходе дела о
        банкротстве. Управляющий действует в интересах гражданского правового
        сообщества, объединяющего кредиторов, и должника, поэтому он не может
        уклониться от анализа сделок и обстоятельств их совершения вместе с
        фактами, сообщаемыми кредитором. Формирование конкурсной массы входит в
        круг основных обязанностей конкурсного управляющего. Игнорирование
        требования кредитора, непредоставление кредитору запрашиваемых
        документов, указанных в требовании, бездействие по обращению в суд с
        заявлением об оспаривании сделок должника; бездействие в резервировании
        средств от продажи залогового имущества в счет требований ФНС, а также
        иные действия или бездействие арбитражного управляющего могут являться
        основаниями для его отстранения судом.
      </Typography>
    ),
    text3: (
      <Typography textAlign='justify'>
        Гражданско-правовая ответственность арбитражного управляющего наступит,
        если убытки возникли по вине управляющего вследствие неисполнения либо
        ненадлежащего исполнения им профессиональных обязанностей; факт убытков
        должен быть установлен судом, при этом права должника и конкурсных
        кредиторов считаются нарушенными всякий раз при причинении убытков.
        <br />
        <br />
        При оценке соответствия действий арбитражного управляющего требованиям
        закона суд обязан без соответствующего заявления от лица, участвующего в
        деле, отстранить управляющего от исполнения его обязанностей при
        установлении обстоятельств, свидетельствующих о совершении им действий
        во вред должнику и кредиторам либо несоответствия установленным
        критериям.
      </Typography>
    ),
  },
};

export const generalJurisdictionMock = {
  first: {
    // name: 'Общая подводка',
    person: {
      name: 'Дмитрий Иванов',
      status: 'Партнёр',
      email: 'dmivanov@prim.group',
      photo:
        'https://downloader.disk.yandex.ru/preview/0b845a53f309c36752b16d0afc7947689660fc8f4c164a00bdae1870aa9f7c2b/66368271/CBxM-YisCnJlR74orLAowdEG8FtHlksaLB_vTeEI9mAi10CQ6A8hEgSRGZ3iZx0ES2fYbdyY4RfhMX2VgJl_Wg%3D%3D?uid=0&filename=%D0%A4%D0%BE%D1%82%D0%BE%202.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=2560x1271',
    },
    text: (
      <Typography textAlign='justify'>
        Каждый, кто участвовал в споре, понимает, насколько необходимо разрешить
        спор и не превратить его в драку. Хорошо, когда стороны терпеливы и
        внимательны к аргументам друг друга. Но не всегда сторонам удается
        договориться. Иногда нет иного выбора, чем обратиться в суд за защитой
        своих прав и интересов. Непросто понять процедуры, правила и возможности
        судебной защиты самому, даже используя возможности Google.
        <br />
        <br />
        Адвокаты PRIM group оказывают юридическую помощь при разрешении в судах
        споров и конфликтов по судебному представительству интересов клиентов в
        арбитражных судах и судах общей юрисдикции. Судебное представительство –
        одна из основных практик в Бюро. Более 20 лет назад наше Бюро было
        создано путем объединения личных практик успешных адвокатов в целях
        повышения эффективности и расширения возможностей командной работы в
        интересах защиты прав клиентов в судах.
        <br />
        <br />
        Мы поможем определиться с тактикой и стратегией дела для оценки
        перспектив похода в суд, с выбором способа защиты, составим все
        необходимые процессуальные документы, осуществим поиск доказательств для
        успешного проведения спора со стороны клиента, найдем специалистов и
        экспертов для решения задач в рамках судебных экспертиз. У нас большой
        практический опыт в судебном представительстве по различным категориям
        дел в арбитражных судах и судах общей юрисдикции всех уровней. Каждый
        адвокат, юрист, стажер и помощник в нашем Бюро изучает и анализирует
        судебную практику, основные подходы суда к разрешению той или другой
        категории споров, мы проводим обсуждения тенденций развития и изменения
        законодательства, организуем круглые тематические столы для обсуждения
        вопросов по специальным темам, выступаем на конференциях, форумах, иных
        мероприятиях в целях широкого обсуждения проблем, возникающих при
        осуществлении судебного представительства.
        <br />
        <br />
        Мы используем любую возможность разрешить спор в досудебном порядке, в
        рамках третейского разбирательства, путем переговоров или медиации. При
        возможности мирового урегулирования судебного спора поможем выработать и
        заключить мировое соглашение. Исполнение решения суда не менее значимо
        для наших клиентов, чем сам судебный поединок. На данной стадии также
        немало юридических вопросов, и мы готовы помочь клиентам в их
        разрешении.
      </Typography>
    ),
  },
  second: {
    name: 'Арбитражные споры',
    text: (
      <Typography textAlign='justify'>
        Арбитражные споры существенно отличаются от любых других споров. Самое
        важное — арбитражное судопроизводство предназначено для рассмотрения
        споров между предпринимателями. При этом, здесь рассматриваются только
        административные и гражданские споры, тогда как уголовные споры, к
        примеру, остаются в компетенции судов общей юрисдикции.
        <br />
        <br />
        Другая особенность состоит в том, что судьи этих судов зачастую гораздо
        требовательнее к представляемым доказательствам, при чём не только к их
        содержанию, но и к тому, как они представлены в судебном заседании.
        <br />
        <br />
        Помимо общегражданских споров Арбитражные суды рассматривают и более
        узкие виды споров: дела о признании предприятия банкротом, различного
        рода корпоративные споры, когда собственники бизнеса не могут поделить
        совместно заработанные деньги, инвестиционные споры, связанные с
        порядком инвестирования в различные проекты и возвратом инвестиционного
        дохода, и другие. Ещё более специфичны споры, связанные с защитой
        интеллектуальной собственности, регистрации и использования товарных
        знаков и коммерческих обозначений, нарушений прав авторов литературных,
        художественных, музыкальных произведений, изобретателей и другие. Их
        рассматривает Суд по интеллектуальным правам, который, хотя и входит в
        систему арбитражных судов, но в силу специфики рассматриваемых споров
        занимает особое место в судебной системе.
        <br />
        <br />
        Арбитражные споры, как, впрочем, и любой другой спор, никогда не бывает
        простым и однозначным. Очень многое будет зависеть от того, каким
        образом представлены доказательства, что они из себя представляют, как
        они доведены до сведения суда, и будут ли восприняты должным образом.
        Претворение этого в жизнь невозможно без должной подготовки и
        профессионального опыта. Адвокаты АБ «PRIM Group» ежедневно развивают
        свои профессиональные компетенции, и применяют многолетний опыт, и
        всегда делятся им друг с другом. К любому спору мы подключаем весь
        имеющийся в нашем распоряжении профессиональный ресурс, именно это
        позволяет эффективно достигать поставленных нашими доверителями задач.
      </Typography>
    ),
    cards: [
      {
        title: 'Какие арбитражные суды существуют в России?',
        content: [
          'Суды субъектов (нижнее звено системы арбитражных судов)',
          'Арбитражные апелляционные суды',
          'Федеральные арбитражные суды округов (кассационная инстанция)',
          'Верховный суд РФ возглавляет систему арбитражных судов',
          'Суд по интеллектуальным правам (специализированный суд)',
        ],
      },
      {
        title: 'Какое решение может вынести суд по итогам рассмотрения дела?',
        content: [
          'Удовлетворить исковые требования полностью или в части',
          'Отказать в удовлетворении требований полностью или в части',
          'Утвердить мировое соглашение между кредитором и должником',
          'Решение о введении определенной процедуры банкротства в отношении банкротящегося предприятия',
          'Обязать ответчика совершить определённые в решении действия',
          'Признать исполнительный документ или иной документ неприменимым',
          'Расторгнуть или изменить договор',
        ],
      },
      {
        title: 'Чем отличается арбитражный суд от суда общей юрисдикции?',
        content: [
          'Рассмотрение споров в сфере предпринимательской и  иной экономической деятельности между юридическими лицами, ИП, инвесторами, а также дела о банкротстве',
          'Арбитражные суды при проведении судебных заседаний руководствуются АПК РФ, а не ГПК РФ.',
          'Самая первая ступень системы арбитражных судов — не районный суд города, а Арбитражный суд субъекта.',
        ],
      },
    ],
  },
  third: {
    name: 'Суды общей юрисдикции',
    text: (
      <Typography textAlign='justify'>
        Большинство всех дел, которые доходят до суда, рассматриваются в судах
        общей юрисдикции. Поэтому юрисдикция и называется общей: рассматривают
        абсолютное большинство поступающих споров.
        <br />
        <br />
        Палитра рассматриваемых дел в этих судах очень обширна:
        <ul>
          <li>
            все гражданские и административные дела о защите нарушенных или
            оспариваемых прав, свобод и охраняемых законом интересов, за
            исключением дел, которые рассматриваются другими судами (например,
            арбитражными, или мировыми судьями);
          </li>
          <li>все уголовные дела;</li>
          <li>
            дела с участием иностранных лиц, судов и иностранных государств,
          </li>
          <li>другие дела, которые законом прямо отнесены к их ведению.</li>
        </ul>
        Например, в порядке гражданского судопроизводства рассматриваются споры
        по оспариванию и исполнению сделок (купли-продажи, долевого участия в
        строительстве, дарения, займа и кредитования), трудовые споры,
        причинение вреда здоровью, имуществу и различным другим спорам.
        Административные споры, например, касающиеся нарушения правил дорожного
        движения, связанные с действиями органов внутренних дел (включая ГИБДД),
        неверным исчислением уплаченных гражданином налогов, и множество
        другого.
        <br />
        <br />
        Все вышеуказанные споры очень хорошо известны не только бизнесу, с ними,
        пожалуй, может столкнуться любой человек.
        <br />
        <br />
        Успех конкретного дела зависит от множества факторов: солнечной погоды,
        вкуса выпитого судьёй утреннего кофе… А ещё очень важную роль играет
        подготовленный адвокатом доказательственный материал, уровень его
        квалификации, навыки судебной риторики, тактика и стратегия ведения
        дела. И эти задачи адвокаты Бюро решают на высшем уровне.
      </Typography>
    ),
    cards: [
      {
        title: 'Какие споры рассматривают суды общей юрисдикции?',
        content: [
          'Гражданские споры между гражданами',
          'Трудовые споры',
          'Административные споры',
          'Все уголовные дела',
          'Налоговые споры в отношении взимаемых с физических лиц налогов',
        ],
      },
      {
        title:
          'Какие судебные расходы можно взыскать с противоположной стороны?',
        content: [
          'Государственная пошлина',
          'Расходы на услуги адвоката',
          'Стоимость экспертизы',
          'Расходы на услуги переводчика, специлиста или эксперта',
          'Почтовые расходы',
          'Иные суммы, которые суд признает необходимыми',
        ],
      },
      {
        title: 'Какие факты не подлежат доказыванию?',
        content: [
          'Общеизвестные факты (проведение выборов, проведение массового мероприятия, боевые действия',
          'Преюдициальные факты (обстоятельства, установленные вступившим в законную силу судебным постановлением по ранее рассмотренному делу)',
          'обстоятельства, подтвержденные нотариусом при совершении нотариального действия',
        ],
      },
    ],
  },
  fourth: {
    name: 'Составление иска – с чего начать',
    person: {
      name: 'Дмитрий Иванов',
      status: 'Партнёр',
      email: 'dmivanov@prim.group',
      photo:
        'https://downloader.disk.yandex.ru/preview/0b845a53f309c36752b16d0afc7947689660fc8f4c164a00bdae1870aa9f7c2b/66368271/CBxM-YisCnJlR74orLAowdEG8FtHlksaLB_vTeEI9mAi10CQ6A8hEgSRGZ3iZx0ES2fYbdyY4RfhMX2VgJl_Wg%3D%3D?uid=0&filename=%D0%A4%D0%BE%D1%82%D0%BE%202.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=2560x1271',
    },
    text: (
      <Typography textAlign='justify'>
        Перспективу судебного спора для истца определяет его подготовка. Этой
        стадии нельзя отводить второстепенную или формальную роль.
        Первостепенная задача - найти профессионального советника по правовым
        вопросам и обсудить с ним все обстоятельства спорной ситуации. Оценка
        перспектив дела, рисков, выработка стратегии и правовой позиции в
        дальнейшем во многом предопределяют результат.
        <br />
        <br />
        Больной вопрос для всех судебных представителей – это степень
        достоверности обстоятельств, которые им предоставляют доверители. Тут мы
        не оригинальны, и сможем только повторить известное – нельзя врать своем
        врачу и адвокату. Если к адвокату не возникло доверие – найдите другого
        профессионального советника по правовым вопросам, которому будете
        доверять.
        <br />
        <br />
        Обязанность хранения адвокатской тайны – это не пустая декларация, это
        гарантия защиты клиента. Строгая конфиденциальность не только всех
        сведений, полученных адвокатом от своего клиента, но даже самого факта
        обращения к адвокату, принятие мер по защите адвокатской тайны являются
        приоритетами работы нашего Бюро.
        <br />
        <br />
        После обсуждения обстоятельств дела, определения правовой позиции,
        необходимо собрать и закрепить в определенной форме доказательства в
        подтверждение обоснованности требований истца. Сбор доказательств –
        процесс, требующий не только опыта, но и вдумчивости. Доказательства,
        как правило, на поверхности не лежат, напротив, часто скрываются или
        неизвестны по различным причинам. Соответственно, перед адвокатом стоит
        задача определить способ доказывания, перечень доказательств (письменные
        или электронные документы, свидетельские показания, видео- и аудиозаписи
        и др.), а также возможность поиска и сбора доказательств самостоятельно
        или с помощью суда.
        <br />
        <br />
        Логичное описание в иске конкретных фактов, которыми истец обосновывает
        свои требования о восстановлении нарушенных прав и законных интересов,
        подтверждение изложенных в иске оснований прилагаемыми к нему
        доказательствами, является одной из гарантий успешного рассмотрения
        спора в пользу заявителя. Поэтому составление иска является важной
        стадией на пути инициирования судебного спора.
        <br />
        <br />
        Направление иска в суд, выбор места, времени, ситуации, процессуально-
        распорядительные действия суда и принимаемые им на стадии принятия иска
        процессуальные решения - всегда зависят от конкретных обстоятельств
        спорной ситуации и должны укладываться в общую стратегию, выработанную
        клиентом со своим адвокатом.
        <br />
        <br />
        Помните, что первое впечатление формируется из заявленного вами иска –
        из его содержания, оснований, требований и представленных первоначально
        с иском доказательств. Опытные судебные представители понимают, что со
        стадии составления иска начинается борьба за первое положительное
        впечатление судьи в пользу истца.
        <br />
        <br />
        Краткость – по-прежнему сестра таланта, и сложность в простоте. Первое
        правило хорошего иска – умение сложных явлений и обстоятельств описывать
        простыми словами и немногословными предложениями. Адвокатами нашего Бюро
        составлено и направлено в суды множество исков, множество споров
        завершены в пользу наших клиентов, однако задачу привлечения внимания
        судьи, среднестатистическая нагрузка на которого очень высока и
        вынуждает его ежедневно прочитывать гору бумаг, мы решаем при
        составлении каждого иска.
        <br />
        <br />
        Четкая структура иска представляет собой выделение и исследование
        значимых фактов, краткое описание второстепенных доказательственных
        фактов. Истцу, как правило, всегда есть на что пожаловаться в суде,
        однако внимание судьи следует обращать только на значимые для
        заявленного иска факты.
        <br />
        <br />
        Зачастую доверители склоняют адвокатов обратить внимание на
        обстоятельства, имеющие для них субъективный сильный эмоциональный
        интерес. Но многие из этих фактов не имеют отношения к предмету спора,
        поэтому задача судебного представителя – объяснить и убедить клиента в
        ненужности дополнительных эмоций, затрудняющих рассмотрение спора.
      </Typography>
    ),
  },
};

export const criminalMock = {
  cards: [
    {
      title: 'Информация о практике',
      name: 'Услуги',
      points: [
        'Уголовно-правовая защита на всех стадиях взаимодействия с правоохранительными и контрольно- надзорными органами: от проведения любых оперативно-розыскных мероприятий и доследственных проверок до обжалования действий и решений в Верховном и Конституционном суде РФ;',
        'Защита потерпевших от противоправных деяний третьих лиц и бездействия правоохранительных органов;',
        'Выявление и оценка уголовно-правовых рисков при планировании и ведении экономической деятельности, структурировании бизнеса;',
        'Защита собственников, бенефициаров и менеджмента от преступных посягательствах на бизнес и неправомерном административном давлении с использованием уголовно-правовых институтов;',
        'Участие в гражданских спорах, осложнённых уголовно-правовыми элементами;',
        'Работа с проблемными активами, арестованными и изъятыми в ходе предварительного следствия;',
        'Уголовно-правовая экспертиза бизнес-проектов (due diligеnce), разработка механизмов предупреждения и минимизации уголовно-правовых рисков менеджмента и бенефициаров',
      ],
      text: (
        <Typography textAlign='justify'>
          Уголовная практика АБ «PRIM Group» реализуется в сферах экономических,
          налоговых, таможенных, экологических и должностных преступлений,
          преступлений, связанных с нарушениями специальных правил (против
          промышленной пожарной безопасности, охраны труда и т.д.), преступлений
          в сфере банкротства, в сфере IT-технологий и против интеллектуальной
          собственности, в области медицинских, страховых, финансовых услуг.
          <br />
          <br />
          Адвокаты АБ «PRIM Group» являются специалистами разных практик, что
          позволяет при оценках уголовно-правовых рисков и защите прав лиц от
          уголовного преследования комплексно разбираться в сложных вопросах
          хозяйственных связей и зачастую противоречивых правилах осуществления
          различных видов бизнеса, формировать позицию защиты и осуществлять
          поиск доказательств, привлекать специалистов иных компетенций для
          проверки обоснованности выводов стороны обвинения, совершать все
          необходимые действия в защиту защиты прав и законных интересов бизнеса
          и физических лиц.
          <br />
          <br />
          АБ «PRIM Group» использует уникальный подход командной работы над
          каждым сопровождаемым проектом. Адвокаты бюро всегда работают сообща и
          подключают весь арсенал знаний, применяя их в различных областях
          функционирования и жизни любого бизнеса.
          <br />
          <br />
          Широкий спектр профессиональных компетенций и значительный опыт защиты
          предпринимателей — то конкурентное преимущество, которое позволяет нам
          уже на протяжении десятилетий эффективно отстаивать интересы бизнеса и
          физических лиц.
          <br />
          <br />
          Сопровождение доверителя всегда осуществляется командой профессионалов
          из разных областей, что позволяет получить объективный анализ
          перспектив конкретного дела и разработать результативную тактику
          защиты.
        </Typography>
      ),
    },
    {
      title: 'Оперативно-розыскная деятельность',
      name: 'Органы, осуществляющие оперативно-розыскную деятельность',
      points: [
        'Органы внутренних дел РФ',
        'Органы ФСБ РФ',
        'Федеральная таможенная служба РФ',
      ],
      text: (
        <Typography textAlign='justify'>
          Оперативно-розыскная деятельность — наименее урегулированная и сложная
          по своему содержанию составная часть всего уголовного процесса, на
          которой строится доказательственная база по уголовным делам.
          <br />
          <br />
          Оперативно-розыскная деятельность осуществляется гласно или
          преимущественно негласно оперативными подразделениями МВД, ФСБ, ФСИН,
          таможенных органов и др. В рамках оперативно-розыскной деятельности
          проводятся такие оперативно-розыскные мероприятия, как: обследование
          помещений, зданий, сооружений участков местности и транспортных
          средств (обследование), прослушивание телефонных переговоров,
          проверочная закупка, контроль почтовых отправлений и другие. Важно
          понимать: перечень этих мероприятий закрытый (полный их перечень
          приведен в статье 6 Федерального закона «Об оперативно-розыскной
          деятельности»), что означает запрет на выход за очерченные законом
          пределы при их проведении и компетенцию правоохранительных органов.
          <br />
          <br />
          Вместе с тем, порядок проведения ряда оперативно-розыскных мероприятий
          регулируется отдельными нормативно-правовыми актами, большинство из
          которых доступно только уполномоченным органам, в связи с чем
          проверить законность проведения оперативно- розыскных мероприятий
          зачастую очень затруднительно. По данной причине при проведении
          оперативно-розыскных мероприятий на практике возникают проблемы,
          связанные с несоблюдением правил производства и фиксации результатов
          оперативно-розыскных мероприятий, и влекущие за собой нарушение прав и
          законных интересов граждан и бизнеса.
          <br />
          <br />
          Практика показывает, что злоупотребления при проведении
          оперативно-розыскных мероприятий могут повлечь негативные последствия
          для бизнеса, а именно утрату ведущей позиции в занимаемой отрасли,
          потерю или заморозку активов, изъятие документов, оргтехники, баз
          данных, распространению коммерческой тайны и др. Наиболее часто такие
          нарушения допускаются в ходе проведения оперативно-розыскного
          мероприятия «Обследование», которое предполагает не только осмотр
          объектов, но и их возможное чрезмерное изъятие.
          <br />
          <br />
          Во избежание наступления этих негативных последствий принципиально
          важна оперативная, эффективная и высококачественная защита.
          <br />
          <br />В рамках осуществления оперативно-розыскной деятельности мы
          оказываем юридическую помощь, в том числе:
          <ul>
            <li>
              Анализ возможных рисков проведения отдельных оперативно-розыскных
              мероприятий и выработка механизма минимизации их последствий для
              Вашего бизнеса;
            </li>
            <li>
              Оперативный выезд на место проведения оперативно-розыскного
              мероприятия «Обследование», участие в нем (в том числе при
              одновременном проведении их по различным адресам), недопущение
              нарушений, в том числе изъятия излишних материальных ценностей и
              документов;
            </li>
            <li>
              Обжалование результатов проведения незаконных оперативно-розыскных
              мероприятий, в том числе с целью оперативного возврата изъятого;
            </li>
            <li>
              Сопровождение в органы, осуществляющие оперативно-розыскную
              деятельность, для дачи объяснений или проведения иных
              оперативно-розыскных мероприятий;
            </li>
          </ul>
        </Typography>
      ),
    },
    {
      title: 'Предварительное расследование (следствие и дознание)',
      name: 'В каких случаях при производстве следственных действий необходимо решение суда',
      points: [
        'Выемка содержащих информацию о счетах граждан в банках и иных кредитных организациях предметов и документов',
        'Осмотр жилища, обыск или выемка',
        'Выемка документов, содержащих медицинскую тайну',
        'Получение информации о соединениях между абонентами, устройствами, сведений о телефонных номерах, IMEI-кодах устройств и иных данных',
        'Арест имущества, находящихся не у фигурантов уголовного дела',
      ],
      text: (
        <Typography textAlign='justify'>
          Что следует знать о следствии? Во-первых, просто так следствие не
          начинается. В основном предпосылкой становится заявление гражданина
          или юридического лица, либо же это результат работы (поисков)
          правоохранительных органов, оформленный в виде рапорта об обнаружении
          признаков преступления.
          <br />
          <br />
          По заявлениям либо рапорту проводится проверка, сроки на которые
          установлены в УПК РФ, по результатам проверки принимается одно из
          решений – возбудить уголовное дело, отказать в возбуждении уголовного
          дела, передать для принятия решения в другой правоохранительный орган,
          которому подследственна данная категория дел.
          <br />
          <br />
          Возбуждение уголовного дела предвосхищается проведением доследственной
          проверки. На ней проводятся опросы граждан, истребуются необходимые
          сведения из государственных и коммерческих структур, проводятся
          осмотры мест происшествия, помещений, документов и так далее.
          <br />
          <br />
          Во-вторых, после возбуждения уголовного дела ситуация меняется.
          Исчезают заявители и очевидцы, появляются свидетели, потерпевшие,
          подозреваемые и обвиняемые. Все они имеют свой статус и,
          соответственно, набор процессуальных прав. Теперь возможно проведений
          следственных действий, в том числе тех, которые запрещено проводить до
          возбуждения уголовного дела – допросы, очные ставки, обыски, выемки,
          следственные эксперименты и др.
          <br />
          <br />
          Главное, что необходимо знать в первую очередь, если получен вызов в
          правоохранительные органы – в какой стадии находится дело или
          материал, какой статус у лица, вызванного в правоохранительный орган,
          соответственно, какой объем прав и обязанностей, от чего следует
          воздержаться, что делать нельзя категорически.
          <br />
          <br />
          Важно знать, что помимо прав для каждого из участников (процессуальный
          статус), существуют общие правила ведения расследования и правила
          проведения отдельных следственных и процессуальных действий. Эти
          правила представляют собой те «красные линии», которые лицу, ведущему
          расследование, переступать нельзя. Знать эти правила необходимо для
          понимания предела власти органов следствия, что позволит эффективно
          отстаивать свою правоту в случае необоснованности обвинения. При этом
          никто не обязан разъяснять и «учить» участников расследования как
          использовать имеющиеся у них права и гарантии.
          <br />
          <br />
          В-третьих, чем все это может закончиться? Самый плохой вариант здесь —
          это возбуждение уголовного дела. По итогам расследования в таком
          случае возможны различные варианты: уголовное дело может быть
          прекращено, либо направлено в суд с обвинительным заключением, тогда
          расследование по делу считается оконченным. Также расследование по
          делу может быть приостановлено, тогда оно не является оконченным.
          <br />
          <br />
          Наконец, уголовное дело может быть прекращено. Прекратить его можно
          как по основаниям реабилитирующим (оправдывающим), так и по основаниям
          нереабилитирующим (когда нельзя полностью оправдать, но можно обойтись
          и без суда). Здесь тоже существуют свои риски: наступление негативных
          последствий возможно при прекращении дела по нереабилитирующим
          обстоятельствам или направлении дела в суд.
          <br />
          <br />
          Вопреки распространённому мнению, каждое дело всегда уникально,
          типовых случаев просто не бывает, поэтому и универсальных методов
          защиты существовать не может. Очень важно профессионально разбираться
          во всех хитросплетениях уголовного процесса, в чём вам поможет только
          квалифицированная помощь адвоката. Мы предоставляем эффективную защиту
          в области уголовного права корпоративным и частным клиентам по делам
          экономической, коррупционной, налоговой, общеуголовной направленности,
          помогаем предотвратить разрушение бизнеса и снизить затраты, связанные
          с уголовным преследованием.
          <br />
          <br />
          Мы оказываем различную юридическую помощь в области уголовно-правовой
          защиты:
          <ul>
            <li>
              представление интересов клиента (его руководителей и работников) в
              ходе доследственных проверок, расследования уголовных дел,
              рассмотрения уголовных дел судами
            </li>
            <li>
              выявление и оценка уголовно-правовых рисков при планировании и
              ведении экономической деятельности
            </li>
            <li>антикоррупционное сопровождение деятельности клиента</li>
            <li>
              защита интересов клиента в делах об административных
              правонарушениях
            </li>
            <li>
              защита интересов клиента при взаимодействии с
              контрольно-надзорными органами
            </li>
            <li>
              консультации по вопросам уголовного и уголовно-процессуального
              права
            </li>
            <li>представление интересов потерпевших по уголовным делам</li>
            <li>
              формирование правовой позиции по делу, определение стратегии и
              тактики поведения
            </li>
            <li>защита интересов клиента в правоохранительных органах</li>
            <li>
              обжалование действий (бездействия) сотрудников правоохранительных
              органов
            </li>
            <li>обжалование судебных актов в судах вышестоящих инстанций</li>
          </ul>
        </Typography>
      ),
    },
    {
      title: 'Суд',
      name: 'В каких случаях суд вынесет оправдательный приговор',
      points: [
        'Не будет установлено событие преступления (сам факт противоправного поведения)',
        'Отсутствие в деяниях состава (совершённое действие не преступно)',
        'Отсутствие заявления потерпевшего, уголовное дело может быть возбуждено не иначе его заявлению',
      ],
      text: (
        <Typography textAlign='justify'>
          Судебную стадию по уголовным делам можно свести по сути к нескольким
          принципиальным этапам:
          <ul>
            <li>
              подготовительный этап, на котором могут решаться вопросы об
              изменении подсудности, о мере пресечения, о проведении
              предварительного слушания с целью прекращения дела по
              нереабилитирующим основаниям, с целью признания доказательств
              недопустимыми и т.д.;
            </li>
            <li>
              судебное следствие, в ходе которого происходит представление и
              исследование доказательств обвинения и защиты;
            </li>
            <li>
              прения сторон, где сторона обвинения и защиты дают итоговое
              видение по делу в части виновности подсудимого, оценки
              доказательств и правильности квалификации, необходимости или ее
              отсутствия удовлетворения гражданского иска и др.;
            </li>
            <li>
              обжалование или поддержание итогового судебного акта (в основном
              приговора), когда дается оценка согласия или несогласия с решением
              суда, исследуется аудио и текстуальный протокол судебного
              заседания на предмет его полноты и правильности отражения,
              подготавливается апелляционная жалоба или возражения на
              жалобы/представления другой стороны.
            </li>
          </ul>
          Каждое уголовное дело уникально, и в зависимости от фактических
          обстоятельств позиция будет различна. Именно поэтому стадия
          рассмотрения дела в суде предполагает целый комплекс процессуальных
          действий адвоката, связанных с формированием и реализацией позиции по
          делу.
          <br />
          <br />
          Принципиальное значение, конечно, имеет чьи интересы представляет
          адвокат – подсудимого или потерпевшего. При участии адвоката в
          качестве защитника подсудимого позиция может сводиться к оправданию
          подсудимого, прекращению уголовного дела, переквалификации деяния на
          менее тяжкое, либо минимизации негативных последствий при назначении
          наказания или иных мер уголовно- правового характера. А задача
          адвоката, участвующего на стороне обвинения, состоит в обеспечении
          скорейшего рассмотрения уголовного дела судом, привлечении виновных к
          уголовной ответственности и назначении им соответствующего наказания,
          возмещении потерпевшему причиненного преступлением вреда в полном
          объеме.
          <br />
          <br />
          Любому, кто столкнулся с работой правоохранительной системы, нужно
          понимать — адвокат не может, и более того, не вправе гарантировать
          конкретный исход дела. Но он позволяет соблюсти установленную законом
          юридическую процедуру, от которой и зависит конечное решение.
          <br />
          <br />
          Адвокаты Адвокатского бюро «PRIM group» имеют большой опыт участия в
          судебных заседаниях при рассмотрении сложных и резонансных уголовных
          дел судами всех инстанций в различных регионах России как при защите
          интересов подсудимых, так и потерпевших.
          <br />
          <br />В зависимости от сложности дела может возникнуть необходимость
          привлечения к защите целой команды узкопрофильных специалистов, с чем
          успешно справляются адвокаты нашего Бюро.
          <br />
          <br />
          На этапе судебного разбирательства наши адвокаты оказывают юридическую
          помощь, в том числе:
          <ul>
            <li>
              Изучение материалов уголовного дела, разработка позиции, выработка
              стратегии и тактики ведения уголовного дела в суде;
            </li>
            <li>
              Подготовка необходимых документов, ходатайств, жалоб и иных
              процессуальных документов;
            </li>
            <li>
              Участие в заседаниях судов всех инстанций и защита интересов
              Доверителя;
            </li>
          </ul>
        </Typography>
      ),
    },
    {
      title: 'Исполнение решения',
      name: 'Что запрещено совершать в отношении осуждённого:',
      points: [
        'Лишать возможности пользоваться помощью адвоката',
        'Проводить различные медицинские, биометрические и иные исследования (даже при их согласии)',
        'Лишать осуждённого возможности общаться и вести переписку на родном языке',
        'Лишать осуждённого доступа к охране здоровья, включая первичную и специализированную медицинскую помощь, а также помощь психолога',
        'Каким-либо образом унижать человеческое достоинство осуждённого, жестокое обращение с людьми – недопустимо.',
      ],
      text: (
        <Typography textAlign='justify'>
          Судебная стадия уголовного процесса завершается исполнением приговора,
          которое именуют также обращением приговора к исполнению. Исполнению
          подлежит не только сам приговор, но и иные ранее принятые судебные
          решения (например, определения и постановления), без которых теряется
          смысл самого приговора.
          <br />
          <br />
          Любой осуждённый имеет ряд возможностей по смягчению условий отбывания
          наказания. Так, например, при исполнении обвинительного приговора по
          ходатайству осужденного и его защитника суд может освободить от
          наказания условно-досрочно (эту меру ещё называют УДО) при отбытии
          части срока. Возможно также заменить часть неотбытого срока наказания
          более мягким видом наказания. К примеру, когда осужденный тяжело
          болен, возможно освобождение от наказания или отсрочка исполнения
          приговора до выздоровления. Если же суд постановил уплатить
          осуждённому в качестве наказания штраф, уплата может быть отсрочена
          или рассрочена на срок до 5 лет, если немедленная уплата для
          осужденного невозможна.
          <br />
          <br />
          Кроме того, в порядке исполнения приговора, после отбытия наказания,
          осужденный вправе обратиться в суд с ходатайством о досрочном снятии
          судимости.
          <br />
          <br />
          С другой стороны, если суд постановил оправдательный приговор (в том
          числе частичный) или прекращено уголовное преследование по
          реабилитирующим основаниям (отсутствие события или состава
          преступления, не доказана причастность к совершению преступления),
          человек вправе подать иск в суд о компенсации морального вреда,
          причиненного в результате незаконного уголовного преследования.
          <br />
          <br />
          Любой осуждённый, какое бы ни было преступление, не может быть лишён
          определенного набора прав, предоставленных ему Конституцией РФ и
          действующим законодательством. Их нельзя лишать медицинской, в том
          числе и психологической помощи, мер социального обеспечения (в том
          числе пенсий и социальных пособий), и многое другое. Он — в первую
          очередь человек и гражданин, хотя и допустивший серьёзную ошибку.
          <br />
          <br />
          В интересах осуждённого обратиться за помощью к квалифицированному
          защитнику, который обеспечит соблюдение всего набора предоставленных
          осуждённому прав, и сделает всё возможное, чтобы исполнение приговора
          суда было максимально смягчено в интересах клиента. Наше бюро имеет
          многолетний опыт взаимодействия с системой исполнения судебных
          приговоров, что позволяет нам сохранять неизменным приоритетом защиту
          прав и свобод каждого гражданина, прошедшего через жернова уголовного
          процесса.
          <br />
          <br />В рамках исполнения судебных решений мы оказываем юридическую
          помощь, в том числе:
          <ul>
            <li>
              Защита прав осуждённых, приговорённых к любым видам
              уголовно-правовых санкций;
            </li>
            <li>
              Подготовка необходимых документов для смягчения отбывания
              наказания;
            </li>
            <li>
              Представление интересов осуждённых при взаимодействии с
              исполнительной системой и иными государственными органами.
            </li>
          </ul>
        </Typography>
      ),
    },
  ],
  block: {
    text1: (
      <Typography textAlign='justify'>
        Несмотря на стабильную статистику обвинительных приговоров, суды при
        постановлении приговора нередко допускают ошибки при квалификации
        действий обвиняемого, а также при назначении наказания. И хотя в
        большинстве случаев по уголовным делам, которые дошли до суда, слишком
        высока вероятность получения обвинительного приговора, это не означает,
        что ситуация безысходна. Любое решение суда всегда можно обжаловать в
        порядке апелляционного, кассационного и надзорного производства. Такая
        система позволяет минимизировать последствия судебных ошибок и не
        допустить вступления неправосудного приговора в законную силу.
        <br />
        <br />
        Обжалование приговора суда первой инстанции производится в апелляционном
        порядке. Пока срок на обжалование не истек, приговор суда первой
        инстанции не вступит в законную силу. Именно в этот момент важно принять
        решение об обжаловании и об обращении к профессионалу за защитой на
        стадии апелляционного пересмотра приговора.
        <br />
        <br />
        <br />
        <br />
        При ошибках суда в квалификации, а также при назначении наказания в
        апелляционном (кассационном) порядке возможно добиться:
        <ul>
          <li>
            переквалификации деяния на статью, предусматривающую менее строгое
            наказание;
          </li>
          <li>
            переквалификации деяния на часть той же статьи, предусматривающую
            менее строгое наказание;
          </li>
          <li>назначение более мягкого наказания из всех возможных;</li>
          <li>замены реального лишения свободы условным;</li>
          <li>назначение наказания ниже низшего предела;</li>
          <li>
            применения только основного наказания без дополнительной санкции и
            т.д.
          </li>
        </ul>
      </Typography>
    ),
    text2: (
      <Typography textAlign='justify'>
        Если же и суд апелляционной инстанции не услышал ваши доводы, можно
        обжаловать решения вплоть до Верховного суда Российской Федерации —
        высшего звена всей судебной системы в России.
        <br />
        <br />
        Кроме того, приговор может быть отменен, а дело направлено на пересмотр
        ввиду новых или вновь открывшихся обстоятельств. Так, под вновь
        открывшимися обстоятельствами понимается какие-либо факты,
        существовавшие в момент вынесения приговора, но неизвестные суду. Под
        новыми обстоятельствами подразумеваются факты, исключающие вину
        гражданина в совершенном деянии, о которых суд не знал в момент
        вынесения приговора. И хотя пересмотр приговоров по новым или вновь
        открывшимся обстоятельствами в пользу осужденного не ограничен сроками,
        крайне важно своевременно обращаться к адвокату за защитой своих прав,
        если, конечно, Вы хотите добиться избавления от налагаемых приговором
        ограничений.
        <br />
        <br />
        Адвокаты АБ PRIM Group имеют успешную практику в наших непростых
        условиях при обжаловании судебных актов. Огромный опыт и большое
        количество прошедших через наших адвокатов резонансных уголовных дел
        позволяет нам эффективно отстаивать интересы наших доверителей. Мы
        оказываем квалифицированную юридическую помощь при оценке законности
        постановленного приговора или иного судебного решения. Главное – это
        своевременно, в установленные законом сроки для обжалования, обратиться
        к адвокату.
        <br />
        <br />
        На этапе пересмотра судебных решений мы оказываем юридическую помощь, в
        том числе:
        <ul>
          <li>
            Изучение приговоров, материалов уголовных дел, оценка перспектив
            обжалования судебных решений, выработка позиции в интересах
            обвиняемого/осужденного
          </li>
          <li>
            Подготовка апелляционных и кассационных жалоб на приговоры (судебные
            постановления, в том числе об избрании меры пресечения в виде
            заключения под стражу или домашнего ареста)
          </li>
          <li>
            Защита доверителя в заседаниях суда апелляционной и кассационной
            инстанции.
          </li>
        </ul>
      </Typography>
    ),
    offers: [
      'Суд апелляционной инстанции',
      'Суд кассационной инстанции',
      'Верховный суд РФ',
    ],
  },
};

// export const generalJurisdictionMock = {
//   first: {
//     name: 'Общая подводка',
//     person: {
//       name: 'Дмитрий Иванов',
//       status: 'Партнёр',
//       email: 'dmivanov@prim.group',
//       photo:
//         'https://downloader.disk.yandex.ru/preview/0b845a53f309c36752b16d0afc7947689660fc8f4c164a00bdae1870aa9f7c2b/66368271/CBxM-YisCnJlR74orLAowdEG8FtHlksaLB_vTeEI9mAi10CQ6A8hEgSRGZ3iZx0ES2fYbdyY4RfhMX2VgJl_Wg%3D%3D?uid=0&filename=%D0%A4%D0%BE%D1%82%D0%BE%202.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=2560x1271',
//     },
//     text1: (
//       <Typography>
//         Каждый, кто участвовал в споре, понимает, насколько необходимо разрешить
//         спор и не превратить его в драку. Хорошо, когда стороны терпеливы и
//         внимательны к аргументам друг друга. Но не всегда сторонам удается
//         договориться. Иногда нет иного выбора, чем обратиться в суд за защитой
//         своих прав и интересов. Непросто понять процедуры, правила и возможности
//         судебной защиты самому, даже используя возможности Google.
//         <br />
//         <br />
//         Адвокаты PRIM group оказывают юридическую помощь при разрешении в судах
//         споров и конфликтов по судебному представительству интересов клиентов в
//         арбитражных судах и судах общей юрисдикции.
//       </Typography>
//     ),
//     text2: (
//       <Typography>
//         Судебное представительство – одна из основных практик в Бюро. Более 20
//         лет назад наше Бюро было создано путем объединения личных практик
//         успешных адвокатов в целях повышения эффективности и расширения
//         возможностей командной работы в интересах защиты прав клиентов в судах.
//         <br />
//         <br />
//         Мы поможем определиться с тактикой и стратегией дела для оценки
//         перспектив похода в суд, с выбором способа защиты, составим все
//         необходимые процессуальные документы, осуществим поиск доказательств для
//         успешного проведения спора со стороны клиента, найдем специалистов и
//         экспертов для решения задач в рамках судебных экспертиз. У нас большой
//         практический опыт в судебном представительстве по различным категориям
//         дел в арбитражных судах и судах общей юрисдикции всех уровней.
//       </Typography>
//     ),
//     text3: (
//       <Typography>
//         Каждый адвокат, юрист, стажер и помощник в нашем Бюро изучает и
//         анализирует судебную практику, основные подходы суда к разрешению той
//         или другой категории споров, мы проводим обсуждения тенденций развития и
//         изменения законодательства, организуем круглые тематические столы для
//         обсуждения вопросов по специальным темам, выступаем на конференциях,
//         форумах, иных мероприятиях в целях широкого обсуждения проблем,
//         возникающих при осуществлении судебного представительства.
//         <br />
//         <br /> Мы используем любую возможность разрешить спор в досудебном
//         порядке, в рамках третейского разбирательства, путем переговоров или
//         медиации. При возможности мирового урегулирования судебного спора
//         поможем выработать и заключить мировое соглашение. Исполнение решения
//         суда не менее значимо для наших клиентов, чем сам судебный поединок. На
//         данной стадии также немало юридических вопросов и мы готовы помочь
//         клиентам в их разрешении.
//       </Typography>
//     ),
//   },
//   second: {
//     name: 'СОЮ',
//     person: {
//       name: 'Дмитрий Иванов',
//       status: 'Партнёр',
//       email: 'dmivanov@prim.group',
//       photo:
//         'https://downloader.disk.yandex.ru/preview/0b845a53f309c36752b16d0afc7947689660fc8f4c164a00bdae1870aa9f7c2b/66368271/CBxM-YisCnJlR74orLAowdEG8FtHlksaLB_vTeEI9mAi10CQ6A8hEgSRGZ3iZx0ES2fYbdyY4RfhMX2VgJl_Wg%3D%3D?uid=0&filename=%D0%A4%D0%BE%D1%82%D0%BE%202.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=2560x1271',
//     },
//     text1: (
//       <Typography>
//         Большинство всех дел, которые доходят до суда, рассматриваются в судах
//         общей юрисдикции. Поэтому юрисдикция и называется общей: рассматривают
//         абсолютное большинство поступающих споров.
//       </Typography>
//     ),
//     text2: (
//       <Typography>
//         Палитра рассматриваемых дел в этих судах очень обширна:
//         <ul>
//           <li>
//             все гражданские и административные дела о защите нарушенных или
//             оспариваемых прав, свобод и охраняемых законом интересов, за
//             исключением дел, которые рассматриваются другими судами (например,
//             арбитражными, или мировыми судьями);
//           </li>
//           <li>все уголовные дела;</li>
//           <li>
//             дела с участием иностранных лиц, судов и иностранных государств,
//           </li>
//           <li>другие дела, которые законом прямо отнесены к их ведению.</li>
//         </ul>
//         Например, в порядке гражданского судопроизводства рассматриваются споры
//         по оспариванию и исполнению сделок (купли-продажи, долевого участия в
//         строительстве, дарения, займа и кредитования), трудовые споры,
//         причинение вреда здоровью, имуществу и различным другим спорам.
//         Административные споры, например, касающиеся нарушения правил дорожного
//         движения, связанные с действиями органов внутренних дел (включая ГИБДД),
//         неверным исчислением уплаченных гражданином налогов, и множество
//         другого.
//       </Typography>
//     ),
//     text3: (
//       <Typography>
//         Все вышеуказанные споры очень хорошо известны не только бизнесу, с ними,
//         пожалуй, может столкнуться любой человек.
//         <br />
//         <br />
//         Успех конкретного дела зависит от множества факторов: солнечной погоды,
//         вкуса выпитого судьёй утреннего кофе… А ещё очень важную роль играет
//         подготовленный адвокатом доказательственный материал, уровень его
//         квалификации, навыки судебной риторики, тактика и стратегия ведения
//         дела. И эти задачи адвокаты Бюро решают на высшем уровне.
//       </Typography>
//     ),
//     cards: [
//       {
//         title: 'Какие споры рассматривают суды общей юрисдикции?',
//         content: [
//           'Гражданские споры между гражданами',
//           'Трудовые споры',
//           'Административные споры',
//           'Все уголовные дела',
//           'Налоговые споры в отношении взимаемых с физических лиц налогов',
//         ],
//       },
//       {
//         title:
//           'Какие судебные расходы можно взыскать с противоположной стороны?',
//         content: [
//           'Государственная пошлина',
//           'Расходы на услуги адвоката',
//           'Стоимость экспертизы',
//           'Расходы на услуги переводчика, специлиста или эксперта',
//           'Почтовые расходы',
//           'Иные суммы, которые суд признает необходимыми',
//         ],
//       },
//       {
//         title: 'Какие факты не подлежат доказыванию?',
//         content: [
//           'Общеизвестные факты (проведение выборов, проведение массового мероприятия, боевые действия',
//           'Преюдициальные факты (обстоятельства, установленные вступившим в законную силу судебным постановлением по ранее рассмотренному делу)',
//           'обстоятельства, подтвержденные нотариусом при совершении нотариального действия',
//         ],
//       },
//     ],
//   },
//   third: {
//     name: 'Арбитражные споры',
//     person: {
//       name: 'Дмитрий Иванов',
//       status: 'Партнёр',
//       email: 'dmivanov@prim.group',
//       photo:
//         'https://downloader.disk.yandex.ru/preview/0b845a53f309c36752b16d0afc7947689660fc8f4c164a00bdae1870aa9f7c2b/66368271/CBxM-YisCnJlR74orLAowdEG8FtHlksaLB_vTeEI9mAi10CQ6A8hEgSRGZ3iZx0ES2fYbdyY4RfhMX2VgJl_Wg%3D%3D?uid=0&filename=%D0%A4%D0%BE%D1%82%D0%BE%202.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=2560x1271',
//     },
//     text1: (
//       <Typography>
//         Арбитражные споры существенно отличаются от любых других споров. Самое
//         важное — арбитражное судопроизводство предназначено для рассмотрения
//         споров между предпринимателями. При этом, здесь рассматриваются только
//         административные и гражданские споры, тогда как уголовные споры, к
//         примеру, остаются в компетенции судов общей юрисдикции.
//         <br />
//         <br />
//         Другая особенность состоит в том, что судьи этих судов зачастую гораздо
//         требовательнее к представляемым доказательствам, при чём не только к их
//         содержанию, но и к тому, как они представлены в судебном заседании.
//       </Typography>
//     ),
//     text2: (
//       <Typography>
//         Помимо общегражданских споров Арбитражные суды рассматривают и более
//         узкие виды споров: дела о признании предприятия банкротом, различного
//         рода корпоративные споры, когда собственники бизнеса не могут поделить
//         совместно заработанные деньги, инвестиционные споры, связанные с
//         порядком инвестирования в различные проекты и возвратом инвестиционного
//         дохода, и другие. Ещё более специфичны споры, связанные с защитой
//         интеллектуальной собственности, регистрации и использования товарных
//         знаков и коммерческих обозначений, нарушений прав авторов литературных,
//         художественных, музыкальных произведений, изобретателей и другие. Их
//         рассматривает Суд по интеллектуальным правам, который, хотя и входит в
//         систему арбитражных судов, но в силу специфики рассматриваемых споров
//         занимает особое место в судебной системе.
//       </Typography>
//     ),
//     text3: (
//       <Typography>
//         Арбитражные споры, как, в прочем, и любой другой спор, никогда не бывает
//         простым и однозначным. Очень многое будет зависеть от того, каким
//         образом представлены доказательства, что они из себя представляют, как
//         они доведены до сведения суда, и будут ли восприняты должным образом.
//         Претворение этого в жизнь невозможно без должной подготовки и
//         профессионального опыта. Адвокаты АБ «Prim Group» ежедневно развивают
//         свои профессиональные компетенции, и применяют многолетний опыт, и
//         всегда делятся им друг с другом. К любому спору мы подключаем весь
//         имеющийся в нашем распоряжении профессиональный ресурс, именно это
//         позволяет эффективно достигать поставленных нашими доверителями задач.
//       </Typography>
//     ),
//     cards: [
//       {
//         title: 'Какие арбитражные суды существуют в России?',
//         content: [
//           'Суды субъектов (нижнее звено системы арбитражных судов)',
//           'Арбитражные апелляционные суды',
//           'Федеральные арбитражные суды округов (кассационная инстанция)',
//           'Верховный суд РФ возглавляет систему арбитражных судов',
//           'Суд по интеллектуальным правам (специализированный суд)',
//         ],
//       },
//       {
//         title: 'Какое решение может вынести суд по итогам рассмотрения дела?',
//         content: [
//           'Удовлетворить исковые требования полностью или в части',
//           'Отказать в удовлетворении требований полностью или в части',
//           'Утвердить мировое соглашение между кредитором и должником',
//           'Решение о введении определенной процедуры банкротства в отношении банкротящегося предприятия',
//           'Обязать ответчика совершить определённые в решении действия',
//           'Признать исполнительный документ или иной документ неприменимым',
//           'Признать договор заключённым или изменённым',
//         ],
//       },
//       {
//         title: 'Чем отличается арбитражный суд от суда общей юрисдикции?',
//         content: [
//           'Арбитражные суды рассматривают споры между юридическими лицами, ИП, инвесторами, а также дела о банкротстве.',
//           'Арбитражные суды при проведении судебных заседаний руководствуются АПК РФ, а не ГПК РФ.',
//           'Самая первая ступень системы арбитражных судов — не районный суд города, а Арбитражный суд субъекта.',
//         ],
//       },
//     ],
//   },
//   fourth: {
//     name: 'Составление иска – с чего начать',
//     person: {
//       name: 'Дмитрий Иванов',
//       status: 'Партнёр',
//       email: 'dmivanov@prim.group',
//       photo:
//         'https://downloader.disk.yandex.ru/preview/0b845a53f309c36752b16d0afc7947689660fc8f4c164a00bdae1870aa9f7c2b/66368271/CBxM-YisCnJlR74orLAowdEG8FtHlksaLB_vTeEI9mAi10CQ6A8hEgSRGZ3iZx0ES2fYbdyY4RfhMX2VgJl_Wg%3D%3D?uid=0&filename=%D0%A4%D0%BE%D1%82%D0%BE%202.jpg&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=2560x1271',
//     },
//     text1: (
//       <Typography>
//         Перспективу судебного спора для истца определяет его подготовка. Этой
//         стадии нельзя отводить второстепенную или формальную роль.
//         Первостепенная задача - найти профессионального советника по правовым
//         вопросам и обсудить с ним все обстоятельства спорной ситуации. Оценка
//         перспектив дела, рисков, выработка стратегии и правовой позиции в
//         дальнейшем во многом предопределяют результат.
//         <br />
//         <br />
//         Больной вопрос для всех судебных представителей – это степень
//         достоверности обстоятельств, которые им предоставляют доверители. Тут мы
//         не оригинальны и сможем только повторить известное – нельзя врать своем
//         врачу и адвокату. Если не возникло к адвокату доверие – найдите другого
//         профессионального советника по правовым вопросам, которому будете
//         доверять.
//       </Typography>
//     ),
//     text2: (
//       <Typography>
//         Обязанность хранения адвокатской тайны это не пустая декларация, это
//         гарантия защиты клиента. Строгая конфиденциальность не только всех
//         сведений, полученных адвокатом от своего клиента, но даже самого факта
//         обращения к адвокату, принятие мер по защите адвокатской тайны являются
//         приоритетами работы нашего Бюро.
//         <br />
//         <br />
//         После обсуждения обстоятельств дела, определения правовой позиции,
//         необходимо собрать и закрепить в определенной форме доказательства в
//         подтверждение обоснованности требований истца. Сбор доказательств –
//         процесс, требующий не только опыта, но и вдумчивости. Доказательства,
//         как правило, на поверхности не лежат, напротив, часто скрываются или
//         неизвестны по различным причинам. Соответственно, перед адвокатом стоит
//         задача определить способ доказывания, перечень доказательств (письменные
//         или электронные документы, свидетельские показания, видео- и аудиозаписи
//         и др.), а также возможность поиска и сбора доказательств самостоятельно
//         или с помощью суда.
//         <br />
//         <br />
//         Логичное описание в иске конкретных фактов, которыми истец обосновывает
//         свои требования о восстановлении нарушенных прав и законных интересов,
//         подтверждение изложенных в иске оснований прилагаемыми к иску
//         доказательствами, является одной из гарантий успешного рассмотрения
//         спора в пользу заявителя. Поэтому составление иска является важной
//         стадией на пути инициирования судебного спора.
//         <br />
//         <br />
//         Направление иска в суд, выбор места, времени, ситуации, процессуально-
//         распорядительные действия суда и принимаемые им на стадии принятия иска
//         процессуальные решения - всегда зависят от конкретных обстоятельств
//         спорной ситуации и должны укладываться в общую стратегию, выработанную
//         клиентом со своим адвокатом.
//       </Typography>
//     ),
//     text3: (
//       <Typography>
//         Помните, что первое впечатление формируется из заявленного вами иска –
//         из его содержания, оснований, требований и представленных первоначально
//         с иском доказательств. Опытные судебные представители понимают, что со
//         стадии составления иска начинается борьба за первое положительное
//         впечатление судьи в пользу истца.
//         <br />
//         <br />
//         Краткость – по-прежнему сестра таланта, и сложность в простоте. Первое
//         правило хорошего иска – умение сложных явлений и обстоятельств описывать
//         простыми словами и немногословными предложениями. Адвокатами нашего Бюро
//         составлено и направлено в суды множество исков, множество споров
//         завершены в пользу наших клиентов, однако задачу привлечения внимания
//         судьи, среднестатистическая нагрузка на которого очень высока и
//         вынуждает его ежедневно прочитывать гору бумаг, мы решаем при
//         составлении каждого иска.
//         <br />
//         <br />
//         Четкая структура иска представляет собой выделение и исследование
//         значимых фактов, краткое описание второстепенных доказательственных
//         фактов. Истцу, как правило, всегда есть на что пожаловаться в суде,
//         однако внимание судьи следует обращать только на значимые для
//         заявленного иска факты.
//         <br />
//         <br />
//         Зачастую доверители склоняют адвокатов обратить внимание на
//         обстоятельства, имеющие для них субъективный сильный эмоциональный
//         интерес. Но многие из этих фактов не имеют отношения к предмету спора,
//         поэтому задача судебного представителя – объяснить и убедить клиента в
//         ненужности дополнительных эмоций, затрудняющих рассмотрение спора.
//       </Typography>
//     ),
//   },
// };
