import tagAPIRoutes from 'apiRoutes/tag';
import {
  createOrEditFetching,
  createOrEditFetchingError,
  createOrEditFetchingSuccess,
  getTagsFetching,
  getTagsFetchingError,
  getTagsFetchingSuccess,
} from 'store/slices/tagSlices';
import getResponse from 'utils/getResponse';

export const getTags = () => async (dispatch) => {
  try {
    dispatch(getTagsFetching());

    const response = await tagAPIRoutes.getTagSelectList();
    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(getTagsFetchingSuccess(data));
    } else {
      dispatch(getTagsFetchingError(errors[0].value));
    }
  } catch (e) {
    dispatch(getTagsFetchingError(e.message));
  }
};

export const createOrEditTags = (form) => async (dispatch) => {
  try {
    dispatch(createOrEditFetching());

    const response = await tagAPIRoutes.tagCreateOrEdit(form);

    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(createOrEditFetchingSuccess(data));
      dispatch(getTags());
    } else {
      dispatch(createOrEditFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(createOrEditFetchingError(e.message));
  }
};

export const tagRemove = (form) => async (dispatch) => {
  try {
    dispatch(createOrEditFetching());

    const response = await tagAPIRoutes.tagRemove(form);
    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(createOrEditFetchingSuccess(data));
      dispatch(getTags());
    } else {
      dispatch(createOrEditFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(createOrEditFetchingError(e.message));
  }
};
