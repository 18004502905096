import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';

const Content = styled(Box)(({ theme }) => ({
  textAlign: 'end',
  '& span': {
    fontWeight: 'bold',
  },
}));

const FooterContacts = () => {
  return (
    <>
      <Content mb='48px' mt='32px'>
        <Typography mb='16px' variant='body2'>
          <span>Россия, 634041, г. Томск,</span>
          <br />
          ул. Белинского, д. 29, оф. 16
        </Typography>
        <Typography mb='16px' variant='body2'>
          <span>Тел.:</span> +7 (3822) 56-41-84
          <br />
          +7 (3822) 56-37-11
          <br />
          +7 (3822) 56-36-80
        </Typography>
        <Typography variant='body2'>
          <span>Email:</span> tomsk@prim.group
        </Typography>
      </Content>
      <Content sx={{ textAlign: 'end' }}>
        <Typography mb='16px' variant='body2'>
          <span>Россия, 639091, г. Новосибирск,</span>
          <br />
          ул. Романова, д. 39, оф. 200
        </Typography>
        <Typography mb='16px' variant='body2'>
          <span>Тел.:</span> +7 (383) 209-12-23
        </Typography>
        <Typography mb='16px' variant='body2'>
          <span>Email:</span> nsk@prim.group
        </Typography>
      </Content>
    </>
  );
};

export default FooterContacts;
