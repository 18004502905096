import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  team: [],
  isLoadingTeam: false,
  errorTeam: '',

  member: {},
  isLoadingMember: false,
  errorMember: '',

  memberEditInfo: {},
  isLoadingMemberEditInfo: false,
  errorMemberEditInfo: '',

  isLoadingEdit: false,
  editError: '',

  memberContent: [],
  isLoadingMemberContent: false,
  errorContent: '',

  memberPublications: [],
  isLoadingMemberPublications: false,
  errorPublications: '',

  memberInterestingCases: [],
  isLoadingMemberInterestingCases: false,
  errorInterestingCases: '',

  memberComments: [],
  isLoadingMemberComments: false,
  errorComments: '',

  memberReview: [],
  isLoadingMemberReview: false,
  errorReview: '',
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    getTeamFetching: (state) => {
      state.isLoadingTeam = true;
    },
    getTeamFetchingSuccess: (state, action) => {
      state.isLoadingTeam = false;
      state.errorTeam = '';
      state.team = action.payload?.memberCards;
    },
    getTeamFetchingError: (state, action) => {
      state.isLoadingTeam = false;
      state.errorTeam = action.payload;
      state.team = [];
    },

    clearMember: (state) => {
      state.isLoadingMember = false;
      state.errorMember = '';
      state.member = {};
    },
    getMemberFetching: (state) => {
      state.isLoadingMember = true;
    },
    getMemberFetchingSuccess: (state, action) => {
      state.isLoadingMember = false;
      state.errorMember = '';
      state.member = action.payload;
    },
    getMemberFetchingError: (state, action) => {
      state.isLoadingMember = false;
      state.errorMember = action.payload;
      state.member = {};
    },

    getMemberEditInfoFetching: (state) => {
      state.isLoadingMemberEditInfo = true;
    },
    getMemberEditInfoFetchingSuccess: (state, action) => {
      state.isLoadingMemberEditInfo = false;
      state.errorMemberEditInfo = '';
      state.memberEditInfo = action.payload;
    },
    getMemberEditInfoFetchingError: (state, action) => {
      state.isLoadingMemberEditInfo = false;
      state.errorMemberEditInfo = action.payload;
      state.memberEditInfo = {};
    },

    createOrUpdateMemberFetching: (state) => {
      state.isLoadingEdit = true;
    },
    createOrUpdateMemberFetchingSuccess: (state, action) => {
      state.isLoadingEdit = false;
      state.editError = '';
    },
    createOrUpdateMemberFetchingError: (state, action) => {
      state.isLoadingEdit = false;
      state.editError = action.payload;
    },

    clearAllMemberMaterials: (state) => {
      state.isLoadingMemberContent = false;
      state.isLoadingMemberPublications = false;
      state.isLoadingMemberInterestingCases = false;
      state.isLoadingMemberComments = false;
      state.isLoadingMemberReview = false;
      state.errorContent = '';
      state.errorPublications = '';
      state.errorInterestingCases = '';
      state.errorComments = '';
      state.errorReview = '';
      state.memberContent = [];
      state.memberPublications = [];
      state.memberInterestingCases = [];
      state.memberComments = [];
      state.memberReview = [];
    },
    //
    getMemberContentFetching: (state) => {
      state.isLoadingMemberContent = true;
    },
    getMemberContentFetchingSuccess: (state, action) => {
      state.isLoadingMemberContent = false;
      state.errorContent = '';
      state.memberContent = action.payload;
    },
    getMemberContentFetchingError: (state, action) => {
      state.isLoadingMemberContent = false;
      state.errorContent = action.payload;
      state.memberContent = {};
    },
    //
    getMemberPublicationsFetching: (state) => {
      state.isLoadingMemberPublications = true;
    },
    getMemberPublicationsFetchingSuccess: (state, action) => {
      state.isLoadingMemberPublications = false;
      state.errorPublications = '';
      state.memberPublications = action.payload;
    },
    getMemberPublicationsFetchingError: (state, action) => {
      state.isLoadingMemberPublications = false;
      state.errorPublications = action.payload;
      state.memberPublications = {};
    },
    //
    getMemberInterestingCasesFetching: (state) => {
      state.isLoadingMemberInterestingCases = true;
    },
    getMemberInterestingCasesFetchingSuccess: (state, action) => {
      state.isLoadingMemberInterestingCases = false;
      state.errorInterestingCases = '';
      state.memberInterestingCases = action.payload;
    },
    getMemberInterestingCasesFetchingError: (state, action) => {
      state.isLoadingMemberInterestingCases = false;
      state.errorInterestingCases = action.payload;
      state.memberInterestingCases = {};
    },
    //
    getMemberCommentsFetching: (state) => {
      state.isLoadingMemberComments = true;
    },
    getMemberCommentsFetchingSuccess: (state, action) => {
      state.isLoadingMemberComments = false;
      state.errorComments = '';
      state.memberComments = action.payload;
    },
    getMemberCommentsFetchingError: (state, action) => {
      state.isLoadingMemberComments = false;
      state.errorComments = action.payload;
      state.memberComments = {};
    },
    //
    getMemberReviewFetching: (state) => {
      state.isLoadingMemberReview = true;
    },
    getMemberReviewFetchingSuccess: (state, action) => {
      state.isLoadingMemberReview = false;
      state.errorReview = '';
      state.memberReview = action.payload;
    },
    getMemberReviewFetchingError: (state, action) => {
      state.isLoadingMemberReview = false;
      state.errorReview = action.payload;
      state.memberReview = {};
    },
  },
});

export const {
  getTeamFetching,
  getTeamFetchingSuccess,
  getTeamFetchingError,
  createOrUpdateMemberFetching,
  createOrUpdateMemberFetchingSuccess,
  createOrUpdateMemberFetchingError,
  clearMember,
  getMemberFetching,
  getMemberFetchingSuccess,
  getMemberFetchingError,
  getMemberEditInfoFetching,
  getMemberEditInfoFetchingSuccess,
  getMemberEditInfoFetchingError,
  // materials
  clearAllMemberMaterials,
  getMemberContentFetching,
  getMemberContentFetchingSuccess,
  getMemberContentFetchingError,
  getMemberPublicationsFetching,
  getMemberPublicationsFetchingSuccess,
  getMemberPublicationsFetchingError,
  getMemberInterestingCasesFetching,
  getMemberInterestingCasesFetchingSuccess,
  getMemberInterestingCasesFetchingError,
  getMemberCommentsFetching,
  getMemberCommentsFetchingSuccess,
  getMemberCommentsFetchingError,
  getMemberReviewFetching,
  getMemberReviewFetchingSuccess,
  getMemberReviewFetchingError,
} = teamSlice.actions;

export default teamSlice.reducer;
