import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';

import { Swiper, SwiperSlide } from 'swiper/react';
import { styled } from '@mui/system';
import photo1 from './../../../assets/img/group-photos/Груп.фото 1.jpg';
import photo2 from './../../../assets/img/group-photos/Груп.фото 2.jpg';
import photo3 from './../../../assets/img/group-photos/Груп.фото 3.jpg';

import general1 from './../../../assets/img/group-photos/Общая 1.jpg';
import general2 from './../../../assets/img/group-photos/Общая 2.jpg';
import general3 from './../../../assets/img/group-photos/Общая 3.jpg';

import { Autoplay } from 'swiper';

const photos = [
  [photo1, photo2, photo3],
  [general1, general2, general3],
];

const Img = styled('div')(({ theme, imgUrl }) => ({
  borderRadius: '36px',
  width: 'auto',
  backgroundColor: 'white',
  backgroundImage: `url('${imgUrl}')`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '70vw',
  [theme.breakpoints.up('ssm')]: {
    height: '400px',
  },
  [theme.breakpoints.up('sm')]: {
    height: '33vw',
  },
  [theme.breakpoints.up('mmd')]: {
    height: '30vw',
  },
  [theme.breakpoints.up('md')]: {
    height: '28vw',
  },
  [theme.breakpoints.up('lg')]: {
    height: '25vw',
  },
  [theme.breakpoints.up('xl')]: {
    height: '400px',
  },
}));

export const PhotosBlock = ({ photosBlockNumber = 0 }) => {
  const theme = useTheme();
  const greaterThanXl = useMediaQuery(theme.breakpoints.up('xl'));
  const greaterThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const greaterThanMmd = useMediaQuery(theme.breakpoints.up('mmd'));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const greaterThanSsm = useMediaQuery(theme.breakpoints.up('ssm'));
  return (
    <Swiper
      className='mySwiper swiperOverflow'
      slidesPerView={
        greaterThanXl
          ? 2.4
          : greaterThanLg
          ? 2.4
          : greaterThanMd
          ? 2
          : greaterThanMmd
          ? 1.8
          : greaterThanSm
          ? 1.8
          : greaterThanSsm
          ? 1
          : 1
      }
      style={{ cursor: 'grab' }}
      spaceBetween={40}
      modules={[Autoplay]}
      speed={3000}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
    >
      {photos[photosBlockNumber]?.map((el, idx) => (
        <SwiperSlide key={idx}>
          <Img imgUrl={el} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
