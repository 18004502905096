import { Swiper, SwiperSlide } from 'swiper/react';
import { styled } from '@mui/system';

import 'swiper/css';
import 'swiper/css/pagination';

import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { Autoplay } from 'swiper';

const photos = process.env.REACT_APP_MAIN_PHOTOS.split(', ');
const host = process.env.REACT_APP_YANDEXHOST;

const Img = styled('div')(({ theme, imgUrl }) => ({
  borderRadius: '36px',
  height: '400px',
  width: 'auto',
  backgroundColor: 'white',
  backgroundImage: `url(${imgUrl})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
}));

const GalleryBlock = () => {
  const theme = useTheme();
  const greaterThanXl = useMediaQuery(theme.breakpoints.up('xl'));
  const greaterThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const greaterThanMmd = useMediaQuery(theme.breakpoints.up('mmd'));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const greaterThanSsm = useMediaQuery(theme.breakpoints.up('ssm'));

  return (
    <>
      <Swiper
        className='mySwiper swiperOverflow'
        slidesPerView={
          greaterThanXl
            ? 2.4
            : greaterThanLg
            ? 2.4
            : greaterThanMd
            ? 2
            : greaterThanMmd
            ? 1.8
            : greaterThanSm
            ? 1.8
            : greaterThanSsm
            ? 1
            : 1
        }
        style={{ cursor: 'grab' }}
        spaceBetween={40}
        modules={[Autoplay]}
        speed={3000}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
      >
        {photos.map((el, idx) => (
          <SwiperSlide key={idx}>
            <Img imgUrl={host + el} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default GalleryBlock;
