import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PaperBlank from 'elements/UI/PaperBlank';
import StrokeWrapper from 'elements/UI/StrokeWrapper';
import { useNavigate } from 'react-router-dom';
import { practiceArticlesModel } from 'pages/Practice/model';

const Practices = () => {
  const navigate = useNavigate();
  const handleClick = (practice) => {
    navigate(
      '/practices/' +
        encodeURI(practice.name) +
        (practice.toPathAnchor ? '#' + practice.toPathAnchor : '')
    );
  };
  return (
    <>
      <Grid container spacing='32px' justifyContent='center'>
        {practiceArticlesModel.map((el, idx) => (
          <Grid item xs={12} sm={6} key={idx}>
            <StrokeWrapper height='100%'>
              <PaperBlank
                onClick={() => handleClick(el)}
                sx={{
                  cursor: 'pointer',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    padding: { xs: '24px', md: '32px 40px' },
                    minHeight: { xs: '30px', md: '122px' },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant='h6' textAlign='center'>
                    {el.title}
                  </Typography>
                </Box>
              </PaperBlank>
            </StrokeWrapper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Practices;
