import { Input as MuiInput, styled } from '@mui/material';

const StyledInput = styled('input')(({ theme }) => ({
  background: 'inherit',
  border: '2px solid #FFFFFF',
  borderRadius: '8px',
  padding: '16px 32px',
  fontSize: '24px',
  lineHeight: '29px',
  outline: 'none',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}));

const Input = (props) => {
  return <StyledInput placeholder='Введите ваш email' {...props} />;
};

export default Input;
