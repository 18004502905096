import { Box, Typography } from '@mui/material';
import CommentTextfield from 'elements/UI/CommentTextfield';
import Comment from './Comment';
import { useAuth } from 'hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getCommentList, removeComment } from 'store/actions/articleActions';
import { clearCommentList } from 'store/slices/articleSlices';
import { declOfNum } from 'utils/declOfNum';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import ArrowButton from 'elements/UI/ArrowButton';

const CommentSection = ({ articleId = null }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [commentToDel, setCommentToDel] = useState(false);

  const { isAuth } = useAuth();

  const {
    commentList,
    commentPageMetaData,
    isLoadingCommentList,
    isLoadingCommentEdit,
  } = useSelector((state) => state.article);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCommentList());
    dispatch(getCommentList({ articleId: articleId, page: 1 }));

    return () => {
      dispatch(clearCommentList());
    };
  }, [dispatch, articleId]);

  const loadMore = () => {
    dispatch(
      getCommentList({
        articleId: articleId,
        page: commentPageMetaData?.pageNumber + 1,
      })
    );
  };

  const onDelete = (id) => {
    setCommentToDel(id);
    setIsDeleteModalOpen(true);
  };

  const submitDelete = async () => {
    let form = { id: commentToDel };
    try {
      const { ok } = await dispatch(removeComment(form));
      if (ok) {
        await dispatch(clearCommentList());
        await dispatch(getCommentList({ articleId: articleId, page: 1 }));
        setIsDeleteModalOpen(false);
        setCommentToDel(null);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <Box mt='48px'>
      <Typography variant='h4'>
        {commentPageMetaData?.totalItemCount ?? 0}{' '}
        {declOfNum(commentPageMetaData?.totalItemCount ?? 0, [
          'комментарий',
          'комментария',
          'комментариев',
        ])}
      </Typography>
      <CommentTextfield articleId={articleId} />
      {commentList.map((el, index) => (
        <Comment
          comment={el}
          key={'comment' + index}
          canBeDeleted={isAuth}
          onDelete={onDelete}
        />
      ))}
      {commentPageMetaData?.hasNextPage ? (
        <Box sx={{ mt: '48px' }}>
          <ArrowButton
            fullWidth={true}
            withoutArrow={true}
            onClick={loadMore}
            disabled={isLoadingCommentList}
          >
            <Typography variant='h6Normal'>
              Ещё <span>↓</span>
            </Typography>
          </ArrowButton>
        </Box>
      ) : null}
      {isAuth ? (
        <ConfirmModal
          isOpen={isDeleteModalOpen}
          onSubmit={submitDelete}
          onClose={() => setIsDeleteModalOpen(false)}
          isLoading={isLoadingCommentEdit}
        />
      ) : null}
    </Box>
  );
};

export default CommentSection;
