import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Breadcrumbs from 'elements/UI/Breadcrums';
import Container from 'elements/UI/Container';
import PageContainer from 'elements/UI/PageContainer';
import { practiceArticlesModel } from 'pages/Practice/model';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setSelectedTagForSearch } from 'store/slices/articleSlices';
import { toggleSearch } from 'store/slices/uiSlices';

const CustomLink = ({
  name,
  to,
  variant = 'body1',
  component = Link,
  onClick = () => {},
}) => (
  <Typography
    variant={variant}
    component={component}
    to={to}
    sx={{
      textDecoration: 'none',
      color: 'black',
      fontWeight: '300',
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    {name}
  </Typography>
);

const SitemapPage = () => {
  const { team } = useSelector((state) => state.team);
  const { tags } = useSelector((state) => state.tag);
  const dispatch = useDispatch();
  return (
    <PageContainer>
      <Container>
        <Breadcrumbs
          links={[{ name: 'Главная', to: 'main' }, { name: 'Карта сайта' }]}
          mb='64px'
        />
        <CustomLink name='Главная' to='/main' variant='h3' />
        <Box mt='24px' pl={{ xs: '24px' }}>
          <CustomLink name='Команда' to='/team' variant='h3' />
          <Box mt='16px' pl={{ xs: '24px' }}>
            <Grid container rowSpacing='12px' columnSpacing='24'>
              {team?.map((el, idx) => (
                <Grid item xs={12} sm={6} md={4} key={idx}>
                  <CustomLink
                    name={`• ${[el.firstName, el.middleName, el.lastName].join(
                      ' '
                    )}`}
                    to={'/team/' + el.id}
                    variant='h7'
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Box mt='24px' pl={{ xs: '24px' }}>
          <CustomLink name='Практики' to='/practices' variant='h3' />
          <Box mt='16px' pl={{ xs: '24px' }}>
            <Grid container rowSpacing='12px' columnSpacing='24'>
              {practiceArticlesModel.map((el, idx) => (
                <Grid item xs={12} sm={6} md={4} key={idx}>
                  <CustomLink
                    name={`• ${el.title}`}
                    to={
                      '/practices/' +
                      encodeURI(el.name) +
                      (el.toPathAnchor ? '#' + el.toPathAnchor : '')
                    }
                    variant='h7'
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box mt='24px' pl={{ xs: '24px' }}>
          <CustomLink name='Контакты' to='/contacts' variant='h3' />
        </Box>

        <Box mt='24px' pl={{ xs: '24px' }}>
          <CustomLink name='Блог' to='/blog' variant='h3' />
          <Box mt='16px' pl={{ xs: '24px' }}>
            <Grid container rowSpacing='12px' columnSpacing='24'>
              {tags?.map((el, idx) => (
                <Grid item xs={12} sm={6} md={4} key={idx}>
                  <CustomLink
                    name={`• ${el.name}`}
                    to=''
                    component={Box}
                    variant='h7'
                    onClick={(e) => {
                      dispatch(setSelectedTagForSearch(el));
                      dispatch(toggleSearch());
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
    </PageContainer>
  );
};

export default SitemapPage;
