import { Box } from '@mui/material';
import { styled } from '@mui/system';
import Lottie from 'lottie-react';
import loader from 'assets/lottie/Preloader.json';

const Loader = ({ ...other }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '300px',
      }}
    >
      <Lottie
        animationData={loader}
        loop={true}
        autoPlay={true}
        style={{ width: '120px', height: '120px' }}
      />
    </Box>
  );
};

export default Loader;
