import api from '../http/index';

const loginAPIRoutes = {
  login: (data) =>
    api.post('/Account/Login', JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
};

export default loginAPIRoutes;
