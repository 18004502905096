import Template from 'elements/components/Template';
import ArticlePage from 'pages/Article';
import BlogPage from 'pages/Blog';
import ContactsPage from 'pages/Contacts';
import EmailPage from 'pages/Email';
import EmployeePage from 'pages/Employee';
import EventsPage from 'pages/Events';
import LoginPage from 'pages/Login';
import MainPage from 'pages/Main';
import NotFoundPage from 'pages/NotFound';
import PracticePage from 'pages/Practice';
import PracticesPage from 'pages/Practices';
import SitemapPage from 'pages/Sitemap';
import TeamPage from 'pages/Team';
import { Navigate } from 'react-router-dom';

export const routes = [
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/',
    element: <Template />,
    children: [
      {
        path: '',
        element: <Navigate to='/main' />,
      },
      {
        path: 'main',
        element: <MainPage />,
      },
      {
        path: 'practices',
        element: <PracticesPage />,
      },
      {
        path: 'practices/:nameId',
        element: <PracticePage />,
      },
      {
        path: 'contacts',
        element: <ContactsPage />,
      },
      {
        path: 'team',
        element: <TeamPage />,
      },
      {
        path: 'team/:id',
        element: <EmployeePage />,
      },
      {
        path: 'sitemap',
        element: <SitemapPage />,
      },
      {
        path: 'article/:id',
        element: <ArticlePage />,
      },
      {
        path: 'blog',
        element: <BlogPage />,
      },
      {
        path: 'events',
        element: <EventsPage />,
      },
      {
        path: '404',
        element: <NotFoundPage />,
      },
      {
        path: 'emails',
        element: <EmailPage />,
      },
      {
        path: '*',
        element: <Navigate to='/404' replace />,
      },
    ],
  },
];
