import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  emails: [],
  isLoading: false,
  error: '',

  isLoadingCreate: false,
  createError: '',
};

export const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    getEmailsFetching: (state) => {
      state.isLoading = true;
    },
    getEmailsFetchingSuccess: (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.emails = action.payload;
    },
    getEmailsFetchingError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.emails = [];
    },

    createFetching: (state) => {
      state.isLoadingCreate = true;
    },
    createFetchingSuccess: (state, action) => {
      state.isLoadingCreate = false;
      state.createError = '';
    },
    createFetchingError: (state, action) => {
      state.isLoadingCreate = false;
      state.createError = action.payload;
    },
  },
});

export const {
  getEmailsFetching,
  getEmailsFetchingSuccess,
  getEmailsFetchingError,
  createFetching,
  createFetchingSuccess,
  createFetchingError,
} = emailSlice.actions;

export default emailSlice.reducer;
