import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { EmployeeItemVertical } from 'elements/components/EmployeeItem';
import { mockTeam } from 'mock';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { useNavigate } from 'react-router-dom';
import { Autoplay } from 'swiper';

const TeamBlock = ({ team }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const greaterThanXl = useMediaQuery(theme.breakpoints.up('xl'));
  const greaterThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const greaterThanMmd = useMediaQuery(theme.breakpoints.up('mmd'));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const greaterThanSsm = useMediaQuery(theme.breakpoints.up('ssm'));

  return (
    <>
      <Typography variant='h2' mb='64px'>
        Команда
      </Typography>
      <Swiper
        className='mySwiper swiperOverflow'
        slidesPerView={
          greaterThanXl
            ? 4
            : greaterThanLg
            ? 4
            : greaterThanMd
            ? 3.5
            : greaterThanMmd
            ? 3.1
            : greaterThanSm
            ? 2.5
            : greaterThanSsm
            ? 1.8
            : 1.2
        }
        spaceBetween={40}
        modules={[Autoplay]}
        speed={3000}
        autoplay={{
          delay: 2000,
          disableOnInteraction: true,
        }}
      >
        {team.map((el, idx) => (
          // {mockTeam.slice(0, 9).map((el, idx) => (
          <SwiperSlide key={idx}>
            <EmployeeItemVertical
              employee={el}
              onClick={() => navigate(`/team/${el.id}`)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default TeamBlock;
