import emailAPIRoutes from 'apiRoutes/email';
import {
  getEmailsFetching,
  getEmailsFetchingSuccess,
  getEmailsFetchingError,
  createFetching,
  createFetchingSuccess,
  createFetchingError,
} from 'store/slices/emailSlices';
import getResponse from 'utils/getResponse';

export const getEmails =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch(getEmailsFetching());

      const response = await emailAPIRoutes.getEmails({ page: page });
      const { ok, data, errors } = getResponse(response);

      if (ok) {
        dispatch(getEmailsFetchingSuccess(data));
      } else {
        dispatch(getEmailsFetchingError(errors[0].value));
      }
    } catch (e) {
      dispatch(getEmailsFetchingError(e.message));
    }
  };

export const createEmail = (form) => async (dispatch) => {
  try {
    dispatch(createFetching());

    const response = await emailAPIRoutes.emailCreate(form);
    const { ok, data, errors } = getResponse(response);

    if (ok) {
      dispatch(createFetchingSuccess(data));
    } else {
      dispatch(createFetchingError(errors[0].value));
    }
    return { ok, data, errors };
  } catch (e) {
    dispatch(createFetchingError(e.message));
  }
};

export const emailRemove =
  (form, page = 1) =>
  async (dispatch) => {
    try {
      dispatch(createFetching());

      const response = await emailAPIRoutes.emailRemove(form);
      const { ok, data, errors } = getResponse(response);

      if (ok) {
        dispatch(createFetchingSuccess(data));
        dispatch(getEmails(page));
      } else {
        dispatch(createFetchingError(errors[0].value));
      }
      return { ok, data, errors };
    } catch (e) {
      dispatch(createFetchingError(e.message));
    }
  };
