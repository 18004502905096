export const articleTypesModel = {
  post: {
    name: 'Пост',
    key: 0,
  },
  event: {
    name: 'Событие',
    key: 1,
  },
  external: {
    name: 'Внешний источник',
    key: 2,
  },
};
