import { useTheme } from '@emotion/react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { Swiper, SwiperSlide } from 'swiper/react';
import { bankruptcyMock } from '../mock';
import CollapsedTextBlock from '../components/collapsedTextBlock';
import PaperBlank from 'elements/UI/PaperBlank';
import { PersonBlock } from '../components/personBlock';
import { useEffect, useRef, useState } from 'react';
import { PhotosBlock } from '../components/photosBlock';
import { Autoplay } from 'swiper';

const Bankruptcy = ({ memberCards }) => {
  const theme = useTheme();
  const greaterThanXl = useMediaQuery(theme.breakpoints.up('xl'));
  const greaterThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const greaterThanMmd = useMediaQuery(theme.breakpoints.up('mmd'));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const greaterThanSsm = useMediaQuery(theme.breakpoints.up('ssm'));

  const largeCardName = useRef(null);
  const [biggestHeight, setBiggestHeight] = useState(null);

  const swiperRef = useRef(null);

  useEffect(() => {
    if (largeCardName.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { height } = entry.contentRect;
          setBiggestHeight(height);
        }
      });
      observer.observe(largeCardName.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <Box
      sx={{
        '& ul > li:not(:last-of-type)': {
          marginBottom: '0.8em',
        },
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <PersonBlock person={memberCards?.[0]} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography sx={{ mb: 4 }} textAlign='justify'>
            Банкротство, как бы печально это ни звучало, является инструментом
            рыночной экономики. Неспособность участника экономического оборота
            расплатиться по своим долгам вызывает множество конфликтов,
            урегулирование которых является целью применения процедур
            банкротства. Банкротство реализуется через особый процесс, где
            главная роль отведена суду. Спецификой дел о банкротстве является
            рассмотрение в рамках единого судебного разбирательства в
            арбитражном суде различных споров, получивших наименование
            "обособленных споров", двигающих процедуры банкротства. Защита прав
            и интересов лиц, участвующих в делах о банкротстве, требует
            определенных компетенций, задает необходимость отслеживания
            изменений не только законодательства, но судебной практики,
            формирующих правовые правила и подходы в процедурах банкротства.
            <br />
            <br />
            Адвокаты Бюро осуществляют юридическую помощь бизнесу и физическим
            лицам во всех процедурах банкротства, проводят аналитику рисков и
            неблагоприятных последствий при угрозе банкротства. Наша команда
            осуществляет консультирование, судебную защиту в практике
            банкротства более 20 лет. Не пренебрегайте помощью профессионалов,
            обладающих опытом комплексного подхода к решению сложных вопросов
            банкротства и связанных с ним процедур.
          </Typography>
        </Grid>
      </Grid>

      <Swiper
        ref={swiperRef}
        className='mySwiper swiperOverflow'
        slidesPerView={
          greaterThanXl
            ? 3
            : greaterThanLg
            ? 2.6
            : greaterThanMd
            ? 2.2
            : greaterThanMmd
            ? 1.8
            : greaterThanSm
            ? 1.8
            : greaterThanSsm
            ? 1
            : 1
        }
        style={{ cursor: 'grab' }}
        spaceBetween={40}
        modules={[Autoplay]}
        speed={3000}
        autoplay={{
          delay: 2000,
          disableOnInteraction: true,
        }}
      >
        {bankruptcyMock.blocks.map((el, idx) => (
          <SwiperSlide
            key={idx}
            onClick={() => swiperRef.current.swiper.autoplay.stop()}
          >
            <Box>
              <Box
                ref={idx === 2 ? largeCardName : null}
                sx={{
                  height: biggestHeight + 'px',
                  display: 'flex',
                  alignItems: 'end',
                }}
              >
                <Typography variant='h5' fontWeight={600} mb={2}>
                  {el.name}
                </Typography>
              </Box>

              <Box>
                <CollapsedTextBlock height={400}>
                  <PersonBlock person={memberCards?.[idx + 1]} />
                  {idx === 2 && <PersonBlock person={memberCards?.[idx + 2]} />}
                  {el.text}
                </CollapsedTextBlock>
              </Box>
              <PaperBlank mt={4}>
                <Box sx={{ p: '24px' }}>
                  <Typography fontWeight='bold' mb={2}>
                    Услуги
                  </Typography>
                  {el.card}
                </Box>
              </PaperBlank>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <br />
      <br />
      <br />
      <PhotosBlock />
      <br />
      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Typography variant='h5' fontWeight={600} mb={3}>
            {bankruptcyMock.lastBlock.name}
          </Typography>
          <PersonBlock person={memberCards?.[5]} />
          <PersonBlock person={memberCards?.[6]} />
          {bankruptcyMock.lastBlock.text1}
        </Grid>
        <Grid item xs={12} md={4}>
          {bankruptcyMock.lastBlock.text2}
        </Grid>
        <Grid item xs={12} md={4}>
          {bankruptcyMock.lastBlock.text3}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Bankruptcy;
