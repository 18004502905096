import { useTheme } from '@emotion/react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { Swiper, SwiperSlide } from 'swiper/react';
import { criminalMock } from '../mock';
import CollapsedTextBlock from '../components/collapsedTextBlock';
import PaperBlank from 'elements/UI/PaperBlank';
import { PersonBlock } from '../components/personBlock';
import { useEffect, useRef, useState } from 'react';
import { PhotosBlock } from '../components/photosBlock';
// import { Autoplay } from 'swiper/modules';
import { Autoplay } from 'swiper';

const CriminalPractice = ({ memberCards }) => {
  const theme = useTheme();
  const greaterThanXl = useMediaQuery(theme.breakpoints.up('xl'));
  const greaterThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const greaterThanMmd = useMediaQuery(theme.breakpoints.up('mmd'));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const greaterThanSsm = useMediaQuery(theme.breakpoints.up('ssm'));

  const largeCardName = useRef(null);
  const [biggestHeight, setBiggestHeight] = useState(null);

  const swiperRef = useRef(null);

  useEffect(() => {
    if (largeCardName.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { height } = entry.contentRect;
          setBiggestHeight(height);
        }
      });
      observer.observe(largeCardName.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);
  return (
    <Box
      sx={{
        '& ul > li:not(:last-of-type)': {
          marginBottom: '0.8em',
        },
      }}
    >
      <Swiper
        ref={swiperRef}
        className='mySwiper swiperOverflow'
        slidesPerView={
          greaterThanXl
            ? 2.6
            : greaterThanLg
            ? 2.6
            : greaterThanMd
            ? 2.2
            : greaterThanMmd
            ? 1.8
            : greaterThanSm
            ? 1.8
            : greaterThanSsm
            ? 1
            : 1
        }
        modules={[Autoplay]}
        speed={3000}
        autoplay={{
          delay: 2000,
          disableOnInteraction: true,
        }}
        style={{ cursor: 'grab', marginTop: '50px' }}
        spaceBetween={40}
      >
        {criminalMock.cards.map((el, idx) => (
          <SwiperSlide
            key={idx}
            onClick={() => swiperRef.current.swiper.autoplay.stop()}
          >
            <Box>
              <Box
                ref={idx === 1 ? largeCardName : null}
                sx={{
                  height: biggestHeight + 'px',
                  display: 'flex',
                  alignItems: 'end',
                }}
              >
                <Typography variant='h5' fontWeight={600} mb={2}>
                  {el.title}
                </Typography>
              </Box>
              <PersonBlock person={memberCards?.[idx]} />
              <Box>
                <CollapsedTextBlock>{el.text}</CollapsedTextBlock>
              </Box>
              <PaperBlank mt={4}>
                <Box sx={{ p: '24px' }}>
                  <Typography fontWeight='bold' mb={2} textAlign='justify'>
                    {el.name}
                  </Typography>
                  <ol style={{ paddingLeft: '24px', textAlign: 'justify' }}>
                    {el.points.map((point, pIdx) => (
                      <li key={pIdx} style={{ marginTop: '8px' }}>
                        {point}
                      </li>
                    ))}
                  </ol>
                </Box>
              </PaperBlank>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <br />
      <br />
      <br />
      <PhotosBlock photosBlockNumber={1} />
      <br />
      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Typography variant='h5' fontWeight={600} mb={2}>
            Пересмотр судебных решений
          </Typography>
          <PersonBlock person={memberCards?.[5]} />
          {criminalMock.block.text1}
        </Grid>
        <Grid item xs={12} md={4}>
          {criminalMock.block.text2}
        </Grid>
        <Grid item xs={12} md={4}>
          <PaperBlank>
            <Box sx={{ p: '24px' }}>
              <Typography fontWeight='bold' mb={2}>
                Какие инстанции существуют для обжалования приговора суда?
              </Typography>
              <ul style={{ paddingLeft: '24px', textAlign: 'justify' }}>
                {criminalMock.block.offers.map((offer, oIdx) => (
                  <li key={oIdx}>{offer}</li>
                ))}
              </ul>
            </Box>
          </PaperBlank>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CriminalPractice;
