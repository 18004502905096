import { ButtonBase, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { NavLink } from 'react-router-dom';

const MenuItemsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '29px',
}));
const LinkBtn = styled(ButtonBase)(({ theme }) => ({
  width: 'fit-content',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '.2s',
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    height: '2px',
    right: '100%',
    left: 0,
    bottom: 0,
    position: 'absolute',
    backgroundColor: 'black',
    transition: '.2s',
  },
  '&:hover, &.active': {
    opacity: '100%',
    '&::after': {
      right: 0,
    },
  },
}));

const FooterMenu = () => {
  return (
    <MenuItemsContainer>
      <LinkBtn component={NavLink} to='/main'>
        <Typography variant='h4'>Главная</Typography>
      </LinkBtn>
      <LinkBtn component={NavLink} to='/practices'>
        <Typography variant='h4'>Практики</Typography>
      </LinkBtn>
      <LinkBtn component={NavLink} to='/team'>
        <Typography variant='h4'>Команда</Typography>
      </LinkBtn>
      <LinkBtn component={NavLink} to='/contacts'>
        <Typography variant='h4'>Контакты</Typography>
      </LinkBtn>
    </MenuItemsContainer>
  );
};

export default FooterMenu;
